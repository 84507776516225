_:-ms-fullscreen, :root {
   .banner{
      .container{
         height: 690px;
         display: flex;
         align-items: center;
      }
   }
   .sub_navigation_wrapper .sub_navigation_item .sub_navigation_item_inner .sub_navigation_item_title{
      word-break: break-all;
      word-wrap: break-word;
   }
}
