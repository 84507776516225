html {
  width: 100%;
  height: 100%;
}

* {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}

.text-primary {
  color: $abellio-red !important;
}

body.modal-open {
  overflow: hidden;
}

#app {
  width: 100%;
  min-height: 100vh;
  position: relative;
  overflow-x: hidden;

  &.timetable_open {
    height: 100vh;
    overflow: hidden;
  }
}

.page_section {
  min-height: 100vh;
  padding-bottom: 1px;

  .error_page {
    text-align: center;
    padding: 0 20px;

    h1 {
      color: $abellio-red;
      font-size: 12rem;
      margin-bottom: 30px;
    }

    h6 {
      font-size: 2rem;
      margin-top: 30px;
      color: $abellio-dark-grey;
    }

    p {
      font-size: 1.2rem;
      margin-top: 50px;
    }
  }
}

.content_element {
  margin-bottom: 30px;

  .content_blocks_element {
    margin-top: 150px;
  }
}

.alert {
  position: fixed;
  z-index: 10000;
  top: 20px;
  right: 20px;
  padding: 10px 12.5px;
  width: 450px;
  max-width: 90%;
  background-color: $abellio-dark-grey;
  color: white;
  opacity: 1;
  transform: translate(0, 0);

  &.alert-danger {
    background: #f44336;
  }

  &.alert-success {
    background: $abellio-dark-grey;
  }

  .closebtn {
    margin-left: 15px;
    color: white;
    font-weight: bold;
    float: right;
    font-size: 22px;
    line-height: 20px;
    cursor: pointer;
    transition: 0.3s;
  }

  .title {
    margin-bottom: 0;
  }

  .content {
    margin-top: 0;
    @include fontSize(13);

    &.has_icon {
      float: left;
      padding-left: 7px;
    }
  }

  .refresh-icon {
    float: left;
    margin-top: 6px;
    animation: spin 1s linear 2;
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.alert-animation-enter-active,
.alert-animation-leave-active {
  transition: all 0.5s;
}

.alert-animation-enter,
.alert-animation-leave-to {
  opacity: 0;
  transform: translate(0, -50%);
}

.cursor-pointer {
  cursor: pointer;
}

.container_small {
  width: 100%;
  max-width: 1020px;
  padding-left: 30px;
  padding-right: 30px;
  margin: 0 auto;
}

.page_top_wrapper {
  position: fixed;
  z-index: 100;
  width: 100%;
  padding: 14px 30px 14px 30px;

  // height: 164px;
  pointer-events: none;
  @include transition(0.3);
  z-index: 991;
  background-color: $white;

  // &:before {
  //    content: '';
  //    position: absolute;
  //    z-index: -1;
  //    top: 0;
  //    left: 0;
  //    width: 100%;
  //    height: 58%;
  //    background: -moz-linear-gradient(top, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0) 100%); /* FF3.6-15 */
  //    background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0) 100%); /* Chrome10-25,Safari5.1-6 */
  //    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  //    opacity: 1;

  //    @include transition(0.3);
  // }

  * {
    pointer-events: auto;
  }

  .region_changer_wrapper {
    margin-left: 30px;
  }

  &.menu_open {
    &:before {
      opacity: 0;
    }

    .select_white {
      color: $black;
    }

    .custom-select .selected:after {
      background-image: url(../../assets/images/select_arrow.svg);
    }

    .logo {
      img {
        // opacity: 0;

        &.logo_color {
          opacity: 1;
        }
      }
    }

    .menu_trigger,
    .text_size_toggle,
    .profile_menu {
      svg {
        @include svgColor($black);
      }
    }

    .locale_changer_wrapper {
      a {
        color: $black;
        border-color: $black;
      }
    }
  }
}

.locale_changer_wrapper {
  display: flex;
  margin-right: 25px;

  a {
    display: block;
    @include fontSize(16);
    @include lineHeight(20);
    margin-bottom: 0;
    color: $white;
    text-transform: uppercase;
    margin-left: 8px;
    padding-left: 8px;
    @include transition(0.3);
    margin-top: 2px;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      left: 0;
      width: 1px;
      height: 12px;
      background: $white;
      opacity: 0.4;
      top: 3px;
    }

    &:first-child {
      padding-left: 0;
      margin-left: 0;

      &:after {
        content: none;
      }
    }

    &.is_active {
      color: $abellio-red !important;
    }
  }
}

.menu_trigger {
  display: inline-block;
  width: 40px;
  height: 40px;
  margin-bottom: 0;

  i {
    &:before {
      color: $black;
    }
  }
}

.logo {
  // display: block;
  margin-bottom: 0;
  position: relative;
  display: inline-flex;
  max-width: 132px;

  img {
    width: 100%;
    opacity: 1;
    @include transition(0.3);

    &.logo_color {
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      width: 100%;
      height: auto;
      opacity: 0;
    }
  }
}

.text_size_toggle {
  display: block;
  margin-bottom: 2px;
  margin-right: 14px;
  border-radius: 44px;
  padding: 10px 10px;
  background: rgba(107, 113, 122, 0.1);

  svg {
    @include svgColor($abellio-gray);
    height: 14px;
  }
}

.profile_menu {
  display: block;
  width: 40px;
  height: 40px;
  margin-bottom: 0;

  i {
    color: $white;
    font-size: 20px;
  }
}

.banner {
  // height: 70vh;
  position: relative;
  color: $white;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: 0;
  margin-bottom: 48px;
  display: flex;
  align-items: center;
  // overflow: hidden;
  min-height: 690px;

  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    width: 100%;
    border-top: 145px solid transparent;
    border-left: 50vw solid $white;
    border-right: 50vw solid $white;
    border-bottom: 5px solid $white;
    margin-bottom: -3px;
    z-index: 2;
  }

  &.blogBanner {
    &:after {
      content: '';
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      background-color: rgba(0, 0, 0, 0.5);
    }

    &:before {
      z-index: 1;
    }

    h1 {
      position: relative;
      z-index: 2;
    }
  }

  &:after {
    content: '';
    background: linear-gradient(90deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
    opacity: 0.6;
    position: absolute;
    left: 0;
    z-index: 1;
    width: 60%;
    top: 0;
    min-height: 100%;
  }

  .banner_title_wrapper {
    position: relative;
  }

  .container {
    position: relative;
    z-index: 2;
  }

  h1 {
    font-weight: 900;
    margin-bottom: 0;
  }

  &.small_banner {
    display: flex;
    height: auto;
    padding-top: 180px;
    padding-bottom: 150px;
    min-height: auto;

    .banner_title_wrapper {
      position: relative;
      bottom: 0;
      text-align: center;
    }

    h1 {
      @include fontSize(32);
      @include lineHeight(36);
      font-weight: bold;
    }

    h4 {
      @include fontSize(18);
      @include lineHeight(26);
      margin-top: 10px;
      margin-bottom: 0;
      font-weight: normal;
    }
  }

  .vertical_text {
    position: absolute;
    right: 0;
    height: 100%;
    pointer-events: none;
    display: none;

    span {
      background: linear-gradient(to bottom,
          rgba(0, 0, 0, 0.65) 0,
          rgba(0, 0, 0, 0) 70%);
      // writing-mode: vertical-lr;
      // text-orientation: mixed;
      writing-mode: tb-rl;
      transform: rotate(-180deg);
      height: calc(100% - 130px);
      text-align: left;
      padding-left: 10px;
      padding-right: 10px;
      padding-top: 30px;
      text-orientation: sideways-right;
    }
  }
}

.job_offers_map_element {
  height: 70vh;
  position: relative;
  margin-top: 0;
  margin-bottom: 60px;
  overflow: hidden;

  &:before {
    pointer-events: none;
    content: '';
    position: absolute;
    z-index: 8;
    bottom: 0;
    width: 100%;
    border-top: 145px solid transparent;
    border-left: 50vw solid $white;
    border-right: 50vw solid $white;
  }
}

.job_offers_map_wrapper {
  width: 100%;
  height: 100%;
}

.navigation_wrapper,
.search_wrapper,
.slide-top-popup {
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  padding-top: 145px;
  padding-bottom: 190px;
  padding-left: 30px;
  padding-right: 30px;
  transform: translate(0, -150%);
  transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
  min-height: 690px;

  .navigation_overlay {
    position: fixed;
    z-index: -10;
    top: 0;
    left: 0;
    width: 100%;
    height: 200vh;
    pointer-events: none;
    background: rgba(0, 0, 0, 0.3);
    opacity: 0;
    @include transition(0.3);
  }

  &.is_open {
    transform: translate(0, 0%);

    .navigation_overlay {
      opacity: 1;
      pointer-events: auto;
    }
  }

  &:before {
    content: '';
    position: absolute;
    z-index: -1;
    left: 0;
    top: 0;
    width: 100%;
    height: calc(100% - 145px);
    background: $white;
  }

  &:after {
    content: '';
    position: absolute;
    z-index: -1;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 145px;
    border-top: 146px solid $white;
    border-left: 50vw solid transparent;
    border-right: 50vw solid transparent;
  }

  .nav_title {
    @include fontSize(24);
    @include lineHeight(30);
    margin-bottom: 26px;
  }

  .navigation_inner {
    width: 100%;
    max-width: 400px;
    height: auto;
    // overflow-y: auto;

    ul {
      display: none;
      padding: 0 30px;
      margin-bottom: 15px;

      li {
        //text-align: left;
        list-style: none;

        &:first-child {
          border-top: 0 none;
        }

        a {
          @include fontSize(16);
          @include lineHeight(28);
          display: block;
          margin-bottom: 0;
          padding: 1px 10px;
          position: relative;
          z-index: 1;
          @include transition(0.3);

          &:before {
            content: '';
            position: absolute;
            z-index: -1;
            left: 0;
            top: 2px;
            bottom: 2px;
            width: 0;
            background: $abellio-red;
            @include transition(0.3);
          }

          &.router-link-active {
            color: $abellio-red;
            font-weight: bold;
          }

          &:hover {
            color: $white;

            &:before {
              width: 100%;
            }
          }
        }
      }
    }

    .first_level {
      display: block;
      padding: 0 30px;
      margin-bottom: 0;
      background: transparent;
      border-top: 2px solid $abellio-red;

      &>li {
        border-top: 1px solid $abellio-red;

        &>a {
          @include fontSize(24);
          @include lineHeight(30);
          padding: 10px 10px;

          &:before {
            top: 0 !important;
            bottom: 0 !important;
          }
        }
      }
    }
  }

  .region_changer_wrapper {
    display: none;
    position: absolute;
    bottom: 50px;
    left: 50%;
    transform: translate(-50%, 0);

    .custom-select .items {
      bottom: 100%;
      margin-top: 0;
      margin-bottom: 5px;
    }
  }

  .locale_changer_wrapper {
    position: relative;
    z-index: 1;
    display: none;
    justify-content: center;
    margin: -220px 0 0 0;

    a {
      color: $black;
      border-color: $black;
    }
  }
}

.menu_open {
  .locale_changer_wrapper {
    a {
      &:after {
        background-color: #000;
      }
    }
  }

  .search_trigger,
  .profile_menu {
    i {
      color: #000;
    }
  }
}

.timetable_open {
  .page_top_wrapper {
    &:before {
      background: transparent;
    }
  }

  .locale_changer_wrapper {
    a {
      &.is_active {
        color: #000 !important;
      }
    }
  }
}

.search_open {
  .page_top_wrapper {
    &:before {
      background: transparent;
    }

    .logo {
      img {
        &.logo_color {
          opacity: 1;
        }
      }

      >img {
        opacity: 0;
      }
    }

    .menu_trigger svg *[fill='#fff'],
    .menu_trigger svg *[fill='#ffffff'] {
      fill: #000;
    }

    .locale_changer_wrapper {
      a {
        color: #000;

        &:after {
          background: #000;
        }
      }
    }

    .text_size_toggle svg *[fill='#fff'],
    .text_size_toggle svg *[fill='#ffffff'] {
      fill: #000;
    }

    .search_trigger,
    .profile_menu {
      i {
        color: #000;
      }
    }

    .select_white {
      .custom-select {
        .selected {
          color: #000;

          &:after {
            background-image: url('../images/select_arrow.svg');
          }
        }
      }
    }
  }
}

.search_wrapper.is_open {
  z-index: 99;
}

.close_button {
  position: absolute;
  width: 48px;
  height: 48px;
  bottom: 0;
  left: 50vw;
  transform: translate(-50%, 0) rotate(45deg);
  margin-bottom: 0;
  background: url(../../assets/images/plus_icon_red.svg) no-repeat center center;
}

.user_profile_wrapper {
  position: absolute;
  // top: 544px;
  bottom: 50px;
  left: 0;
  right: 0;
  width: 400px;
  max-width: 100%;
  margin: auto;
  flex-direction: column;

  .user_profile {
    position: relative;
    display: block;
    margin-bottom: 0;
    padding-top: 10px;
    @include lineHeight(30);
    @include fontSize(15);
  }

  .user-account {
    margin-bottom: 10px;
    @include fontSize(22);
    font-weight: 700;
    color: $abellio-red;
  }

  .logout-btn {
    margin-left: -15px;

    .ab-icon-logout {
      position: relative;
      top: 2px;
      margin-right: 5px;
    }
  }
}

.fr-img-caption {
  .fr-img-wrap {
    position: relative;
    display: inline-flex;
  }

  .fr-inner {
    color: $white;
    background-color: rgba(206, 19, 45, 0.7);
    text-align: center;
    @include fontSize(13);
    font-weight: 300;
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 4px 40px;
  }
}

.left_buttons_wrapper {
  position: fixed;
  z-index: 100;
  left: 40px;
  display: flex;
  flex-direction: column;
  top: 230px;

  .left_button {
    display: block;
    margin-bottom: 0;
    margin-top: 10px;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    // background: $abellio-red;
    // background: $sub-navigation-background;
    // border: 1px solid $abellio-red;
    background: $white;
    //border: 1px solid $abellio-dark-grey;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.16);
    @include transition(0.3);
    color: #fff;

    &:first-child {
      margin-top: 0;
    }

    i {
      color: $abellio-gray;
      font-size: 25px;
      @include transition(0.3);
    }

    &:hover {
      // background: $white;
      background: rgb(232, 232, 232);
      //color: $abellio-red;

      i {
        color: $abellio-red;
        @include transition(0.3);
      }
    }

    &.router-link-exact-active {
      i {
        color: $abellio-red !important;

        &:before {
          color: $abellio-red !important;
        }
      }
    }
  }
}

.change_password_link {
  color: $abellio-red;
  text-decoration: underline;
}

.traffic_infobox_element {
  margin-bottom: 50px;
}

.euro-sign {
  font-size: 20px;
  color: $abellio-red;
}

.traffic_infobox_wrapper {
  position: relative;
  box-shadow: 4px 4px 10px rgba($black, 0.1);
  height: 100%;
  padding-bottom: 48px;

  .traffic_infobox_title {
    padding: 14px 15px 10px 15px;
    background: $abellio-red;
    color: $white;

    * {
      margin-bottom: 0;
      @include fontSize(18);
      @include lineHeight(24);
    }
  }

  .traffic_infobox_container {
    padding: 15px 15px 0 15px;

    .traffic_infobox_pagination {
      bottom: -20px;
    }

    .traffic_infobox_item {
      display: block;
      margin-bottom: 10px;
      box-shadow: 0 2px 4px rgba($black, 0.2);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding: 6px 30px 5px 20px;
      position: relative;
      color: lighten($black, 50%);

      span {
        color: $black;
      }

      .item_color {
        position: absolute;
        background: #cecece;
        top: 0;
        left: 0;
        width: 10px;
        height: 100%;
        opacity: 1;
      }

      &:after {
        content: '';
        position: absolute;
        right: 0;
        top: 50%;
        transform: translate(0, -50%);
        width: 30px;
        height: 30px;
        background: url(../../assets/images/arrow_left_red.svg) no-repeat center center;
      }
    }
  }

  .traffic_infobox_overview {
    position: absolute;
    bottom: 8px;
    right: 15px;
    margin-bottom: 0;
    padding-right: 30px;

    &:after {
      content: '';
      position: absolute;
      right: 0;
      top: 50%;
      transform: translate(0, -50%);
      width: 30px;
      height: 30px;
      background: url(../../assets/images/arrow_left_red.svg) no-repeat center center;
    }
  }
}

body {

  .swiper-pagination-fraction,
  .swiper-pagination-custom,
  .swiper-container-horizontal>.swiper-pagination-bullets {
    bottom: 0;
    line-height: 0;
    height: auto;
  }

  .swiper-container-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet {
    margin: 0 4px;
    border-radius: 50%;
    width: 52px;
    height: auto;
    padding: 3px 0;
    opacity: 1;
    background: $white;
    box-shadow: none;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    @include transition(0.3);

    &:before {
      // content: '';
      // display: block;
      // margin: 0 4px;
      // border-radius: 0;
      // width: 12px;
      // height: 12px;
      // padding: 3px 0;
      // opacity: 1;
      // background: #fff;
      // box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
      // position: relative;
      // top: 50%;
      // transform: translateY(-50%);
      // transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
      // border-radius: 50%;
      // background: #6B717A;
      // opacity: 0.1;
      // @include transition(0.3);

      // top: 50%;
      // transform: translateY(-50%);
    }

    &:focus {
      outline: none;
      border: none;
    }

    &.swiper-pagination-bullet-active {
      background: $abellio-red;
      @include transition(0.3);
      background: #6b717a;
      opacity: 1;
      width: 16px;
      height: 16px;
    }
  }

  .swiper-pagination.traffic_infobox_pagination {
    bottom: 0px;

    &.swiper-pagination-bullets .swiper-pagination-bullet {
      width: 32px;
    }
  }
}

.carousel_content_element {
  margin-bottom: 100px;
}

.carousel_content_wrapper {
  .swiper-container {
    padding-bottom: 28px;
    position: static;
  }

  .job-item-slide {
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.1);
    margin-left: 4px;
    border-left: 1px solid transparent;
    border-right: 1px solid transparent;
    border-bottom: 1px solid transparent;
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);

    .abellio_info_box {
      box-shadow: unset;
      margin-left: 0;
      border: none;
      transition: unset;
    }
  }
}

.abellio_info_holder {
  display: flex;
  justify-content: space-between;
}

.jobs_carousel_element {
  margin-bottom: 70px;
}

// .abellio_info_box {
//    display: flex;
//    flex-direction: column;
//    position: relative;
//    height: 100%;
//    box-shadow: 4px 4px 10px rgba($black, 0.1);
//    margin-left: 4px;
//    border-left: 1px solid transparent;
//    border-right: 1px solid transparent;
//    border-bottom: 1px solid transparent;
//    @include transition(0.3, 'box-shadow');
//    border-radius: 5px;

//    .abellio_info_box_content {
//       position: relative;
//       padding: 20px 12px 40px 12px;
//       flex-grow: 1;
//       display: flex;
//       flex-direction: column;

//       .abellio_info_box_title {
//          @include fontSize(22);
//          @include lineHeight(26);
//          font-weight: normal;
//          margin-bottom: 14px;

//          &.abellio_info_box_title {
//             @include fontSize(28);
//             font-weight: bold;
//          }
//       }

//       p {
//          margin-bottom: 14px;
//          color: lighten($black, 40%);
//       }

//       .abellio_info_box_sub_title {
//          @include fontSize(14);
//          @include lineHeight(18);
//          margin-bottom: 2px;
//       }
//    }

//    &.is_active,
//    &:hover {
//       box-shadow: 4px 4px 10px rgba($black, 0.2);
//    }

//    .abellio_info_box_image {
//       margin: 0 -1px;
//       overflow: hidden;
//       height: 240px;
//       background-size: cover;
//       background-position: center;
//       position: relative;
//    }

//    .abellio_info_box_more {
//       position: absolute;
//       bottom: 10px;
//       right: 10px;
//       padding-right: 30px;
//       @include fontSize(18);
//       @include lineHeight(21);
//       margin-bottom: 0;

//       &:after {
//          content: '';
//          position: absolute;
//          right: 0;
//          top: 50%;
//          transform: translate(0, -50%) rotate(90deg);
//          width: 30px;
//          height: 30px;
//          background: url(../../assets/images/arrow_left_red.svg) no-repeat center center;
//       }
//    }

//    &.job_info_box {
//       .abellio_info_box_content {
//          padding-bottom: 12px;

//          .abellio_info_box_title {
//             @include fontSize(18);
//             @include lineHeight(20);
//             font-weight: bold;
//             margin-bottom: 8px;
//          }

//          p {
//             @include fontSize(14);
//             @include lineHeight(18);
//             margin-bottom: 8px;
//             color: $black;
//          }

//          .abellio_info_box_sub_title {
//             @include fontSize(14);
//             @include lineHeight(18);
//             margin-bottom: 2px;
//          }

//          .abellio_info_box_address {
//             padding-left: 25px;
//             position: relative;

//             i.icon {
//                position: absolute;
//                left: 0;
//                top: 2px;

//                svg {
//                   @include svgColor($abellio-red);
//                }
//             }
//          }
//       }

//       .job_info_box_apply_button {
//          @include fontSize(11);
//          @include lineHeight(18);
//          margin-bottom: 0;
//          font-weight: bold;
//          color: $abellio-red;
//          padding-left: 25px;
//          position: relative;
//          margin-right: 20px;

//          i.icon {
//             position: absolute;
//             left: 0;
//             top: 0;

//             svg {
//                @include svgColor($abellio-red);
//             }
//          }
//       }

//       .job_info_box_actions {
//          display: flex;

//          .job_info_box_action_button {
//             @include fontSize(13);
//             @include lineHeight(18);
//             display: block;
//             margin-left: 20px;
//             margin-bottom: 0;

//             &:first-child {
//                margin-left: 0;
//             }

//             i.icon {
//                svg {
//                   @include svgColor($abellio-red);
//                }
//             }

//          }
//       }

//       .view-more {
//          .job_info_box_action_button {
//             margin: 0 -12px -12px -12px;
//             padding: 5px 13px;
//             background: #e8e8e8;
//             @include fontSize(13);
//             display: flex;
//             font-weight: 700;
//             align-items: center;
//             margin-top: 10px;
//             z-index: 3;
//             position: relative;

//             .icon {
//                svg {
//                   width: 16px;
//                   margin-right: 8px;
//                   height: 16px;
//                   position: relative;
//                   top: 3px;
//                }
//             }
//          }
//       }
//    }
// }

.sub_navigation_element {
  margin-bottom: 80px;
}

.sub_navigation_wrapper {
  position: relative;

  .swiper-wrapper {
    padding: 2px 0;
  }

  &.is_centralized .swiper-wrapper {
    justify-content: center;

    .swiper-slide:last-child {
      margin-right: 0 !important;
    }
  }

  .abellio-swiper-button-prev,
  .abellio-swiper-button-next {
    display: block;
    margin-bottom: 0;
    position: absolute;
    z-index: 8;
    top: 50%;
    width: 40px;
    height: 40px;
    left: -46px;
    transform: translate(0, -50%);

    &.swiper-button-disabled {
      display: none;
    }
  }

  .abellio-swiper-button-next {
    left: auto;
    right: -46px;
  }

  .sub_navigation_item {
    display: block;
    position: relative;
    // padding-bottom: 100%;
    margin-bottom: 0px;
    // background: $sub-navigation-background;
    // border: 1px solid red;

    .sub_navigation_item_inner {
      display: flex;
      // flex-direction: column;
      // justify-content: center;
      // position: absolute;
      // top: 0;
      // left: 0;
      // right: 0;
      // bottom: 0;

      display: block;
      position: relative;
      // background: $sub-navigation-background;
      color: $abellio-gray;
      margin-bottom: 0;
      border: 1px solid transparent;
      display: inline-block;
      background-color: rgba(107, 113, 122, 0.06);
      transition: 0.3s;
      min-width: 110px;
      text-align: center;
      border-radius: 44px;
      font-size: 20px;
      line-height: 100%;

      &:hover {
        color: $abellio-red;
        border-color: $button-background;
      }

      .icon_wrapper {
        height: auto;
        position: relative;
        padding: 15px;

        .icon {
          height: 100%;
          display: block;

          svg {
            display: block;
            width: 64px;
            height: 64px;
            max-height: 60px;
            margin-bottom: 0;
            @include svgColor($black);
          }
        }
      }

      .sub_navigation_item_title {
        margin-bottom: 0;
        font-weight: normal;
        @include fontSize(20);
        @include lineHeight(20);
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        // height: 50px;
        padding: 14px 24px 12px 24px;
      }
    }

    &.router-link-exact-active {
      .sub_navigation_item_inner {
        color: $abellio-red;
        border-color: $button-background;
      }
    }

    path,
    polygon,
    rect {
      transition: fill 0.3s ease-in-out;
    }

    &:hover,
    &.router-link-active {
      .icon_wrapper .icon {
        svg {

          path,
          polygon,
          rect {
            fill: $abellio-red !important;
          }
        }
      }
    }

    &.router-link-active {
      .sub_navigation_item_title {
        // font-weight: bold;
      }
    }
  }
}

.carousel_big_content_wrapper {
  margin-top: 38px;

  .carousel_big_image {
    width: 100%;
    height: auto;
    display: block;
    position: relative;
  }

  .image_copyright {
    border-radius: 12px;
    overflow: hidden;
  }

  .carousel_content_side {
    padding-left: 40px;

    h3 {
      margin: 0 0 24px 0;
    }

    .info_tag_btn {
      width: auto;
      max-width: 100%;
      margin: 0;
      box-shadow: none;
      padding: 0;
      color: #c71712;
      font-weight: 700;
      font-size: 16px;
      text-decoration: underline;
    }
  }
}

.info_tag_btn {
  display: inline-block;
  margin-bottom: 20px;
  margin-right: 30px;
  position: relative;
  @include fontSize(14);
  @include lineHeight(24);
  padding: 10px 20px 9px 52px;
  box-shadow: 2px 2px 4px rgba($black, 0.1);

  p {
    @include fontSize(14);
    @include lineHeight(20);
    margin-bottom: 0;
  }

  .color {
    background-color: #d8d8d8;
    position: absolute;
    top: 0;
    left: 0;
    width: 40px;
    height: 100%;
  }

  &.more_btn {
    width: 360px;
    max-width: 100%;
    margin: 0;
  }

  &.more_btn {
    width: 360px;
    max-width: 100%;
    margin: 0;
  }

  .info_tag_arrow {
    position: relative;
    @include fontSize(18);
    @include lineHeight(21);
    padding-right: 30px;
    padding-left: 10px;
    margin-right: -5px;

    &:after {
      content: '';
      position: absolute;
      right: 0;
      top: 50%;
      transform: translate(0, -50%) rotate(0deg);
      width: 30px;
      height: 30px;
      background: url(../../assets/images/arrow_left_red.svg) no-repeat center center;
      transition: 0.3s;
    }
  }

  &:hover {
    .info_tag_arrow {
      &:after {
        transform: translate(5px, -50%) rotate(0deg);
        transition: 0.3s;
      }
    }
  }
}

.full_image_element {
  margin-bottom: 80px;
  position: relative;
}

.big_image {
  display: block;
  width: 100%;
  height: auto;
}

.title_big_image_element {
  margin-bottom: 80px;
}

.title_big_image_wrapper {
  position: relative;

  img {
    display: block;
    width: 100%;
    height: auto;
  }
}

.address_box_column {
  padding-left: 0;
}

.address_box_wrapper {
  display: inline-block;
  position: relative;
  width: 100%;
  max-width: 300px;
  padding: 20px 20px 74px 20px;
  box-shadow: 4px 4px 10px rgba($black, 0.1);
  min-height: 290px;
  background: url(../../assets/images/map_marker_bg.svg) no-repeat right top;
  margin-bottom: 20px;

  .address_box_title {
    padding: 6px 0;
    padding-left: 64px;
    position: relative;
    @include fontSize(22);
    @include lineHeight(27);
    font-weight: normal;
    background: url(../../assets/images/map_marker_icon.svg) no-repeat left center;
    margin-bottom: 30px;
  }

  .address_box_subtitle {
    @include fontSize(22);
    @include lineHeight(27);
    font-weight: normal;
    margin-bottom: 10px;
  }

  p {
    color: lighten($black, 40%);
  }

  .btn {
    margin-bottom: 0;
    position: absolute;
    left: 20px;
    bottom: 20px;
  }
}

.stats_element {
  margin-bottom: 60px;
  overflow-y: auto;
}

.stats_wrapper {
  display: flex;

  .stats_container {
    // margin-right: 104px;
    gap: 32px;
    flex-direction: column;

    .stats_item {
      height: 200px;
      width: 200px;
      // padding: 28px 30px;
      // box-shadow: 4px 4px 10px rgba($black, 0.1);
      margin-bottom: 20px;
      display: flex;
      flex-direction: column;
      align-content: center;
      justify-content: center;
      align-items: center;
      text-align: center;
      border-radius: 50%;
      background: rgba(107, 113, 122, 0.06);
      padding: 0 15px;

      .stats_title {
        @include fontSize(30);
        @include lineHeight(36);
        margin-bottom: 0;
        color: $abellio-red;
        // @include media-breakpoint-down(lg) {
        //    font-size: 24px;
        //    line-height: 28px;
        // }
      }

      p {
        @include fontSize(14);
        @include lineHeight(19.6);
        margin-bottom: 0;
      }
    }
  }

  .stats_content {
    // width: 570px;
    width: 100%;
    max-width: 100%;
  }
}

.timeline_element {
  margin-bottom: 80px;
}

.timeline_wrapper {
  position: relative;
  z-index: 1;
  padding-top: 38px;

  &:before {
    content: '';
    position: absolute;
    width: 150px;
    height: 100%;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);
    z-index: -1;
    background: $abellio-red;
  }

  .timeline_item {
    width: 50%;
    position: relative;
    padding-top: 58px;
    padding-right: 75px;
    min-height: 254px;
    margin-top: -66px;

    &:first-child {
      margin-top: 0;
    }

    .timeline_year {
      position: absolute;
      right: -10px;
      top: 0;
      @include fontSize(80);
      @include lineHeight(94);
      color: $white;
      word-break: break-all;
      margin-bottom: 0;
    }

    p {
      @include fontSize(26);
      @include lineHeight(36);
    }

    .timeline_contents {
      padding-right: 30px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      width: 100%;
    }

    .timeline_content {
      text-align: right;
      padding-right: 45px;
      padding-top: 22px;
      position: relative;
      margin-bottom: 30px;

      &:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        width: 85px;
        height: 5px;
        background: $abellio-red;
      }
    }

    &.align-self-end {
      padding-left: 75px;
      padding-right: 0;

      .timeline_year {
        right: auto;
        left: -5px;
      }

      .timeline_contents {
        padding-right: 0;
        padding-left: 30px;
      }

      .timeline_content {
        text-align: left;
        padding-right: 0;
        padding-left: 45px;

        &:before {
          right: auto;
          left: 0;
        }
      }
    }
  }
}

.columns_content_element {
  margin-bottom: 50px;
}

.stations_list_element {
  margin-bottom: 50px;
}

.columns_content_wrapper {
  margin-bottom: 30px;
}

.place_info_element {
  margin-bottom: 60px;
}

.footer_navigation {
  display: none;
  position: fixed;
  z-index: 200;
  top: 0;
  left: 0;
  right: 0px;
  height: 76px;
  background: $white;
  border-top: 1px solid $carousel-border-color;
}

.scroll_top_wrapper {
  position: relative;
  height: 0;
  pointer-events: none;

  .scroll_top_button {
    pointer-events: auto;
    position: fixed;
    z-index: 100;
    bottom: 30px;
    margin-bottom: 0;
    border-radius: 50%;
    right: 15px;
    width: 35px;
    height: 35px;
    background-color: $abellio-dark-grey;
    transform: translate(-50%, 0);
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 12px;
  }
}

.mobile_title_wrapper {
  display: none;
  position: fixed;
  z-index: 49;
  top: 0;
  left: 0;
  right: 0;
  color: $black;
  border-bottom: 1px solid transparent;
  background: transparent;
  padding: 12px 50px;
  pointer-events: none;
  @include transition(0.3);

  .mobile_title_back {
    position: absolute;
    pointer-events: auto;
    margin-bottom: 0;
    border-radius: 50%;
    top: 20px;
    left: 8px;

    &:before {
      content: '';
      position: absolute;
      z-index: -1;
      top: 15%;
      left: 15%;
      right: 15%;
      bottom: 15%;
      border-radius: 50%;
      background: $white;
    }

    svg {
      display: block;
    }

    span {
      svg {
        @include svgColor($scrill-top-button-color);
      }
    }

    &>svg {
      display: none;
    }
  }

  p {
    display: none;
    margin-bottom: 0;
    text-align: center;
    @include fontSize(21);
    @include lineHeight(26);
    font-weight: bold;
  }

  &.first_mode {
    color: $white;
    border-color: $white;

    p {
      display: block;
    }

    .mobile_title_back {
      left: 25px;
      top: 50%;
      transform: translate(0, -50%);

      &:before {
        display: none;
      }

      span {
        display: none;
      }

      &>svg {
        display: block;
        @include svgColor($white);
      }
    }

    &.second_mode {
      color: #fff;
      border: 0;
      background: transparent;

      .mobile_title_back {
        &>svg {
          @include svgColor(#fff);
        }
      }

      p {
        font-weight: 300;
      }

      &:before {
        content: '';
        background: linear-gradient(to bottom,
            rgba(0, 0, 0, 0.85) 0%,
            rgba(0, 0, 0, 0) 100%);
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100px;
        z-index: -1;
      }
    }
  }
}

.form_builder_element {
  margin-bottom: 60px;
}

.accordion_element {
  margin-bottom: 80px;
  color: $abellio-gray;

  h3 {
    color: $black;
  }
}

.accordion_wrapper {
  .accordion_item {
    margin-bottom: 8px;
    box-shadow: $box-shadow-new;
    border-radius: 8px;
    //  padding: 18px;
    //  padding-bottom: 16px;
    outline: 0;
    @include transition(0.3);

    .accordion_title {
      cursor: pointer;
      position: relative;
      // padding-right: 20px;
      padding: 17px 22px 16px 21px;
      color: $abellio-gray;

      h6 {
        padding: 20px 0;
      }

      &:after {
        position: absolute;
        content: '';
        background: url(../../assets/images/select_arrow.svg) no-repeat center center;
        width: 16px;
        height: 12px;
        right: 22px;
        margin-top: 7px;
        transform: rotate(-180deg);
        transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
        transform-origin: center;
      }
    }

    .accordion_content {
      padding: 0 18px;
      // overflow: hidden;
      // max-height: 0;
      // display: none;
      // transition: all .5s linear;

      h4 {
        @extend .text-muted;
        font-weight: 300;
        @include fontSize(14);
        margin-bottom: 0;
        color: $black;

        strong {
          font-weight: 300;
          color: $black;
        }
      }

      p {
        margin-bottom: 5px;
        color: $black;
      }

      table,
      ul li,
      ol li {
        color: $black;
      }
    }

    &.is_open {
      .accordion_title {
        &:after {
          transform: rotate(0deg);
        }
      }

      .accordion_content {
        padding-top: 5px;
        padding-bottom: 5px;
        //  max-height: 100%;
        //  opacity: 1;
        //  display: block;
        //  margin-top: 20px;
        //  transition: all .5s linear;
      }
    }

    &.accordion_form {
      form {
        padding: 10px 0 20px 0;
      }
    }
  }
}

.application_button_wrapper {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;

  .application_button {
    display: inline-block;
    margin-bottom: 0;
    border-radius: 50%;
    color: $white;
    width: 204px;
    position: relative;
    transform: rotate(-21deg);
    @include fontSize(30);
    @include lineHeight(36);
    font-size: 30px;
    line-height: 36px;
    font-weight: bold;

    &:before {
      content: '';
      display: block;
      padding-bottom: 100%;
      background: $abellio-red;
      border-radius: 50%;
    }

    span {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
    }
  }
}

.job_search_element {
  margin-bottom: 65px;
}

.job_search_wrapper {
  width: 540px;
  max-width: 100%;
  margin: 0 auto;
}

.content_blocks_element {
  margin-bottom: 20px;
}

.station_loader_btn_wrapper {
  margin-bottom: 60px;
}

.content_block_item {
  margin-bottom: 60px;

  img {
    max-width: 100%;
  }

  .content_block_content {
    padding-right: 35px;
  }

  &.flex-row-reverse {
    .content_block_content {
      padding-left: 35px;
      padding-right: 0;
    }
  }

  .flex100 {
    .content_block_content {
      padding: 0 !important;
    }
  }

  .content_block_image {
    position: relative;
    height: 100%;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;

    img {
      max-width: 100%;
      display: block;
      margin-bottom: 0;
      opacity: 0;
    }

    .content_block_image_caption {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      padding: 15px 20px;
      color: $white;
      margin-bottom: 0;
      background: -moz-linear-gradient(left,
          rgba(0, 0, 0, 0.65) 75%,
          rgba(0, 0, 0, 0) 100%);
      /* FF3.6-15 */
      background: -webkit-linear-gradient(left,
          rgba(0, 0, 0, 0.65) 75%,
          rgba(0, 0, 0, 0) 100%);
      /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to right,
          rgba(0, 0, 0, 0.65) 75%,
          rgba(0, 0, 0, 0) 100%);
      /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#a6000000', endColorstr='#00000000', GradientType=1);
      /* IE6-9 */
    }
  }
}

.employees_carousel_element {
  margin-bottom: 70px;
}

.employees_carousel_wrapper {
  position: relative;
  // margin-top: 60px;
  margin-top: 100px;
  padding: 50px 155px;

  .swiper-slide {
    min-height: 61px;
    background: #fff;
    height: auto;
    margin-bottom: 20px;
    box-shadow: 7px 7px 17px rgba(0, 0, 0, 0.1);
  }

  // .swiper-container {
  //    overflow: visible;
  // }

  .employee_box_item {
    margin-left: 7px;
    margin-bottom: 50px;

    .employee_box_image_wrapper {
      position: relative;
      z-index: 2;
      // margin-bottom: -96px;
      margin-top: -96px;

      img {
        width: 192px;
        border-radius: 50%;
        display: block;
        margin: 0 auto;
      }
    }

    .employee_box_content_wrapper {
      position: relative;
      z-index: 1;
      // box-shadow: 7px 7px 17px rgba($black, 0.10);
      padding: 30px 20px 30px 20px;
      text-align: center;

      &:before {
        content: '';
        width: 139px;
        height: 99px;
        position: absolute;
        z-index: -1;
        background: url('../images/apostrophe.png') no-repeat center center;
        background-size: cover;
        top: -40px;
        left: 20px;
      }

      .employee_box_position {
        @include fontSize(20);
        @include lineHeight(26);
        color: $abellio-red;
        margin-bottom: 0;
        margin-top: 48px;
        font-style: italic;
      }
    }
  }
}

.abellio_world_element {
  margin-bottom: 60px;
  position: relative;

  .job_offers_map_element {
    margin-bottom: 30px;
  }

  .abellio_world_filter_wrapper {
    margin-bottom: 60px;
  }

  .abellio_world_filter_region {
    display: none;
    width: 100%;
    border-color: #000;
  }

  .abellio_world_filter_title {
    display: none;
    margin-bottom: 0;
    @include fontSize(22);
    @include lineHeight(28);
    width: 100%;
    text-align: center;
  }

  .abellio_world_filter_close {
    display: none;
    height: 25px;
    width: 100%;
    position: absolute;
    z-index: 10;
    top: 0;
    left: 0;
    margin-bottom: 0;

    &:before {
      content: '';
      position: absolute;
      top: 6px;
      left: 50%;
      transform: translate(-50%, 0);
      width: 36px;
      height: 5px;
      border-radius: 2.5px;
      background: #bebec0;
    }
  }

  .abellio_world_filter_close_button {
    display: none;
    position: absolute;
    z-index: 11;
    margin-bottom: 0;
    top: 25px;
    right: 25px;

    svg {
      display: block;
    }
  }

  .abellio_world_filter_trigger {
    display: block;
    margin: 16px 6px;
    width: 86px;
    text-align: center;

    .icon {
      display: block;
      width: 36px;
      height: 36px;
      background: $scrill-top-button-color;
      border-radius: 50%;
      margin-bottom: 6px;
      color: #fff;
    }

    &.is_passive {
      .icon {
        background: $scrill-top-button-color !important;
      }
    }
  }
}

.map_zip_code_cannger_wrapper {
  width: 100%;
  max-width: 450px;
  position: absolute;
  z-index: 2;
  right: calc(50% - (450px / 2));
  top: 0;
  bottom: 0;
  height: 145px;
  transform: translate(0, -50%);
  padding: 15px;
  color: $white;
  margin: auto;
  text-align: center;
  border-radius: 5px;
  transition: all 1s linear;
  transition-delay: 1s;

  &:after {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $black;
    box-shadow: 0 20px 40px #0000008a;
  }

  &:before {
    content: '';
    position: absolute;
    z-index: -2;
    width: 200vw;
    height: 100vh;
    background: rgba($black, 0.5);
    left: 50%;
    transform: translate(-50%, -50%);
    backdrop-filter: blur(6px);
    opacity: 1;
    pointer-events: none;
    transition: all 1s linear;
    transition-delay: 1s;
  }

  &.right-side {
    right: 0;
    max-width: 250px;

    &:before {
      opacity: 0;
    }
  }

  p {
    @include fontSize(16);
    @include lineHeight(22);
    margin-bottom: 20px;
  }

  fieldset {
    margin-bottom: 0;
    border-color: $white;
    width: 200px;
    margin-right: 15px;
    padding: 0 4px;

    label {
      @include fontSize(14);
      @include lineHeight(18);
    }

    input {
      @include fontSize(16);
      @include lineHeight(20);
      padding: 2px 12px 4px 12px;
      color: $white;
    }

    ::-webkit-input-placeholder {
      color: $white;
      opacity: 0.5;
    }

    :-moz-placeholder {
      color: $white;
      opacity: 0.5;
    }

    ::-moz-placeholder {
      color: $white;
      opacity: 0.5;
    }

    :-ms-input-placeholder {
      color: $white;
      opacity: 0.5;
    }

    ::-ms-input-placeholder {
      color: $white;
      opacity: 0.5;
    }

    ::placeholder {
      color: $white;
      opacity: 0.5;
    }
  }

  .plus_button {
    display: block;
    margin-bottom: 0;
    width: 40px;
    height: 40px;
    top: 3px;
    position: relative;
    border-radius: 3px;
    background-color: $abellio-red;
  }
}

.regions_page_element {
  margin-bottom: 60px;
}

.regions_section {
  background: $light-grey;

  .job_offers_map_element:before {
    border-left-color: $light-grey;
    border-right-color: $light-grey;
  }

  .custom-select-wrapper {
    width: 100%;
    max-width: 220px;
    display: inline-block;

    &.select_red {
      .custom-select {
        background: $abellio-red;
        border-color: $abellio-red;

        .selected {
          color: $white;

          &:after {
            background: url(../../assets/images/select_arrow_white.svg) no-repeat center top;
          }
        }
      }
    }
  }

  .region_changer_form_logo {
    margin-top: 30px;
    padding-left: 10px;
  }
}

.timetable_wrapper {
  position: relative;
  z-index: 5;
  padding-bottom: 0;
  color: $white;
  margin-bottom: 60px;
  background: #ce132d;

  &:after {
    content: '';
    position: absolute;
    z-index: -1;
    left: 0;
    top: 99.8%;
    width: 100%;
    height: 145px;
    border-top: 146px solid $abellio-red;
    border-left: 50vw solid transparent;
    border-right: 50vw solid transparent;
  }

  .timetable_inner_inner {
    // max-width: 830px;
    margin: 0 auto;
  }

  .timetable_title {
    @include fontSize(21);
    @include lineHeight(24);
    margin-bottom: 30px;
    text-align: center;
  }

  .timetable_inner_wrapper {
    margin: 0 auto;
    background: #ce132d;
    // padding: 110px 50px 120px 54px;
    padding: 0 48px;
    min-height: auto;
    height: 100%;
    // border: 1px solid white;

    box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.13),
      0px 2px 5px rgba(0, 0, 0, 0.07), 0px 1px 2px rgba(0, 0, 0, 0.06);
    border-radius: 12px;

    .close_button {
      bottom: -130px;
    }
  }

  .timetable_controls_wrapper {
    position: relative;
    display: flex;

    // padding-top: 48px;

    .timetable_controls_line {
      position: absolute;
      display: flex;
      justify-content: space-between;
      top: 0;
      left: 0;
      width: 100%;
      display: none;

      &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        transform: translate(0, -50%);
        width: 0;
        height: 2px;
        background: $white;
        transition: all 1.2s ease-in-out;
      }

      &.active:before {
        width: 100%;
      }

      .line_icon_end {
        opacity: 0;
        transition: all 1.2s ease-in-out;
      }

      &.active {
        .line_icon_end {
          opacity: 1;
        }
      }

      .timetable_controls_line_icon {
        position: relative;
        z-index: 2;
        display: block;
        border-radius: 50%;
        background: $abellio-red;

        svg {
          display: block;
        }
      }
    }

    .timetable_inputs_wrapper {
      display: flex;
      flex-direction: column;
      // justify-content: space-between;

      .timetable_input_wrapper {
        width: 632px;
        // border: 1px solid red;
        // position: relative;
        // width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        margin-bottom: 16px;

        // &:first-child {
        //    input {
        //       &::-webkit-input-placeholder {
        //          font-weight: bold;
        //          color: #000;
        //          opacity: 1;
        //       }

        //       &::placeholder {
        //          font-weight: bold;
        //          color: #000;
        //          opacity: 1;
        //       }
        //    }
        // }

        .dest-icon {
          height: 30px;
          width: 30px;
          background: $abellio-gray;
          border-radius: 50%;
          position: absolute;
          top: 10px;
          left: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: $white;
          font-size: 14px;
          line-height: 100%;
          font-weight: normal;
        }

        .input-arrows {
          position: absolute;
          right: 20px;
          width: 20px;
          top: 50%;
          transform: translateY(-50%);
          background-color: transparent;
          outline: 0;

          &:focus {
            border: none;
            outline: 0;
            box-shadow: none;
          }
        }

        input {
          // border: 0.5px solid rgba(107, 113, 122, 0.75);
          border: 1px solid $abellio-gray;
          border-radius: 2px;
          padding: 19px 19px 16px 56px;
          font-weight: 400;
          font-size: 14px;
          line-height: 110%;
          margin-bottom: 0;
          color: $abellio-gray;
          // margin-bottom: 0;
          width: 500px;
          font-family: Frutiger, Helvetica, Arial, sans-serif !important;
        }

        ::-webkit-input-placeholder {
          font-weight: normal;
          color: black;
          opacity: 0.7;
        }

        :-moz-placeholder {
          font-weight: normal;
          color: black;
          opacity: 0.7;
        }

        ::-moz-placeholder {
          font-weight: normal;
          color: black;
          opacity: 0.7;
        }

        :-ms-input-placeholder {
          color: black;
          opacity: 0.7;
        }

        ::-ms-input-placeholder {
          font-weight: normal;
          color: black;
          opacity: 0.7;
        }

        ::placeholder {
          font-weight: normal;
          color: black;
          opacity: 0.7;
        }

        label {
          // position: absolute;
          // top: 8px;
          // left: 10px;
          font-weight: 400;
          font-size: 14px;
          line-height: 110%;
          margin-bottom: 0;
          color: $abellio-gray;
          margin-bottom: 0;
          margin-right: 32px;
        }

        .timetable_input_autocomplete_wrapper {
          position: relative;
          z-index: 1000;

          .timetable_input_autocomplete {
            // border: 1px solid $abellio-red;
            // border-top: 0 none;
            // display: none;
            // position: absolute;
            // top: 100%;
            // left: -1px;
            // right: -1px;
            // background: $white;
            // padding: 20px;
            // padding-right: 0;
            // border-radius: 2px;
            // height: 340px;
            // color: $black;
            border: 1px solid #ce132d;
            border-top: 0 none;
            display: none;
            position: absolute;
            top: 100%;
            left: -1px;
            right: -1px;
            background: #fff;
            padding: 59px;
            padding-right: 0;
            border-radius: 0px;
            height: 340px;
            color: #000;

            &.is_open {
              display: block;
            }

            .timetable_input_autocomplete_inner {
              max-height: 100%;
              overflow: hidden;
              overflow-y: auto;
              padding-right: 20px;
              box-shadow: none;
              top: 0;
            }

            .timetable_input_autocomplete_item {
              display: flex;
              align-items: center;
              position: relative;
              margin-bottom: 0;
              padding-top: 10px;
              padding-bottom: 10px;
              border-top: 1px solid $abellio-red;
              padding-left: 76px;
              min-height: 60px;
              margin-left: 15px;
              height: 100%;
              flex: 1 0 auto;

              .icon {
                position: absolute;
                left: 0;
                top: 50%;
                transform: translate(0, -50%);
                display: flex;
                justify-content: center;
                align-items: center;
                width: 40px;
                height: 40px;
                border-radius: 50%;
                font-size: 25px;
              }

              &:first-child {
                margin-top: 0;
                border-top: 0 none;
              }

              &.location_home {
                .icon {
                  background: #49b170;
                }
              }

              &.location_work {
                .icon {
                  background: #0071bc;
                }
              }

              &.location_home,
              &.location_work {
                .icon {
                  color: #fff;
                  font-size: 18px;
                }
              }
            }
          }

          .loading-icon {
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            display: flex;
            align-items: center;

            svg {
              stroke: $abellio-red;
              width: 40px;
              height: 40px;
            }
          }
        }
      }

      .departing_trains_select {
        padding-top: 10px;
        padding-left: 10px;
        padding-bottom: 10px;
        margin-bottom: -10px;

        &.is_open {
          background: $white;
          border-radius: 2px 2px 0 0;
          box-shadow: inset 0 2px 4px rgba($black, 0.15);
        }

        .custom-select {
          .selected {
            z-index: 20;

            &.open {
              color: $black;

              &:after {
                background: url(../../assets/images/select_arrow2.svg) no-repeat center center;
                background-size: contain;
              }
            }
          }

          .items {
            border: 0 none;
            left: -9px;
            right: 1px;
            border-radius: 0 0 2px 2px;
            margin-top: 0;

            .item {
              padding: 8px 10px 3px 10px;

              &:before {
                left: 10px;
                right: 10px;
              }

              &:hover {
                background: $white;
                color: $abellio-red;
              }

              .select_timetable_content {
                display: flex;
                font-weight: bold;
                @include fontSize(18);
                @include lineHeight(24);

                .select_timetable_time {
                  width: 100px;

                  span {
                    position: relative;
                    top: -2px;
                    margin-left: 5px;
                    @include fontSize(14);
                    @include lineHeight(18);
                  }
                }

                .select_timetable_title {
                  flex-grow: 1;
                }
              }
            }

            .select_timetable_header+.item {
              &:before {
                display: none;
              }
            }

            .select_timetable_header {
              cursor: default;
              color: $black !important;

              .select_timetable_content {
                @include fontSize(14);
                @include lineHeight(16);
                font-weight: normal;
              }
            }
          }
        }
      }

      .timetable_switch_button_wrapper {
        margin: 0 50px;

        .timetable_switch_button {
          display: block;
          background: transparent;
          padding: 0;
          margin-top: 6px;
          margin-bottom: 0;
          width: 40px;
          height: 40px;

          svg {
            display: block;
          }
        }
      }
    }
  }

  .timetable_controls_wrapper.time {
    align-items: center;
    justify-content: flex-end;

    label {
      font-weight: 400;
      font-size: 14px;
      line-height: 110%;
      margin-bottom: 0;
      color: #6b717a;
      margin-right: 32px;
    }

    .timetable_datetime_wrapper {
      // border: 0.5px solid rgba(107, 113, 122, 0.75);
      border: none;
      border-radius: 2px;
      padding: 10px 19px 10px 10px;
      font-weight: 400;
      font-size: 14px;
      line-height: 110%;
      margin-bottom: 0;
      color: $abellio-gray;
      width: 500px;
      margin-top: 0;
      display: flex;
      justify-content: space-between;
      align-items: center;

      span,
      a {
        font-weight: 400;
        font-size: 14px;
        line-height: 110%;
        color: $abellio-gray;
        height: auto;
        min-height: auto;
      }

      button.plain {
        padding: 0;
        background: none;
        border: none;
        color: $abellio-gray;
        text-decoration: underline;
        font-weight: 700;
        font-size: 18px;
        line-height: 110%;
        align-items: center;
      }
    }
  }

  .login_section_wrapper {
    padding: 110px 50px 50px 54px;

    .desc {
      max-width: 30%;
      margin: 25px auto 20px;
      text-align: center;

      p {
        line-height: 1.5;
        margin: 0;
        @include fontSize(15);
      }
    }
  }
}

.timetable_result_banner {
  .timetable_inner_wrapper {
    padding: 110px 50px 20px 54px;
    border-radius: 0;
    background-color: #fff;
    box-shadow: none;
  }

  .timetable_wrapper {
    background-color: $white;
    border: none;

    &:after {
      border-top: 146px solid #fff;
    }
  }

  h5,
  p {
    color: $abellio-gray;
  }

  .btn {
    padding: 10px 20px 10px 20px !important;
  }
}

.timetable_inner_result_wrapper {
  .timetable_inner_result_addresses {
    text-align: left !important;
  }
}

.timetable_results_content {
  margin-top: 200px;
}

.timetable_datetime_wrapper {
  margin-top: 30px;
  display: flex;
  justify-content: center;

  .timetable_time_wrapper,
  .timetable_date_wrapper {
    position: relative;
    display: flex;

    img {
      margin-right: 17px;
    }
  }

  .date-time-picker,
  .date-time-picker:not(.inline),
  .date-time-picker a.date_trigger {
    position: absolute !important;
    display: block;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    cursor: pointer;
  }

  .date-time-picker a.date_trigger {
    z-index: 1;
  }

  .datetimepicker:not(.inline) .pickers-container {
    height: calc(100% - 175px) !important;
  }

  .date-time-picker a.date_trigger {
    opacity: 0;
    background: red;
  }

  .timetable_time,
  .timetable_date {
    position: relative;
    min-height: auto;
    padding-left: 0;
    display: flex;
    align-items: center;

    .icon {
      display: block;
      position: absolute;
      left: 0;
      top: 5px;

      i {
        font-size: 32px;
      }
    }
  }

  .timetable_date {
    margin-right: 10px;
  }
}

.timetable_my_locations_wrapper {
  margin-top: 40px;
  margin-bottom: 20px;
  max-width: 630px;
  margin: 40px auto;
  display: flex;
  align-content: center;
  justify-content: space-between;

  .location-wrap {
    display: flex;
    align-content: center;

    .timetable_my_locations_title {
      margin-right: 36px;
      margin-bottom: 0;
      font-weight: 300;
      font-size: 14px;
    }
  }

  .timetable_submit_wrapper {
    margin-top: 0;

    @include media-breakpoint-down(sm) {
      margin-top: 15px;
    }
  }

  .timetable_my_locations_title {
    text-align: center;
    @include fontSize(18);
    @include lineHeight(24);
    font-weight: bold;
    margin-bottom: 8px;
  }

  @include media-breakpoint-down(sm) {
    flex-direction: column;

    .location-wrap {
      justify-content: space-between;
    }
  }

  .timetable_my_locations {
    display: flex;
    justify-content: center;

    .timetable_my_locations_item {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 0;
      margin-left: 16px;
      background: $white;
      border-radius: 50%;
      width: 40px;
      height: 40px;

      &:first-child {
        margin-left: 0;
      }

      i {
        color: $abellio-red;
      }

      &.my_location_home {
        i {
          color: #49b170;
        }
      }

      &.my_location_work {
        i {
          color: #0071bc;
        }
      }
    }
  }
}

.swiper-pagination-bullet:only-child {
  visibility: hidden;
}

.timetable_submit_wrapper {
  .btn {
    padding: 16px 24px 14px 24px;
    font-weight: 700;
    font-size: 18px;
    line-height: 100%;
    color: $white;

    &.btn-opacity {
      min-width: 130px;
      padding: 10px 20px;
    }
  }

  .timetable_inner_inner {
    width: 632px;
    display: flex;
    justify-content: flex-end;

    @include media-breakpoint-down(sm) {
      width: auto;
    }
  }

  .timetable_submit_wrapper_logo {
    margin-top: 30px;
    padding-left: 10px;
  }

  &_in {
    max-width: 630px;
    margin: 0 auto;
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    text-align: right;

    .timetable_inner_inner {
      max-width: 500px;
      width: 100%;
      margin: unset;
      justify-content: flex-end;

      a {
        font-weight: 700;
        text-decoration: underline;
        color: $abellio-gray;
        margin: 0;
        display: flex;
        align-items: center;
      }

      @include media-breakpoint-down(sm) {
        flex-direction: column;

        a {
          font-size: 16px;
          margin-bottom: 1rem;
        }
      }
    }
  }
}

.timetable_inner_result_wrapper {
  p {
    @include fontSize(18);
    @include lineHeight(24);
    font-weight: bold;
    margin-bottom: 0;
    margin-top: 5px;
  }

  .timetable_inner_result_addresses {
    position: relative;
    padding-left: 32px;
    margin-left: -32px;
    text-align: center;

    .timetable_inner_result_line {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      padding: 2px 0;

      &:before {
        content: '';
        position: absolute;
        left: 50%;
        top: 2px;
        bottom: 2px;
        width: 2px;
        background: $abellio-gray;
        transform: translate(-50%, 0);
      }

      .timetable_inner_result_line_icon {
        position: relative;
        z-index: 1;
        display: block;
        background: $abellio-red;
        border-radius: 50%;

        svg {
          display: block;
          margin-bottom: 0;
        }
      }
    }
  }
}

.timetable_result_list {
  .timetable_result_list_item {
    display: block;
    margin-bottom: 26px;
    padding-bottom: 10px;
    border-bottom: 1px solid $abellio-red;

    &:hover {
      cursor: pointer;

      .road_parts_wrapper {
        &:before {
          width: 100% !important;
        }
      }
    }

    .timetable_result_list_item_part1 {
      display: flex;

      .start_time {
        width: 60px;
        @include fontSize(18);
        @include lineHeight(22);
      }

      .end_time {
        width: 70px;
        @include fontSize(18);
        @include lineHeight(22);
        padding-left: 25px;
        position: relative;

        .icon {
          position: absolute;
          top: 0;
          left: 0;

          svg {
            display: block;
            margin-bottom: 0;
            @include svgColor($abellio-red);
          }
        }
      }

      .road_parts_wrapper {
        display: flex;
        flex-grow: 1;
        position: relative;

        &:before {
          content: '';
          position: absolute;
          left: 0;
          top: 8px;
          width: 0;
          transition: width 1.2s ease-in-out;
          height: 2px;
          background: $abellio-red;
          z-index: 2;
        }

        .road_parts_item {
          position: relative;
          margin: 0;
          -ms-flex-preferred-size: 0;
          flex-basis: 0;
          -webkit-box-flex: 1;
          -ms-flex-positive: 1;
          flex-grow: 1;
          max-width: 100%;

          @include fontSize(14);
          @include lineHeight(16);
          padding-top: 30px;

          .road_parts_arrow {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 18px;

            &:before {
              content: '';
              position: absolute;
              z-index: 1;
              top: 0;
              left: 0;
              width: 18px;
              height: 18px;
              background: $abellio-red;
              border-radius: 50%;
            }

            &:after {
              content: '';
              position: absolute;
              z-index: 0;
              top: 50%;
              left: 0;
              width: 100%;
              height: 2px;
              background: #4a4a4a;
              transform: translate(0, -50%);
            }
          }
        }
      }
    }

    .timetable_result_list_item_part2 {
      margin-top: 16px;
      display: flex;
      justify-content: center;

      .timetable_result_list_item_time {
        opacity: 0.6;
        @include fontSize(14);
        @include lineHeight(16);
        display: flex;
        align-items: center;
        padding-left: 26px;
        position: relative;
        margin-right: 44px;

        .icon {
          position: absolute;
          top: 50%;
          left: 0;
          transform: translate(0, -50%);

          i {
            font-size: 20px;
          }
        }
      }

      .timetable_result_list_item_changes {
        opacity: 0.6;
        @include fontSize(14);
        @include lineHeight(16);
      }
    }
  }
}

.timetable_filter_select {
  width: 260px;
  max-width: 100%;
  margin-bottom: 30px;

  .custom-select {
    background: $white;
    border: 0 none;
    box-shadow: 0 2px 4px rgba($black, 0.2);
  }
}

.carousel_content_wrapper {
  .swiper-slide {
    height: auto;
  }
}

.gm-style-iw-d {
  overflow-x: hidden !important;
}

.gmnoprint.gm-bundled-control {
  top: auto !important;
  bottom: 222px;
}

.vue-map-container {
  .gm-style {
    font-family: Frutiger, Helvetica, Arial, sans-serif !important;
    @include fontSize(18);
    @include lineHeight(26);

    .gm-style-iw {
      border-radius: 2px;
      padding: 0;

      &>button {
        top: 0 !important;
        right: 0 !important;
      }

      .gm-style-iw-d {
        overflow-x: hidden !important;

        .abellio_info_box.job_info_box {
          width: 200px;
          margin: 0;
        }
      }
    }

    .info-window {
      position: relative;
      background: transparent;
      width: 200px;
      height: auto;
      top: auto;
      left: auto;
      transform: translate(0, 0);
      margin: 0;
      border: 0;

      .abellio_info_box {
        margin: 0;
        border: 0;
        box-shadow: none;
        min-height: 200px;
      }

      &.ticket_sales_info_window {
        width: 300px;

        .abellio_info_box {
          min-height: 300px;
        }
      }

      .abellio_info_box_content {
        padding: 12px 12px 30px 12px;
      }

      i.icon {
        position: absolute;
        left: 0;
        top: 2px;
      }

      svg [fill='#ffffff'] {
        fill: #ce132d;
        transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
      }

      .abellio_info_box_address {
        position: relative;
        padding-left: 25px;
      }

      .abellio_info_box_title {
        @include fontSize(18);
        @include lineHeight(24);
        font-weight: 700;
        margin-bottom: 8px;
        // min-height: 50px;
      }
    }
  }
}

.register_page,
.login_page {

  input[type='text'],
  input[type='email'],
  input[type='password'],
  .custom-select {
    background: $white;
    color: $black;
    border: 0 none;
    margin-bottom: 20px;
    padding: 13px 14px 12px 14px;
  }

  .custom-select {
    padding: 0;

    .selected {
      padding: 14px 36px 13px 14px;
    }
  }

  .vf-field-invalid.vf-field-submitted,
  .vf-field-invalid.vf-field-invalid-email {

    input,
    .custom-select .selected {
      color: $abellio-red;
    }

    ::-webkit-input-placeholder {
      color: $black;
      opacity: 0.6;
    }

    :-moz-placeholder {
      color: $abellio-red;
      opacity: 0.6;
    }

    ::-moz-placeholder {
      color: $abellio-red;
      opacity: 0.6;
    }

    :-ms-input-placeholder {
      color: $abellio-red;
      opacity: 0.6;
    }

    ::-ms-input-placeholder {
      color: $abellio-red;
      opacity: 0.6;
    }

    ::placeholder {
      color: $abellio-red;
      opacity: 0.6;
    }
  }

  .vf-field-invalid .vf-error_message {
    color: #fff;
    border-radius: 2px;
    margin-top: -16px;
    margin-bottom: 20px;
  }

  .register_links {
    color: #fff;
  }

  .login_wrapper {
    width: 330px;
    max-width: 100%;
    margin: 0 auto;

    .vf-field-pristine {
      &:focus {
        outline: 0;
      }
    }
  }

  .login_email_wrapper,
  .login_password_wrapper {
    position: relative;

    .icon {
      position: absolute;
      z-index: 1;
      color: $abellio-dark-grey;
      font-size: 20px;
      transform: translate(50%, 50%);
      top: 2px;
      left: 4px;
    }

    input {
      padding-left: 46px;
    }
  }
}

.login_page {
  .timetable_element {
    margin-bottom: 200px;
  }

  .close_button {
    z-index: 10;
    // filter: invert(100%);
  }
}

.register_page {
  .timetable_element {
    margin-bottom: 70px;
  }
}

.account_boxes_item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 0 24px;
  margin-bottom: 30px;

  .icon {
    padding: 16px;
    width: 126px;
    height: 126px;
    // background: $abellio-red;
    background: hsla(0, 0%, 84.7%, 0.6);
    // border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 34px;

    svg {
      display: block;
      margin-bottom: 0;

      path {
        fill: #000;
      }
    }
  }

  &:hover,
  &.is_active {
    @include svgColor($abellio-red);

    .icon {
      @include transition(0.3);
      // background: white;
      // border: 2px solid $abellio-red;
    }
  }
}

.account_information_element {
  .account_information_title {
    margin-bottom: 40px;
  }

  .account_information_table {
    margin-bottom: 30px;
    table-layout: fixed;

    th {
      text-align: left;
    }

    td {
      border: 0 none;
    }

    .color_green {
      color: #29c527;
    }

    .color_yellow {
      color: #ffc856;
    }

    .color_red {
      color: #ea2c2c;
    }
  }

  .account_saved_rides_table {
    width: auto;
    max-width: 100%;

    td {
      padding: 1px 16px 1px 0px;
    }
  }

  hr {
    background: $abellio-red;
    margin: 36px 0;
  }

  .br_line {
    max-width: 1190px;
    margin: 50px auto;
    border-bottom: 1px solid $abellio-red;
    padding: 0 15px;
  }

  button {
    background-color: #a0a0a0 !important;
  }
}

.favorite_places_title {
  margin-top: 50px;
}

.favorite_places_content {
  .favorite_places_wrapper {
    .favorite_places_item {
      padding-left: 70px;

      .icon {
        left: 0;
      }
    }
  }
}

.html-content {
  a {
    color: $abellio-red;
    text-decoration: underline;
    transition: color 0.3s ease-in-out;

    @include media-breakpoint-down(sm) {
      @include fontSize(16);
      @include lineHeight(24);
    }

    &:hover,
    &:focus {
      color: $black;
      text-decoration: none;
      outline-offset: 0;
      outline: transparent;
    }
  }

  table {
    border: 1px solid $black;
    border-collapse: collapse;

    th,
    td {
      border: 1px solid $black;
      padding: 8px;

      &:last-child {
        min-width: 100px;
        text-align: left;
      }
    }
  }
}

.account_boxes_element {
  &.dark_grey_bg {
    background: $dark-gray;
    padding-top: 24px;

    .account_boxes_item {
      .icon {
        svg {
          @include svgColor($white);
        }
      }

      &:hover,
      &.is_active {
        .icon {
          svg {
            @include svgColor($abellio-red);
          }
        }
      }
    }
  }
}

.__cov-progress {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.4), 0 0 3px inset rgba(0, 0, 0, 0.8);
}

.jobs_navigator_form_element {
  margin-bottom: 0;
  padding-top: 94px;
  position: relative;
  padding-bottom: 145px;

  &.applications_wizard_form_element {
    padding-top: 145px;
    overflow-x: hidden;
  }

  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    width: 100%;
    border-top: 145px solid transparent;
    border-left: 50vw solid $dark-gray;
    border-right: 50vw solid $dark-gray;
  }

  .jobs_navigator_menu {
    background: $light-grey;
    padding: 14px 0 10px 0;
    display: flex;
    margin-bottom: 40px;

    .jobs_navigator_menu_inner {
      display: flex;
      justify-content: center;
    }

    .jobs_navigator_menu_item {
      display: block;
      position: relative;
      margin-left: 25px;
      padding-bottom: 4px;
      margin-bottom: 0;
      @include fontSize(18);
      @include lineHeight(26);
      font-weight: bold;
      opacity: 0.33;
      @include transition(0.3);
      cursor: default;

      &:first-child {
        margin-left: 0;
      }

      &:before {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 0;
        height: 3px;
        background: $abellio-red;
        @include transition(0.3);
      }

      &.is_available {
        opacity: 1;
        cursor: pointer;
      }

      &.is_active {
        opacity: 1;

        &:before {
          width: 100%;
        }
      }
    }
  }

  .jobs_navigator_container {
    padding-left: 30px;
    padding-right: 30px;
  }
}

.jobs_navigator_progress_wrapper {
  width: 588px;
  max-width: 100%;
  margin: 0 auto;

  p {
    opacity: 0.33;
    @include fontSize(14);
    @include lineHeight(18);
    font-weight: bold;
    margin-bottom: 10px;
  }

  .jn_progress_wrapper {
    border: 1px solid $black;
    height: 40px;
    border-radius: 20px;
    padding: 2px;

    span {
      content: '';
      display: block;
      border-radius: 18px;
      width: 0%;
      height: 34px;
      background: $abellio-red;
      @include transition(0.3);
    }
  }
}

.custom-checkboxes-tags-wrapper {
  overflow: hidden;
  margin-bottom: 36px;

  .custom-checkboxes-tags-inner {
    margin: -10px -15px;
  }

  .checkbox_option {
    background: $lighter-grey;
    display: block;
    margin: 10px 15px;
    cursor: pointer;
    font-weight: normal;
    @include fontSize(18);
    @include lineHeight(26);
    padding: 10px 18px;
    border-radius: 22px;
    @include transition(0.3);

    &.is_checked {
      background: $abellio-red;
      color: $white;
    }

    input {
      display: none;
    }
  }
}

.jobs_navigator_info_wrapper {
  h2 {
    @include fontSize(32);
    @include lineHeight(36);
    margin-bottom: 24px;
  }

  p {
    @include fontSize(14);
    @include lineHeight(20);
    margin-bottom: 36px;
  }
}

.aplications_button_wrapper {
  margin: -8px;
  padding-bottom: 40px;

  .btn {
    margin: 8px;
  }
}

.applications_wizard_form_element {
  .jobs_navigator_inner {
    margin-bottom: 40px;
  }
}

.applications_other {
  margin-bottom: 20px;
}

.applications_wizard_data_import_box {
  border: 1px solid $black;
  border-radius: 2px;
  padding: 15px;

  .applications_wizard_data_import_row {
    margin-top: 42px;
    flex-wrap: wrap;

    &:first-child {
      margin-top: 0;
    }
  }

  .applications_wizard_importers_wrapper {
    display: flex;
  }

  p {
    margin-bottom: 0;
  }

  .applications_wizard_importer,
  .applications_wizard_add_button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-bottom: 0;
    color: $abellio-red;
    margin-left: 50px;

    &:first-child {
      margin-left: 25px;
    }

    .icon {
      display: block;
      margin-bottom: 5px;

      svg {
        display: block;
        @include svgColor($abellio-red);
      }
    }
  }
}

.applications_educations_jobs_wrapper {
  border: 1px solid $black;
  border-radius: 2px;
  padding: 20px 15px 60px 15px;
  position: relative;

  .applications_educations_jobs_item {
    margin-bottom: 20px;

    p {
      margin-bottom: 5px;
    }

    .applications_educations_jobs_date {
      color: #828080;
    }
  }

  .applications_educations_jobs_add_button {
    position: absolute;
    display: block;
    margin-bottom: 0;
    bottom: 20px;
    left: 15px;

    .icon {
      display: block;

      svg {
        display: block;
        margin-bottom: 0;
        @include svgColor($abellio-red);
      }
    }
  }
}

.applications_termin_wrapper {
  border: 1px solid $black;
  border-radius: 2px;
  padding: 20px 15px 0px 15px;
  margin-bottom: 30px;

  .applications_termin_add_button {
    display: block;
    margin-bottom: 20px;
    bottom: 20px;
    left: 15px;

    .icon {
      display: block;
      width: 26px;
      height: 26px;

      svg {
        display: block;
        margin-bottom: 0;
        @include svgColor($abellio-red);
      }
    }
  }
}

.summary_hr {
  margin: 40px 0;
  background: $abellio-red;
}

.favorite_places_wrapper {
  .favorite_places_item {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    padding-left: 110px;
    position: relative;
    min-height: 42px;
    @include fontSize(18);
    @include lineHeight(26);

    .icon {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      left: 44px;
      top: 50%;
      transform: translate(0, -50%);
      width: 42px;
      height: 42px;
      border-radius: 50%;
      background: $abellio-red;
      color: #fff;
    }
  }
}

#app {
  .vc-container {
    border-radius: 2px;

    .vc-btn {
      color: $black !important;
    }
  }

  .screen-rotator {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    z-index: 999;
    background-color: rgba(0, 0, 0, 0.9);
    overflow: hidden;

    >div {
      background-image: url('../../assets/images/rotate-device.png');
      background-position: center;
      background-repeat: no-repeat;
      background-size: 100% auto;
      width: 150px;
      height: 150px;
    }

    >span {
      margin-top: 20px;
      color: #fff;
      font-size: 14px;
    }
  }
}

.timetable_information_wrapper {
  position: relative;

  .timetable_information_title {
    @include fontSize(22);
    @include lineHeight(28);
    margin-bottom: 0;
  }

  .timetable_information_info {
    margin-bottom: 20px;
    display: flex;
    opacity: 0.6;

    p {
      margin-bottom: 10px;
      margin-right: 60px;
      @include fontSize(14);
      @include lineHeight(16);
      position: relative;
      padding-left: 26px;

      .icon {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        color: $black;
        font-size: 20px;

        .ab-icon-marker-o {
          font-size: 17px;
        }
      }
    }
  }

  .timetable_information_hint {
    @include fontSize(10);
    @include lineHeight(12);
    margin-bottom: 10px;
  }
}

.timetable_information_extra_wrapper {
  position: absolute;
  z-index: 101;
  top: 0;
  right: 0;

  &.inline-w-heading {
    position: relative;
    top: 8px;
  }

  .timetable_information_extra_trigger {
    display: block;
    margin-bottom: 0;

    .icon {
      display: block;

      svg {
        display: block;
        margin-bottom: 0;
      }
    }
  }

  .timetable_information_extras {
    position: absolute;
    z-index: 100;
    top: 50%;
    right: 100%;
    margin-right: 0;
    width: 250px;
    padding: 20px 25px;
    background: $white;
    border-radius: 2px;
    transform: scale(0.5) translate(50%, -50%);
    @include transition(0.3);

    opacity: 0;
    pointer-events: none;

    &.is_active {
      transform: scale(1) translate(0, 0);
      margin-right: 36px;
      pointer-events: auto;
      opacity: 1;
    }

    &:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(50%, 0);
      border-top: 16px solid $white;
      border-left: 14px solid transparent;
      border-right: 14px solid transparent;
      border-radius: 2px;
    }

    .timetable_information_extras_item {
      display: flex;
      align-items: center;
      margin-bottom: 0;
      margin-top: 20px;

      @include fontSize(18);
      @include lineHeight(20);

      &:first-child {
        margin-top: 0;
      }

      .icon {
        display: block;
        margin-bottom: 0;
        margin-right: 12px;

        svg {
          display: block;
          margin-bottom: 0;
          @include svgColor($abellio-red);
        }
      }
    }
  }
}

.timetable_information_extras_overlay {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: $black;
  opacity: 0;
  pointer-events: none;
  @include transition(0.3);

  &.is_active {
    pointer-events: auto;
    opacity: 0.4;
  }
}

.timetable_information_stations_wrapper {
  display: flex;
  position: relative;
  margin-bottom: 30px;

  .timetable_information_stations_item {
    flex-basis: 100%;
    padding-right: 20px;
    position: relative;
    padding-top: 50px;

    &:before {
      content: '';
      position: absolute;
      top: 9px;
      left: 0;
      right: 0;
      height: 2px;
      background: #4a4a4a;
    }

    .circle {
      display: block;
      position: absolute;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      border: 2px solid $abellio-red;
      left: 0;
      top: 0px;
      background: $abellio-red;

      &:before {
        content: '';
        position: absolute;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: $abellio-red;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    &:last-child {
      &:before {
        display: none;
      }

      .circle {
        background: $white;
      }
    }

    .timetable_information_stations_item_hints {
      max-height: 0;
      overflow: hidden;
      transition: max-height 0.4s ease-in-out;

      &.is-active {
        max-height: 500px;
      }
    }

    .timetable_information_stations_item_hint-switch {
      width: 100%;
      background-color: transparent;
      color: $abellio-red;
      outline: 0;
      font-size: 11px;
      text-align: left;
      padding-left: 0;

      i {
        transition: transform 0.3s linear;
        display: inline-block;
        margin-left: 5px;
      }

      &.is-active {
        i {
          transform: rotate(180deg);
        }
      }
    }

    .timetable_information_stations_item_row {
      display: flex;
      margin-bottom: 6px;

      .timetable_information_stations_item_col1 {
        width: 60px;
        margin-right: 10px;
        display: flex;
        align-items: center;
      }

      .timetable_information_stations_item_col2 {
        flex-grow: 1;
        display: flex;
        align-items: center;
      }
    }

    .timetable_information_stations_time {
      @include fontSize(18);
      @include lineHeight(20);
      min-height: 20px;
      display: flex;
      align-items: center;
      margin-bottom: 0;
    }

    .timetable_information_stations_title {
      @include fontSize(18);
      @include lineHeight(20);
      min-height: 20px;
      margin-bottom: 0;
      font-weight: normal;
      display: flex;
      align-items: center;
    }

    .timetable_information_stations_icon {
      display: block;
      margin-bottom: 0;
      margin-left: 12px;

      svg {
        max-height: 20px;
      }
    }

    .timetable_information_stations_item_line {
      display: inline-block;
      margin-bottom: 0;
      text-transform: uppercase;
      @include fontSize(14);
      @include lineHeight(16);
      padding: 2px 4px;
      border-radius: 2px;
      background: $dark-gray;
      color: $white;
    }

    .timetable_information_stations_item_info_part {
      @include fontSize(14);
      @include lineHeight(16);
      margin-right: 10px;
      margin-bottom: 0;
    }

    .timetable_information_stations_extra_content {
      opacity: 0.5;

      .timetable_information_stations_extra_title {
        display: block;
        @include fontSize(14);
        @include lineHeight(16);
        margin-bottom: 0;
        position: relative;

        .icon {
          display: block;
          margin-bottom: 0;
          position: absolute;
          top: 0;
          left: -45px;

          svg {
            display: block;
            margin-bottom: 0;
            @include svgColor($black);
          }
        }
      }
    }
  }
}

.timetable_submit_wrapper {
  margin-top: 30px;
  // position: absolute;
  // // bottom: 60px;
  // bottom: -85px;
  // left: 0;
  // right: 0;
}

.register_page {
  .timetable_submit_wrapper {
    position: relative;
    bottom: 0px;
    z-index: 5;
  }
}

.share-popup {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 10px;
  background: #fff;
  height: calc(100% - 35px);
  z-index: 2;
  visibility: hidden;
  opacity: 0;
  transform: translate(0, 50%);
  transition: all 0.3s linear;

  a {
    display: block;
    font-size: 13px;
    border-bottom: 1px solid #ddd;
    padding: 10px 0px;
    margin: 0;

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      border-bottom: 0;
    }

    .icon {
      svg {
        width: 18px;
        position: relative;
        top: 5px;
        margin-right: 5px;
        fill: $abellio-red;
      }
    }
  }

  button.close-share-popup {
    position: absolute;
    right: 0;
    top: 0;
    background: transparent;
    padding: 5px;
    outline: 0;

    img {
      width: 18px;
    }
  }

  &.visible {
    visibility: visible;
    opacity: 1;
    transform: translate(0, 0);
  }
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

.animate__fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.search_trigger {
  background: rgba($abellio-gray, 0.1);
  border-radius: 44px;
  display: inline-block;
  padding: 10px 12px;
  font-size: 14px;
  margin: 0;
  color: $abellio-gray;

  i {
    color: $abellio-gray;
    font-size: 14px;
    position: relative;
    margin-right: 10px;
  }

  span {
    font-size: 14px;
    line-height: 14px;
  }
}

.search-inner {
  flex-direction: column;
  width: 900px !important;
  max-width: 90% !important;
  justify-content: center;
  padding-bottom: 60px;
  display: flex;

  .input-wrapper {
    border-bottom: 2px solid $abellio-red;
    // padding-bottom: 15px;
  }

  .search-icon {
    color: $abellio-red;
    margin-top: 18px;
    font-size: 32px;
  }

  .info-text {
    @include fontSize(14);
    text-align: left;
  }

  .search-input {
    border: 0;
    font-size: 2.5rem;
    line-height: 2.5rem;
    margin: 0;

    &::-webkit-input-placeholder {
      color: $dark-gray;
      font-weight: 100;
    }

    &::-moz-placeholder {
      color: $dark-gray;
      font-weight: 100;
    }

    &::-ms-input-placeholder {
      color: $dark-gray;
      font-weight: 100;
    }
  }

  .clear-input {
    background-color: transparent;
    outline: 0;
    color: #ccc !important;
    top: 5px;
    position: relative;
    margin: 0;
  }

  .clear-icon {
    width: 25px;
    color: $abellio-red;
  }
}

.line-info {
  .heading {
    .title {
      font-size: 4rem;
      margin-top: 10px;
    }
  }

  .types {
    .a-type {
      display: block;
      padding: 5px 10px;
      color: #fff;
      font-weight: 700;
      margin-left: 15px;

      span {
        display: block;
        font-weight: 300;
        @include fontSize(12);
      }
    }
  }
}

.downloads-wrapper {
  .filters {
    margin-top: 8px;

    a {
      display: inline-block;
      padding: 5px 15px;
      margin-right: 10px;
      border: 1px solid #ddd;
      transition: all 0.4s linear;

      &:not(.active) {
        &:hover {
          background-color: $abellio-dark-grey;
          color: #fff;
        }
      }

      &.active {
        background-color: $abellio-red;
        border-color: $abellio-red;
        color: #fff;
      }
    }
  }

  .a-download {
    box-shadow: $box-shadow;
    position: relative;
    margin-bottom: 15px;

    .inner {
      padding: 15px;

      .title {
        color: $abellio-red;
        font-weight: 500;
        margin-bottom: 5px;
      }

      .info {
        @extend .text-muted;
        font-weight: 300;
        text-transform: uppercase;
        @include fontSize(13);
        margin-bottom: 15px;
      }
    }

    .download-btn {
      width: 45px;
      height: 45px;
      border-radius: 50%;
      border: 1px solid $abellio-red;
      margin: 0;
      text-align: center;
      line-height: 50px;
      margin-left: 15px;
      transition: all 0.3s linear;

      svg {
        width: 22px;
        fill: $abellio-red;
      }

      &:hover {
        background-color: $abellio-red;

        svg {
          fill: #fff;
        }
      }
    }
  }
}

.timetable_element.sticky-timetable {
  position: fixed;
  // z-index: 200;
  z-index: 9;
  top: 0;
  left: 0;
  width: 100%;
  height: 0;
  min-height: 690px;
  transform: translate(0, -150%);
  @include transition(0.5);

  .timetable_datetime_wrapper {
    justify-content: flex-start;
  }

  .close_button {
    // filter: invert(100%);
    z-index: 5;
  }

  .navigation_overlay {
    position: fixed;
    z-index: -10;
    top: 0;
    left: 0;
    width: 100%;
    height: 200vh;
    pointer-events: none;
    background: rgba(0, 0, 0, 0.3);
    opacity: 0;
    @include transition(0.3);
  }

  &.is_open {
    transform: translate(0, 0%);

    .navigation_overlay {
      opacity: 1;
      pointer-events: auto;
    }
  }

  //&:before {
  //    content: '';
  //    position: absolute;
  //    z-index: -1;
  //    left: 0;
  //    top: 0;
  //    width: 100%;
  //    height: calc(100% - 145px);
  //    background: $white;
  //}
  //
  //&:after {
  //    content: '';
  //    position: absolute;
  //    z-index: -1;
  //    left: 0;
  //    bottom: -1px;
  //    width: 100%;
  //    height: 145px;
  //    border-top: 146px solid $white;
  //    border-left: 50vw solid transparent;
  //    border-right: 50vw solid transparent;
  //}
}

.close-timetable {
  margin-left: 15px;
  margin-bottom: 25px;
  display: inline-block;
  display: flex;
  font-weight: 800;
  text-transform: uppercase;
  font-size: 14px;

  img {
    transform: rotate(-90deg);
    margin-right: 10px;
  }
}

footer {
  /*background: #6e6e6e;*/
  position: relative;
  z-index: 1;
  @include fontSize(13);

  ul {
    li {
      list-style-type: none;
    }
  }

  .heading {
    font-weight: 300;
    @include fontSize(20);
    margin-bottom: 25px;
    color: white;
  }

  .bg-light-gray {
    background: rgba($abellio-gray, 0.75);
  }

  .logo_color {
    max-width: 50%;
    width: 150px;
  }

  .copyright {
    padding-top: 10px;
    margin-bottom: 0;
  }

  .footer-ul {
    li {
      display: inline-block;
      margin-left: 15px;

      &:first-child {
        margin-left: 0;
      }
    }
  }

  p,
  a,
  div {
    @include fontSize(13);
    color: white;
  }
}

// Alert Style
.error-message {
  background-color: #ce132d;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  color: #fff;
  padding: 20px;

  &-left {
    margin-right: 40px;

    i {
      font-size: 40px;
    }
  }

  &-right {
    width: 100%;
    padding-right: 40px;

    h4 {
      i {
        display: none;
      }
    }
  }

  .close-alert-message {
    position: absolute;
    width: 26px;
    height: 26px;
    top: 0;
    left: auto;
    right: 0;
    transform: translate(-50%, 0) rotate(45deg);
    margin-bottom: 0;
    background: url('../images/plus_icon.svg') no-repeat center center;
    filter: invert(1);
  }

  .container {
    display: flex;
    align-items: center;
    position: relative;
  }
}

@media (max-width: 576px) {
  footer {
    ul {
      margin-top: 0 !important;
    }

    .heading {
      margin-top: 30px;
      margin-bottom: 10px;
    }

    .logo_color {
      max-width: 80%;
    }

    .copyright-container {
      text-align: center;
    }
  }

  .error-message {
    &-left {
      i {
        display: none;
      }
    }

    &-right {
      padding-right: 0;

      h4 {
        display: flex;
        align-items: center;
        padding-right: 40px;
        margin-bottom: 0;

        i {
          font-size: 20px;
          margin-right: 10px;
          display: block;
        }
      }
    }

    .container {
      align-items: flex-start;
      flex-direction: column;
    }
  }
}

span.colored-dot-icon {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  display: inline-block;
  position: relative;

  &:after {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 12px;
    height: 12px;
    border: 3px solid $abellio-red;
    content: '';
    border-radius: 50%;
  }

  &.white {
    background: white;
  }

  &.red {
    background: $abellio-red;

    &:after {
      border-color: white;
    }
  }

  &.filled {
    &:after {
      content: none;
    }
  }
}

.blog-card-holder {
  display: inline;
  margin-bottom: 30px;

  .blog-card {
    // margin-bottom: 15px;
    // margin-top: 15px;
    height: auto;
    // box-shadow: 4px 4px 10px rgba($black, 0.1);
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.08),
      0px 2px 5px rgba(0, 0, 0, 0.07), 0px 1px 2px rgba(0, 0, 0, 0.06);
    transition: 0.3s;
    display: inline-block;
    border-radius: 8px;
    overflow: hidden;
    width: 100%;
    cursor: pointer;

    &:hover {
      box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.16),
        0px 2px 5px rgba(0, 0, 0, 0.07), 0px 1px 2px rgba(0, 0, 0, 0.06);
      transition: 0.3s;

      .text-black {
        color: $abellio-red !important;
      }
    }

    &.large {
      .inner {
        // height: 350px;
        min-height: 350px;
        padding: 0;

        .blog-card-image {
          height: 200px;
          @include bgImageCover;
          background-color: rgba($abellio-gray, 0.6);
        }

        .blog-card-content {
          // position: absolute;
          // right: 0;
          width: 100%;
          height: 100%;
          // bottom: 0;
          // span {
          //    text-transform: uppercase;
          //    margin-bottom: 5px;
          //    display: inline-block;
          //    font-weight: 700;
          //    font-size: .8rem;
          //    transition: .3s;
          // }

          &-top {
            background: rgba($abellio-gray, 0.06);
            padding: 24px;
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
            overflow: hidden;
            min-height: 145px;

            &-map {
              display: flex;
              justify-content: space-between;
              align-content: center;
              padding: 0 0 24px 0;

              .map-title {
                text-transform: capitalize;
                font-weight: 400;
                font-size: 12px;
                line-height: 12px;
                max-height: 23px;
                overflow: hidden;
                max-width: 44%;
                padding-left: 10px;
                color: #000;
              }
            }

            .text-black {
              color: #000;
              height: 3.88rem;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 3;
            }

            h3 {
              color: $black;
            }

            .title {
              color: $black !important;
              // font-size: 1rem;
              font-size: 0.95rem;
            }
          }

          &-bottom {
            padding: 24px;
            border-bottom-left-radius: 8px;
            border-bottom-right-radius: 8px;
            overflow: hidden;

            &-content {
              min-height: 135px;
              max-height: 135px;
              overflow: hidden;
            }

            &-date {
              color: #000;
              font-size: 12px;
            }
          }

          p {
            color: $abellio-gray;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 6;
            line-height: 1.5;
            // font-size: 14px;
            font-size: 16px;
            margin-top: 16px;
            margin-bottom: 0;
          }
        }

        .date {
          position: absolute;
          bottom: 10px;
          left: 10px;
          color: $abellio-red;
          background: rgba(255, 255, 255, 0.9);
          border-top-right-radius: 4px;
          max-width: initial;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 5px 10px;
          transition: 0.3s;

          p {
            margin: 0;
            font-size: 0.8rem;
            text-align: center;
            font-weight: bold;
            font-weight: 300;
          }
        }

        &:before {
          // content: '';
          // position: absolute;
          // left: 0;
          // top: 0;
          // width: 100%;
          // height: 100%;
          // background: linear-gradient(to bottom, rgba(0, 0, 0, 0.95) 0%, rgba(0, 0, 0, 0) 100%);
          // transition: 0.3s;
        }

        &.bgcolor:before {
          background: none;
        }

        // &:hover {
        //    &:before {
        //       height: 70%;
        //       transition: 0.3s;
        //    }

        //    .blog-card-content {
        //       span, .title, p {
        //          color: $abellio-red;
        //          transition: 0.3s;
        //       }
        //    }

        //    .date {
        //       background: rgba(255, 255, 255, 0.6);
        //       transition: 0.3s;
        //    }
        // }
      }
    }

    .inner {
      position: relative;
      padding: 20px;
      // box-shadow: $box-shadow;
      border-radius: 5px;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      overflow: hidden;
    }

    .link-style {
      margin-top: 32px;
      display: inline-block;
    }

    .title {
      position: relative;
      letter-spacing: -0.5px;
      font-size: 1rem;
      line-height: 1.4;
      font-weight: 900;
      margin: 0;
      color: $black;
    }
  }

  &.custom-card {
    .blog-card {
      display: flex;
      // flex-direction: column;
      height: 100%;

      .inner {
        &:before {
          background: none;
        }

        >a {
          margin: 0;
        }

        .blog-card-image {
          background: none;
        }

        .blog-card-content {
          padding: 20px;
          position: relative;
          display: flex;
          flex-direction: column;
          width: 100%;
          height: 100%;

          .title {
            color: unset;
            font-weight: bold;
            height: 5.88rem;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 5;
          }

          p {
            color: initial;
            line-height: 1.5;
            font-size: 14px;
          }

          >span {
            margin-top: auto;
          }

          .date {
            position: unset;
            justify-content: flex-start;
            padding: 0;
          }
        }
      }
    }
  }
}

.news_blog_wrapper {
  .blog-card-holder {
    .blog-card.large {
      .inner {
        padding: 0;
        width: 100%;
      }
    }
  }

  .blog-card {
    .inner {
      padding: 0;

      a {
        padding: 0;
      }
    }
  }

  .blog-card-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 0 !important;

    &-top {
      background: rgba(107, 113, 122, 0.06);
      padding: 24px;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      overflow: hidden;
      width: 100%;
      min-height: 150px;
    }

    &-bottom {
      height: 100%;
      display: flex;
      flex-direction: column;
      min-height: 220px;

      p {
        margin-top: 0 !important;
        -webkit-line-clamp: 5 !important;
      }

      .date {
        justify-content: flex-start;
        padding: 0;
        margin-bottom: 0;
        margin-top: auto;
      }
    }
  }
}

.a-warning {
  padding: 25px;
  box-shadow: $box-shadow;
  background-color: #f9f9f9;
  margin-bottom: 35px;
  border-radius: 5px;
  overflow: hidden;
  position: relative;
  display: flex;

  &:before {
    content: '';
    position: absolute;
    font-family: 'abellio-icons';
    bottom: -5px;
    left: 0;
    width: 100%;
    height: 40px;
    line-height: 35px;
    background: transparent;
    text-align: center;
    cursor: pointer;
    opacity: 0.7;
    transition: 0.3s;
  }

  &:after {
    content: '\e901';
    position: absolute;
    font-family: 'abellio-icons';
    bottom: -5px;
    left: 0;
    width: 100%;
    height: 40px;
    line-height: 35px;
    background: transparent;
    // background: linear-gradient(180deg, rgba(249, 249, 249, 1) 0%, rgba(227, 227, 227, 1) 100%);
    text-align: center;
    cursor: pointer;
    opacity: 0.7;
    transition: 0.3s;
    transform: rotate(180deg);
  }

  .icon {
    font-size: 80px;
  }

  .content {
    max-height: 2000px;
    display: block;
    transition: max-height 1s ease-in-out;
    overflow: hidden;
  }

  &.of {
    position: relative;
    padding-bottom: 35px;

    .content {
      max-height: 50px;
      transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
      // overflow: hidden;
    }

    &:after {
      content: '\e901';
      position: absolute;
      font-family: 'abellio-icons';
      bottom: -5px;
      left: 0;
      width: 100%;
      height: 40px;
      line-height: 35px;
      text-align: center;
      cursor: pointer;
      transition: 0.3s;
      opacity: 1;
      transform: rotate(0);
      z-index: 2;
    }

    &:before {
      content: '';
      position: absolute;
      bottom: -5px;
      left: 0;
      width: 100%;
      height: 40px;
      z-index: 1;
      opacity: 1;
      background: rgb(249, 249, 249);
      background: linear-gradient(180deg,
          rgba(249, 249, 249, 1) 0%,
          rgba(227, 227, 227, 1) 100%);
    }

    &:hover {
      &:after {
        opacity: 1;
      }
    }
  }
}

.object-fit-cover {
  object-fit: cover;
}

////Accordions with tabs
.accordion_item {
  .tabs_section {
    ul {
      // border-bottom: 3px solid $scrill-top-button-color;
      display: flex;
      justify-content: space-between;
      padding: 0;
      margin-top: 50px;
      margin-bottom: 30px;

      li {
        list-style: none;
        position: relative;
        padding: 0 5px;
        width: 100%;

        a {
          width: 100%;
          text-align: center;
          border-top: 1px solid #f9f9f9;
          box-shadow: 0 1px 3px rgba(0, 0, 0, 0.08),
            0 2px 4px rgba(0, 0, 0, 0.03);
          display: block;
          padding: 10px;
          cursor: pointer;
          text-decoration: none;
          transition: 0.3s;
          color: #000;
        }

        &:hover {
          &:after {
            width: 100%;
            transition: 0.3s;
          }

          a {
            transition: 0.3s;
            background: #ededed;
          }
        }
      }

      .active {
        a {
          background: #ededed;
          transition: 0.3s;
        }
      }
    }

    .tabs_navigation {
      margin-top: 30px;
      margin-bottom: 30px;
    }

    .content_tabs_wrap {
      padding: 0 5px;

      .tabs_content {
        padding: 20px 0;
      }
    }

    .tabs_content {
      h2 {
        color: $abellio-dark-grey;
      }
    }
  }
}

.filter_list {
  margin-bottom: 64px;

  ul {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin: 0;
    justify-content: center;

    @include media-breakpoint-down(sm) {
      flex-wrap: nowrap;
      overflow-x: auto;
      justify-content: unset;

      li {
        a {
          white-space: nowrap;
        }

        &:last-child {
          padding-right: 20px;
        }
      }
    }

    li {
      list-style: none;
      margin: 5px;

      a {
        color: $abellio-gray;
        padding: 14px 24px 12px 24px;
        margin-bottom: 0;
        display: inline-block;
        background-color: rgba(107, 113, 122, 0.06);
        border: 1px solid transparent;
        transition: 0.3s;
        min-width: 110px;
        text-align: center;
        border-radius: 44px;
        font-size: 20px;
        line-height: 100%;
      }

      &:hover {
        a {
          background-color: rgba($abellio-gray, 0.06);
          transition: 0.3s;
        }
      }
    }

    .active a {
      color: $abellio-red;
      border-color: $button-background;
    }
  }
}

// Gallery
.gallery-wrapper {
  background-color: $light-grey;
  padding: 100px 0;

  .gallery-holder {
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    .gallery-item-box {
      width: 30%;
      overflow: hidden;
      position: relative;
      display: flex;
      flex-direction: column;
      margin: 20px 20px;
      background: #fff;

      .gallery-box-image {
        height: 380px;
        background-size: cover;
        background-position: top center;
        background-repeat: no-repeat;
        filter: grayscale(1);
        transition: 0.3s;
      }

      .gallery-box-name {
        background-color: #fff;
        position: relative;
        padding: 20px 10px;
        transition: 0.2s;
        z-index: 9;
        width: 100%;
        color: #fff;
        text-align: left;

        h4 {
          margin-bottom: 0px;
          font-weight: 700;
          color: #000;
        }

        h5 {
          color: $carousel-border-color;
          font-weight: 600;
        }

        p {
          color: $abellio-dark-grey;
        }
      }

      &:hover {
        .gallery-box-image {
          filter: grayscale(0);
          transition: 0.3s;
        }

        .gallery-box-position {
          background-color: $light-grey;

          h5 {
            color: $abellio-dark-grey;
          }
        }
      }
    }
  }
}

.popup-gallery-box {
  position: fixed;
  background-color: rgba($black, 0.65);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 99999;

  .popup-holder {
    position: absolute;
    background-color: #fff;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 70%;
    width: 100%;
    min-height: 50vh;
    margin: 0 auto;
    padding: 70px 70px 50px 50px;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.3), 0 0px 22px rgba(0, 0, 0, 0.1);

    .inner-popup {
      display: flex;

      .popup-image {
        max-width: 70%;
        width: 100%;
        min-height: 50vh;
        background-repeat: no-repeat;
        background-size: contain;
        background-color: #fff;
        background-position: top center;

        img {
          width: 100%;
        }
      }

      .popup-content {
        padding-left: 30px;
        max-width: 30%;

        h2 {
          color: $abellio-dark-grey;
        }
      }
    }

    .red-button {
      margin-top: 30px;
      display: inline-block;
    }
  }

  .close_button {
    position: absolute;
    width: 48px;
    height: 48px;
    bottom: 0;
    right: 0;
    top: 20px;
    left: auto;
    transform: translate(-50%, 0) rotate(45deg);
    margin-bottom: 0;
    background: url('../images/plus_icon.svg') no-repeat center center;
  }
}

.red-button {
  background-color: $abellio-red;
  color: #fff;
  padding: 10px 20px;

  &:hover {
    background-color: $abellio-red;
    color: #fff;
  }

  &:visited {
    background-color: $abellio-red;
    color: #fff;
  }
}

.route-notifiers {
  display: flex;
  position: absolute;
  transform: translateY(5px);
  left: 0;
  right: 0;
  margin: auto;
  justify-content: center;
  margin-top: 10px;

  .icon {
    text-align: center;
    // padding: 0px 0 10px 0;
    margin-bottom: 0;
    font-size: 50px;
    font-weight: 300;
  }

  .a-notify {
    height: 100px;
    margin: 0 30px;
    flex: 0 1 auto;

    img {
      animation: pulse 1.5s 2;
      width: 50px;
      height: 50px;

      // &.triangle {
      //    width: 90px;
      //    height: 100px;
      // }
    }

    &>p {
      font-size: 1rem;
      padding: 0 10px;
      line-height: 1;
      text-align: center;
      margin-bottom: 0;
      margin-top: 15px;
    }

    &:visited {
      background-color: #6e6e6e;
      color: #fff;
    }

    &.danger {
      .icon {
        transform: rotate(-180deg);
      }
    }
  }
}

.content_element.employees_carousel_element {
  overflow-x: hidden;
}

.timetable_input_autocomplete_inner {
  max-height: 350px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: -15px;
  left: 0;
  background: #fff;
  box-shadow: 0 13px 30px #0000006b;
  border-radius: 5px;
  width: 100%;

  a {
    padding: 15px;
    border-bottom: 1px solid #eee;
    margin: 0;
  }
}

.timetable_input_autocomplete {
  position: relative;
}

.my-places .popup .inner {
  overflow: visible;
}

.my-places {
  .footer {
    .login-button {
      display: flex;
      justify-content: space-between;
      flex-direction: row !important;
    }
  }

  .timetable_to_wrapper {
    .icons {
      border: 1px solid #e1e1e1;
      padding: 10px 8px;
      border-radius: 2px;
      position: relative;
      margin-bottom: 20px;

      label {
        position: absolute;
        background: #fff;
        top: -10px;
        padding: 0px 6px;
        margin: 0;
        line-height: 1rem;
        font-weight: 300;
        font-size: 0.72222rem;
      }
    }

    .icons-list {
      padding: 0;
      margin: 15px 6px 0px;
      display: flex;

      li {
        list-style: none;
        padding-right: 15px;
        font-size: 1rem;
        line-height: 1rem;

        i {
          font-size: 1.2rem;

          &.active {
            color: $abellio-red;
          }
        }
      }
    }
  }

  .header {
    p {
      margin-bottom: 0;
      font-weight: bold;
    }
  }

  .content {
    padding-top: 5px;

    p {
      font-weight: bold;
    }
  }

  input {
    border: 1px solid #e1e1e1;
  }

  .timetable_to_wrapper {
    position: relative;

    .loading-icon {
      position: absolute;
      top: 20px;
      right: 10px;

      svg {
        stroke: $abellio-red;
        width: 40px;
      }
    }
  }
}

.traffic-information {
  .route-code {
    min-width: 100px;
  }
}

.view-job-wrapper {
  margin-bottom: 50px;

  h1 {
    font-size: 2rem;
    line-height: 2.5rem;
  }
}

.bg-transparent {
  background-color: transparent;
}

.incidents-list {
  .an-info {
    .right-actions {
      img {
        transform: rotate(90deg);
        transition: 0.4s;
      }
    }

    &.opened {
      .right-actions {
        img {
          transform: rotate(-90deg);
          transition: 0.4s;
        }
      }
    }

    &.an-info-top {
      display: flex;
      align-items: flex-start;

      >.inner {
        max-width: 40%;
        width: 100%;
        height: auto;
      }

      .content {
        max-width: 60%;
        width: 100%;

        .inner {
          max-width: 100%;
          padding-left: 0;
          background: #fff;
        }
      }

      .route-code {
        width: 120px;
        padding-right: 10px;
      }
    }
  }

  .show_more_btn {
    float: right;
    margin-top: 20px;
  }
}

.main_form_faq {
  .accordion_wrapper {
    .accordion_item {
      box-shadow: 0 0px 5px rgba(0, 0, 0, 0.3), 0 2px 4px rgba(0, 0, 0, 0.03);

      .accordion_title {
        color: $black;
      }
    }
  }
}

.breadcrumb_section {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;

  ul {
    display: flex;
    align-items: center;
    padding: 0;
    margin: 0;

    li {
      list-style: none;
      margin-right: 30px;
      transition: 0.3s;
      position: relative;

      a {
        font-size: 0.7rem;
        font-size: 14px;
        padding-right: 10px;
      }

      &:hover {
        color: $abellio-red;
        transition: 0.3s;
      }

      &:after {
        content: '';
        position: absolute;
        right: -18px;
        top: 50%;
        width: 15px;
        height: 15px;
        transform: translate(0, -50%) rotate(0deg);
        background: url('../images/arrow_left_red.svg') no-repeat center center;
        background-size: cover;
      }

      &.active {
        color: $abellio-red;
        font-size: 14px;
        font-weight: 700;
      }

      &:last-child {
        &:after {
          display: none;
        }
      }
    }
  }

  .zuruck_button {
    position: relative;
    margin-bottom: 0;
    display: flex;
    align-items: center;

    &:before {
      position: absolute;
      content: '';
      background: url('../images/select_arrow.svg') no-repeat center center;
      width: 10px;
      height: 12px;
      left: -15px;
      margin-top: 2px;
      transform: rotate(-90deg);
      transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    }

    &:hover {
      &:before {
        transform: rotate(-90deg) translateY(-5px);
        transition: 0.3s;
      }
    }
  }
}

.acc_faq {
  .main_form_faq {
    &.jobs_navigator_container {
      .content_element {
        &.accordion_element {
          .container {
            padding-right: 0;
            padding-left: 0;
          }
        }
      }
    }
  }
}

.slide-enter-active {
  transition-duration: 1s;
  transition-timing-function: ease-in;
}

.slide-leave-active {
  transition-duration: 1s;
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  // transition-timing-function: ease-in;
}

.slide-enter-to,
.slide-leave {
  max-height: 2000px;
  overflow: hidden;
}

.slide-enter,
.slide-leave-to {
  overflow: hidden;
  max-height: 0;
}

.backend-edit {
  position: fixed;
  background: #ce132d;
  bottom: 20px;
  left: 20px;
  padding: 10px 25px;
  z-index: 100;
  font-weight: bold;
  color: #fff;
}

.unread-notification {
  background-color: #ff0202;
  box-shadow: 0 0 10px $abellio-red;
  width: 9px;
  height: 9px;
  border-radius: 50%;
  position: absolute;
  right: 5px;
  top: 6px;
}

.copyright_sign {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 15px 20px;
  color: #fff;
  margin-bottom: 0;
  background: linear-gradient(90deg, rgba(0, 0, 0, 0.65) 75%, transparent);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#a6000000", endColorstr="#00000000", GradientType=1);

  p {
    margin-bottom: 0;
  }
}

.image_copyright {
  position: relative;
}

.special-icons {
  .icon_acc_wrap {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    .icon-acc {
      width: 50px;
      margin-right: 20px;
      text-align: center;

      i {
        margin-right: 0;
      }
    }

    .icon-acc i {
      @include fontSize(32);
    }

    .icon-acc-text {
      font-size: 1rem;
    }
  }
}

#applicationStatusChangePassword {
  >button {
    margin-bottom: 20px;
  }
}

.text_size_large {
  .abellio_info_box {
    .abellio_info_box_more {
      position: relative;
      bottom: 10px;
      right: 10px;

      padding-right: 5px;
      font-size: 1rem;
      line-height: 1.16667rem;
      margin-bottom: 0;

      &:after {
        width: 15px;
        height: 15px;
        right: -13px;
      }
    }
  }

  .abellio_info_box_content {
    overflow-y: auto;
    height: 230px;

    // padding: 12px 0px 12px 12px !important;
    &::-webkit-scrollbar {
      width: 10px;
    }

    &::-webkit-scrollbar-track {
      background: #fff;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #e8e8e8;
      border-radius: 20px;
      border: 2px solid #fff;
    }

    .abellio_info_box_more {
      padding-left: 12px;
    }

    p {
      padding-left: 12px;
    }
  }
}

.babasClass {
  &.is_open {
    min-height: unset;
    height: 100%;
  }

  .timetable_wrapper {
    margin: 0;
    position: absolute;
    width: 100%;
    height: calc(100% - 150px);
  }

  .timetable_inner_wrapper {
    position: absolute;
    width: 100%;
    overflow: hidden;
    overflow-y: auto;
    border-radius: 0;
    box-shadow: none;
    padding: 110px 50px 120px 54px;
  }

  .route-notifiers {
    position: relative;
    left: unset;
    bottom: unset;
    top: unset;
    right: unset;
  }

  .timetable_time {
    //font-weight: 700;
    //text-decoration: underline;
  }

  .close_button {
    bottom: -140px;
    color: #6b717a;
  }

  .timetable_wrapper .timetable_controls_wrapper {
    justify-content: center;
    // border: 1px solid white;
  }

  .timetable_wrapper .timetable_inner_wrapper {
    background-color: #fff;
  }

  .mt10cursor-pointer {
    color: $abellio-gray;
  }

  .timetable_my_locations_title {
    color: $abellio-gray;
  }

  .timetable_title {
    color: $abellio-gray;
  }

  .timetable_wrapper {
    .timetable_controls_wrapper {
      margin-top: 15px;

      // border: 1px solid $white;
      label {
        color: $abellio-gray;
        margin-right: 36px;
      }

      .timetable_my_locations_title {
        color: $abellio-gray;
      }

      .timetable_datetime_wrapper {
        background: $white;
        padding: 10px 19px 10px 10px;
        border: 1px solid $abellio-gray;
        justify-content: flex-start;
      }

      .timetable_inputs_wrapper {
        .timetable_input_wrapper {
          label {
            color: $abellio-gray;
          }
        }
      }
    }

    &:after {
      border-top: 146px solid #fff;
    }
  }

  .timetable_my_locations_item {
    border: 1px solid $abellio-gray;
    color: $abellio-gray !important;
    width: 30px;
    height: 30px;
  }

  .navigation_overlay {
    background-color: $abellio-gray !important;
  }

  .timetable_from_wrapper,
  .some-class {
    display: flex;
    align-items: center;
  }

  .monitor {
    display: flex;
    justify-content: flex-end;
    margin-top: 0;
  }

  @media all and (max-device-width: 800px) and (orientation: landscape) {
    .timetable_wrapper {
      height: calc(100% - 120px);

      &:after {
        height: 70px;
      }
    }
  }
}

.presse-btn {
  padding: 0 10px;
  font-size: 16px;
  font-weight: 500;
  line-height: 1;
  margin-right: 15px;
  background: transparent;
  color: $abellio-gray !important;
}

@import './parts/search';
@import './parts/jobSingle';
@import './parts/trafficInformation';
@import './parts/popup';
@import './parts/tabs';
@import './font-size-larger';

// New Design
.carousel_content_element {
  .swiper-slide {
    display: flex;
  }

  .swiper-pagination-bullets {
    display: flex;
    align-content: center;
    justify-content: center;

    .swiper-pagination-bullet {
      width: 12px !important;
      height: 12px !important;
      background: rgba($abellio-gray, 0.1) !important;

      &.swiper-pagination-bullet-active {
        width: 16px !important;
        height: 16px !important;
        background-color: $abellio-gray !important;
      }
    }
  }

  .swiper-container {
    padding-bottom: 0;
    padding: 5px;

    // overflow: visible;
    .swiper-wrapper {
      padding-bottom: 64px;
    }
  }

  // .container {
  //    padding-bottom: 100px;
  // }
  .swiper-button-next {
    left: auto;
    right: -60px;

    @include media-breakpoint-down(lg) {
      right: -30px;
    }

    @include media-breakpoint-down(md) {
      right: 0;
    }
  }

  .swiper-button-prev {
    right: auto;
    left: -60px;

    @include media-breakpoint-down(lg) {
      left: -30px;
    }

    @include media-breakpoint-down(md) {
      left: 0;
    }
  }

  .swiper-button-prev,
  .swiper-button-next {
    top: 50%;
    width: 12px;
    height: 12px;

    @include media-breakpoint-down(md) {
      display: none;
    }
  }

  .swiper-button-prev::after,
  .swiper-button-next::after {
    font-size: 20px;
    color: $abellio-gray;
  }

  .swiper-button-prev.swiper-button-disabled,
  .swiper-button-next.swiper-button-disabled {
    opacity: 0;
  }
}

.abellio_info_box {
  background: #ffffff;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.08), 0px 2px 5px rgba(0, 0, 0, 0.07),
    0px 1px 2px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  padding-bottom: 24px;
  display: flex;
  flex-direction: column;

  &_image {
    height: 200px;
    background-size: cover;
    background-position: center;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
  }

  &_content {
    padding: 24px;

    p {
      font-size: 16px;
      line-height: 1.5;
      color: #6b717a;
    }
  }

  &_title {
    height: 2.88rem;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }

  &_more {
    font-size: 14px;
    font-weight: 700;
    color: $abellio-red;
    text-align: right;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 0;
    margin-top: auto;
    text-decoration: underline;
    margin-left: 24px;
    margin-right: 24px;
  }
}

.tab-item {
  padding: 14px 24px 12px 24px;
  margin-bottom: 0;
  display: inline-block;
  // background-color: rgba($abellio-red, 0.15);
  color: $button-background;
  border: 1px solid $button-background;
  transition: 0.3s;
  text-align: center;
  border-radius: 44px;
  font-size: 20px;
  line-height: 100%;
  text-transform: capitalize;

  &.sm {
    font-size: 12px !important;
    text-transform: capitalize !important;
    line-height: 12px;
    padding: 6px 12px 4px 12px;
    font-weight: 400px;
  }
}

.link-style {
  width: auto;
  max-width: 100%;
  margin: 0;
  box-shadow: none;
  padding: 0;
  color: $abellio-red;
  font-weight: 700;
  font-size: 16px;
  text-decoration: underline;
}

.load-more {
  color: $abellio-gray;
  border: 2px solid $abellio-gray;
  background-color: $white;

  &:hover {
    color: $abellio-gray;
    border: 2px solid $abellio-gray;
    background-color: $white;
  }
}

.aktionen-section {
  .blog-card-holder {
    display: flex;
    margin-bottom: 30px;
    flex-wrap: wrap;
    // justify-content: space-between;

    .blog-card {
      box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.08),
        0px 2px 5px rgba(0, 0, 0, 0.07), 0px 1px 2px rgba(0, 0, 0, 0.06);
      max-width: 31%;
      margin-bottom: 28px;
      border-radius: 8px;
      overflow: hidden;
      margin: 0 16px 25px 16px;

      &:hover {
        box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.16),
          0px 2px 5px rgba(0, 0, 0, 0.07), 0px 1px 2px rgba(0, 0, 0, 0.06);

        .title {
          color: $abellio-red;
        }
      }

      @media (max-width: 824px) {
        max-width: 49%;
      }

      @media (max-width: 560px) {
        max-width: 100%;
        margin: 0 0 25px 0;
      }

      .inner {
        padding: 0;
        height: auto;
        display: inline-block;
        width: 100%;
        box-shadow: none;
      }

      &:nth-child(3n) {
        margin-right: 0;
      }

      &-content {
        &-bottom {
          h3 {
            margin-top: 24px;
            color: $black;
            height: 2.88rem;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
          }
        }
      }
    }
  }

  .container {
    @media (max-width: 824px) {
      padding: 0;
    }

    .row {
      @media (max-width: 824px) {
        margin: 0;
      }

      .col-sm-12 {
        @media (max-width: 824px) {
          padding: 0;
        }
      }
    }
  }
}

.jobs-section {
  .blog-card {
    cursor: text;
  }
}

.two-pic-layout {
  margin-bottom: 100px;

  figure {
    // height: 221px;
    margin: 0;
    position: relative;
    max-height: 221px;
    overflow: hidden;
    border-radius: 8px;

    figcaption {
      position: absolute;
      bottom: 24px;
      left: 28px;
      background: #fff;
      padding: 8px 8px 4px 8px;
      font-size: 18px;
      line-height: 19.8px;
      color: $abellio-gray;
      font-weight: 700;
    }

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
}

.content-back-btn-wrapper {
  width: 100%;
  display: block;
  text-align: center;

  a {
    color: $black;
    font-size: 14px;
    font-weight: 700;
    line-height: 130%;
    position: relative;
    margin-bottom: 0;
    display: inline-block;
    vertical-align: middle;
    align-items: center;

    &:before {
      position: absolute;
      content: '';
      background: url('../images/select_arrow_red.svg') no-repeat center center;
      width: 10px;
      height: 12px;
      left: -15px;
      margin-top: 2px;
      transform: rotate(-90deg);
      transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    }

    &:hover {
      &:before {
        transform: rotate(-90deg) translateY(-5px);
        transition: 0.3s;
      }
    }
  }
}

.image-content {
  margin-bottom: 80px;

  @include media-breakpoint-down(sm) {
    margin-bottom: 40px;
  }

  &-img {
    img {
      border-radius: 12px;
    }
  }

  &-content {
    padding-top: 20px;
    padding-bottom: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .link-style {
    display: flex;
    align-items: center;

    &:before {
      content: '';
      background-image: url('../../assets/images/right-arrow.svg');
      width: 20px;
      height: 20px;
      display: inline-block;
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      margin-right: 12px;
    }
  }
}

.timetable_inline .timetable_wrapper .timetable_controls_wrapper .timetable_inputs_wrapper .timetable_input_wrapper .timetable_input_autocomplete_wrapper .timetable_input_autocomplete {
  z-index: 1;
  position: relative;
}

.blog-card-content-bottom-date {
  //   margin-bottom: 16px;
  display: inline-block;
}

.blog-card-content-bottom-content p:first-child {
  margin-top: 0 !important;
}

.blog-card-content-bottom-content {
  color: #6b717a;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 6;
  line-height: 1.5;
  font-size: 16px;
  padding-top: 16px;
  margin-bottom: 0;
}


// Popup Pdf
.popup-pdf {
  position: fixed;
  top: 0;
  z-index: 999999999;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  &-in {
    background-color: $abellio-red;
    border: 26px solid $white;
    width: 500px;
    color: $white;
    position: relative;
    padding: 60px 20px 20px 20px;
    text-align: center;

    .header-popup {
      a {
        position: absolute;
        width: 48px;
        height: 48px;
        top: -20px;
        right: -20px;
        margin-bottom: 0;
        background-color: $abellio-red;
        transform: unset;
        left: auto;
        border-radius: 50%;
        border: 8px solid;
        display: flex;
        justify-content: center;
        align-items: center;
        // span {
        //   background: url(../../assets/images/plus_icon_white.svg) no-repeat center center ;
        //   width: 16px;
        //   height: 16px;
        //   position: unset;
        //   transform: rotate(45deg);
        //   display: flex;
        // }
      }
    }

    .content-popup {
      h2 {
        margin-bottom: 40px;
      }

      h4 {
        font-weight: normal;
      }
    }

    .footer-popup {
      margin-top: 50px;

      a {
        display: inline-block;
        padding: 15px 20px 15px 20px;
        border-radius: 30px;
        background: $white;
        color: $abellio-red;
        font-size: 1rem;
        line-height: 1.11111rem;
        font-weight: bold;
        cursor: pointer;
        border: 0 none;
        width: auto;
        margin: 0;
        font-family: 'Frutiger';
        font-weight: bold;

      }
    }
  }
}

.popup-image {
  position: fixed;
  top: 0;
  z-index: 999999999;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  &-in {
    // border: 26px solid #fff;
    width: 500px;
    position: relative;
    display: flex;

    .content-btn {
      position: absolute;
      bottom: 70px;
      left: 87px;
      height: 67px;
      width: 326px;
      margin-bottom: 0;
    }

    .header-popup {
      a {
        position: absolute;
        width: 48px;
        height: 48px;
        top: 8px;
        right: 6px;
        // border: 1px solid red;
        // margin-bottom: 0;
        // background-color: $abellio-red;
        // transform: unset;
        // left: auto;
        // border-radius: 50%;
        // border: 8px solid #fff;
        // display: flex;
        // justify-content: center;
        // align-items: center;
        // span {
        //   background: url(../../assets/images/plus_icon_white.svg) no-repeat center center ;
        //   width: 16px;
        //   height: 16px;
        //   position: unset;
        //   transform: rotate(45deg);
        //   display: flex;
        // }
      }
    }
  }
}

.job-form-builder {

  .content_element.form_builder_element,
  .content_element.content_blocks_element {
    .container {
      padding-left: 0;
      padding-right: 0;
    }
  }
}
