.view-job-wrapper {
   .content {
      ul {
         li {
            position: relative;
            list-style-type: none;
            margin-bottom: 10px;

            &:before {
               content: "";
               position: absolute;
               left: -20px;
               top: 6px;
               margin: auto;
               width: 10px;
               height: 10px;
               border: 2px solid $abellio-red;
               border-radius: 50%;
            }
         }
      }
   }

   .contact-info {
      position: sticky;
      top: 110px;

      .inner {
         background-color: #f9f9f9;
         padding: 15px;
         margin-top: -10px;

         a {
            display: block;
         }
      }

      .apply-btn {
         border: 2px solid $abellio-red;
         background-color: transparent;
         color: $body-color !important;
         text-transform: uppercase;
         display: block;
         margin-top: 15px;
         padding: 15px;
         text-align: center;
         transition: all 0.3s linear;

         &:hover {
            background-color: $abellio-red;
            color: #fff !important;
         }
      }

      .details {
         background-color: #f3f3f3;
         margin: -15px;
         margin-top: 0;
         padding: 15px;
         position: relative;

         a {
            margin-bottom: 5px;
            font-weight: 600;
            letter-spacing: -.4px;

            &:last-child {
               margin-bottom: 0;
            }
         }

         .icon {
            position: absolute;
            right: 20px;
            top: 0;
            bottom: 0;
            margin: auto;
            height: 65px;

            svg {
               width: 65px;
               height: 65px;
            }
         }
      }
   }

   .title {
      margin-bottom: 5px;
      color: $abellio-red;
   }

   .type-title {
      margin-bottom: 25px;
      text-transform: uppercase;
      @include fontSize(13)
   }

   .full_image_element {
      .big_image {
         height: 100%;
         object-fit: cover;
      }
   }
}

.accordion_wrapper {
   .accordion_content {
      ul {
         li {
            position: relative;
            list-style-type: none;
            margin-bottom: 10px;

            &:before {
               content: "";
               position: absolute;
               left: -20px;
               top: 6px;
               margin: auto;
               width: 10px;
               height: 10px;
               border: 2px solid $abellio-red;
               border-radius: 50%;
            }
         }
      }
   }

   .accordion_item {
      .accordion_title {
         color: #000000;
      }
   }
}
