.popup {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.1);
  z-index: 101;
  display: flex;
  align-items: center;
  justify-content: center;

  .header, .content, .footer {
    padding: 15px;
  }

  .header {
    position: relative;
  }

  .footer {
    background-color: #f9f9f9;
  }

  .inner {
    background-color: #fff;
    border-radius: 5px;
    width: 550px;
    min-height: 250px;
    box-shadow: 0 3px 15px rgba(0, 0, 0, 0.05);
    overflow: hidden;
  }

  .content {
    //min-height: 140px;
  }

  .close {
    position: absolute;
    width: 48px;
    height: 48px;
    right: -20px;
    top: 0;
    transform: translate(-50%, 0) rotate(45deg);
    margin-bottom: 0;
    background: url(../../../assets/images/plus_icon.svg) no-repeat center center;
  }
}

.my-places {
  .timetable_my_locations {
    .timetable_my_locations_item {
      background: $abellio-red;

      &.my_location_home, .my_location_work {
        i {
          color: #FFF;
        }
      }
    }
  }
}

.popup.dramatic-event-popup{
   z-index: 1002;
   .inner{
      position: relative;
      width: 80%;
      height: 80%;
      background-color: rgba(0,0,0,0.7);
      color:$white;
      display: flex;
      align-items: center;
   }
   .header{
      position: unset;
      flex: 1 0 30%;
      text-align: center;
   }
   .content{
      flex: 1 0 70%;
   }
   .icon{
      font-size: 250px;
   }
   .footer{
      background-color: transparent;
   }
   .close{
      width: 90px;
      height: 90px;
      background-size: 60px;
      background-color: #ddd;
      border-radius: 50%;
      transition: all .3s linear;
      bottom: 20px;
      top: auto;
      left: 0;
      right: 0;
      margin: auto;
      transform: translate(0) rotate(
            45deg
      );
      &:hover{
         background-color: #fff;
         filter: invert(1);

      }
   }
}
