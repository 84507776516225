// @font-face {
//    font-family: 'abellio-icons';
//    src: url('../fonts/icons/abellio-icons.eot?qyhwe4');
//    src: url('../fonts/icons/abellio-icons.eot?qyhwe4#iefix') format('embedded-opentype'),
//    url('../fonts/icons/abellio-icons.ttf?qyhwe4') format('truetype'),
//    url('../fonts/icons/abellio-icons.woff?qyhwe4') format('woff'),
//    url('../fonts/icons/abellio-icons.svg?qyhwe4#abellio-icons') format('svg');
//    font-weight: normal;
//    font-style: normal;
//    font-display: block;
// }

// [class^="ab-icon-"], [class*=" ab-icon-"] {
//    /* use !important to prevent issues with browser extensions that change fonts */
//    font-family: 'abellio-icons';
//    speak: never;
//    font-style: normal;
//    font-weight: normal;
//    font-variant: normal;
//    text-transform: none;
//    line-height: 1;

//    /* Better Font Rendering =========== */
//    -webkit-font-smoothing: antialiased;
//    -moz-osx-font-smoothing: grayscale;
// }

// .ab-icon-no-bike:before {
//    content: "\e940";
//    color: rgb(4, 6, 6);
// }
// .ab-icon-no-bike:after {
//    content: "\e941";
//    margin-left: -0.9990234375em;
//    color: rgb(238, 36, 50) !important;
// }
// .ab-icon-bus:before {
//    content: "\e942";
// }

// .ab-icon-wheelchair:before {
//    content: "\e943";
// }

// .ab-icon-pin-headquarters .path1:before {
//    content: "\e933";
//    color: rgb(206, 19, 45);
// }
// .ab-icon-pin-headquarters .path2:before {
//    content: "\e934";
//    margin-left: -0.9228515625em;
//    color: rgb(255, 255, 255);
// }
// .ab-icon-pin-headquarters .path3:before {
//    content: "\e935";
//    margin-left: -0.9228515625em;
//    color: rgb(255, 255, 255);
// }
// .ab-icon-pin-headquarters .path4:before {
//    content: "\e936";
//    margin-left: -0.9228515625em;
//    color: rgb(255, 255, 255);
// }
// .ab-icon-pin-headquarters .path5:before {
//    content: "\e937";
//    margin-left: -0.9228515625em;
//    color: rgb(255, 255, 255);
// }
// .ab-icon-pin-headquarters .path6:before {
//    content: "\e938";
//    margin-left: -0.9228515625em;
//    color: rgb(255, 255, 255);
// }
// .ab-icon-pin-headquarters .path7:before {
//    content: "\e939";
//    margin-left: -0.9228515625em;
//    color: rgb(255, 255, 255);
// }
// .ab-icon-pin-headquarters .path8:before {
//    content: "\e93a";
//    margin-left: -0.9228515625em;
//    color: rgb(255, 255, 255);
// }
// .ab-icon-pin-headquarters .path9:before {
//    content: "\e93b";
//    margin-left: -0.9228515625em;
//    color: rgb(255, 255, 255);
// }
// .ab-icon-pin-headquarters .path10:before {
//    content: "\e93c";
//    margin-left: -0.9228515625em;
//    color: rgb(255, 255, 255);
// }
// .ab-icon-pin-headquarters .path11:before {
//    content: "\e93d";
//    margin-left: -0.9228515625em;
//    color: rgb(255, 255, 255);
// }
// .ab-icon-pin-headquarters .path12:before {
//    content: "\e93e";
//    margin-left: -0.9228515625em;
//    color: rgb(255, 255, 255);
// }
// .ab-icon-pin-headquarters .path13:before {
//    content: "\e93f";
//    margin-left: -0.9228515625em;
//    color: rgb(255, 255, 255);
// }

// .ab-icon-stars:before {
//    content: "\e92c";
// }

// .ab-icon-messages:before {
//    content: "\e92d";
// }

// .ab-icon-Kontakt:before {
//    content: "\e92e";
// }

// .ab-icon-press:before {
//    content: "\e92f";
// }

// .ab-icon-favourites:before {
//    content: "\e930";
// }

// .ab-icon-likes:before {
//    content: "\e931";
// }

// .ab-icon-terms-conditions:before {
//    content: "\e932";
// }

// .ab-icon-application-status:before {
//    content: "\e900";
// }

// .ab-icon-arrow-down:before {
//    content: "\e901";
// }

// .ab-icon-arrow-left:before {
//    content: "\e902";
// }

// .ab-icon-arrow-right:before {
//    content: "\e903";
// }

// .ab-icon-arrow-up:before {
//    content: "\e904";
// }

// .ab-icon-calendar:before {
//    content: "\e905";
// }

// .ab-icon-chat:before {
//    content: "\e906";
// }

// .ab-icon-contact:before {
//    content: "\e907";
// }

// .ab-icon-contact-help:before {
//    content: "\e908";
// }

// .ab-icon-contact-hq:before {
//    content: "\e909";
// }

// .ab-icon-events:before {
//    content: "\e90a";
// }

// .ab-icon-faq:before {
//    content: "\e90b";
// }

// .ab-icon-headquarters:before {
//    content: "\e90c";
// }

// .ab-icon-home:before {
//    content: "\e90d";
// }

// .ab-icon-hour:before {
//    content: "\e90e";
// }

.ab-icon-job:before {
  content: '\e90f';
  color: #6b717a;
}

// .ab-icon-job-finder:before {
//   content: '\e910';
// }

// .ab-icon-logo .path1:before {
//    content: "\e911";
//    color: rgb(74, 74, 73);
// }

// .ab-icon-logo .path2:before {
//    content: "\e912";
//    margin-left: -1em;
//    color: rgb(74, 74, 73);
// }

// .ab-icon-logo .path3:before {
//    content: "\e913";
//    margin-left: -1em;
//    color: rgb(74, 74, 73);
// }

// .ab-icon-logo .path4:before {
//    content: "\e914";
//    margin-left: -1em;
//    color: rgb(74, 74, 73);
// }

// .ab-icon-logo .path5:before {
//    content: "\e915";
//    margin-left: -1em;
//    color: rgb(74, 74, 73);
// }

// .ab-icon-logo .path6:before {
//    content: "\e916";
//    margin-left: -1em;
//    color: rgb(206, 19, 45);
// }

// .ab-icon-logo .path7:before {
//    content: "\e917";
//    margin-left: -1em;
//    color: rgb(206, 19, 45);
// }

// .ab-icon-logo .path8:before {
//    content: "\e918";
//    margin-left: -1em;
// }

// .ab-icon-map:before {
//    content: "\e919";
// }

// .ab-icon-marker:before {
//    content: "\e91a";
// }

// .ab-icon-marker-o:before {
//    content: "\e91b";
// }

// .ab-icon-plus:before {
//    content: "\e91c";
// }

// .ab-icon-relations:before {
//    content: "\e91d";
// }

// .ab-icon-sales-point:before {
//    content: "\e91e";
// }

// .ab-icon-search:before {
//    content: "\e91f";
// }

// .ab-icon-timetable:before {
//    content: "\e920";
// }

// .ab-icon-train:before {
//    content: "\e921";
// }

// .ab-icon-transport-line:before {
//    content: "\e922";
// }

// .ab-icon-user-account:before {
//    content: "\e923";
// }

// .ab-icon-x:before {
//    content: "\e924";
// }

// .ab-icon-lock:before {
//    content: "\e925";
// }

// .ab-icon-calculator:before {
//    content: "\e926";
// }

// .ab-icon-edit:before {
//    content: "\e927";
// }

// .ab-icon-share:before {
//    content: "\e928";
// }

// .ab-icon-mail:before {
//    content: "\e929";
// }

// .ab-icon-warning:before {
//    content: "\e92a";
// }

// .ab-icon-logout:before {
//    content: "\e92b";
// }

@font-face {
  font-family: 'abellio-icons';
  src: url('../fonts/icons/abellio-icons.eot?cg4xgl');
  src: url('../fonts/icons/abellio-icons.eot?cg4xgl#iefix')
      format('embedded-opentype'),
    url('../fonts/icons/abellio-icons.ttf?cg4xgl') format('truetype'),
    url('../fonts/icons/abellio-icons.woff?cg4xgl') format('woff'),
    url('../fonts/icons/abellio-icons.svg?cg4xgl#abellio-icons') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='ab-icon-'],
[class*=' ab-icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'abellio-icons' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ab-icon-menu:before {
  content: '\e949';
  color: #6b717a;
}
.ab-icon-arrows:before {
  content: '\e944';
}
.ab-icon-email:before {
  content: '\e945';
  color: #6b717a;
}
.ab-icon-location:before {
  content: '\e946';
  color: #6b717a;
}
.ab-icon-map:before {
  content: '\e947';
  color: #6b717a;
}
.ab-icon-time:before {
  content: '\e948';
}
.ab-icon-no-bike .path1:before {
  content: '\e940';
  color: rgb(4, 6, 6);
}
.ab-icon-no-bike .path2:before {
  content: '\e941';
  margin-left: -0.9990234375em;
  color: rgb(238, 36, 50);
}
.ab-icon-bus:before {
  content: '\e942';
}
.ab-icon-wheelchair:before {
  content: '\e943';
}
.ab-icon-pin-headquarters .path1:before {
  content: '\e933';
  color: rgb(206, 19, 45);
}
.ab-icon-pin-headquarters .path2:before {
  content: '\e934';
  margin-left: -0.9228515625em;
  color: rgb(255, 255, 255);
}
.ab-icon-pin-headquarters .path3:before {
  content: '\e935';
  margin-left: -0.9228515625em;
  color: rgb(255, 255, 255);
}
.ab-icon-pin-headquarters .path4:before {
  content: '\e936';
  margin-left: -0.9228515625em;
  color: rgb(255, 255, 255);
}
.ab-icon-pin-headquarters .path5:before {
  content: '\e937';
  margin-left: -0.9228515625em;
  color: rgb(255, 255, 255);
}
.ab-icon-pin-headquarters .path6:before {
  content: '\e938';
  margin-left: -0.9228515625em;
  color: rgb(255, 255, 255);
}
.ab-icon-pin-headquarters .path7:before {
  content: '\e939';
  margin-left: -0.9228515625em;
  color: rgb(255, 255, 255);
}
.ab-icon-pin-headquarters .path8:before {
  content: '\e93a';
  margin-left: -0.9228515625em;
  color: rgb(255, 255, 255);
}
.ab-icon-pin-headquarters .path9:before {
  content: '\e93b';
  margin-left: -0.9228515625em;
  color: rgb(255, 255, 255);
}
.ab-icon-pin-headquarters .path10:before {
  content: '\e93c';
  margin-left: -0.9228515625em;
  color: rgb(255, 255, 255);
}
.ab-icon-pin-headquarters .path11:before {
  content: '\e93d';
  margin-left: -0.9228515625em;
  color: rgb(255, 255, 255);
}
.ab-icon-pin-headquarters .path12:before {
  content: '\e93e';
  margin-left: -0.9228515625em;
  color: rgb(255, 255, 255);
}
.ab-icon-pin-headquarters .path13:before {
  content: '\e93f';
  margin-left: -0.9228515625em;
  color: rgb(255, 255, 255);
}
.ab-icon-stars:before {
  content: '\e92c';
}
.ab-icon-messages:before {
  content: '\e92d';
}
.ab-icon-Kontakt:before {
  content: '\e92e';
}
.ab-icon-press:before {
  content: '\e92f';
}
.ab-icon-favourites:before {
  content: '\e930';
}
.ab-icon-likes:before {
  content: '\e931';
}
.ab-icon-terms-conditions:before {
  content: '\e932';
}
.ab-icon-warning:before {
  content: '\e92a';
}
.ab-icon-logout:before {
  content: '\e92b';
}
.ab-icon-mail:before {
  content: '\e929';
}
.ab-icon-calculator:before {
  content: '\e926';
}
.ab-icon-edit:before {
  content: '\e927';
}
.ab-icon-share:before {
  content: '\e928';
}
.ab-icon-lock:before {
  content: '\e925';
}
.ab-icon-train:before {
  content: '\e921';
}
.ab-icon-application-status:before {
  content: '\e900';
}
.ab-icon-arrow-down:before {
  content: '\e901';
}
.ab-icon-arrow-left:before {
  content: '\e902';
}
.ab-icon-arrow-right:before {
  content: '\e903';
}
.ab-icon-arrow-up:before {
  content: '\e904';
}
.ab-icon-calendar:before {
  content: '\e905';
}
.ab-icon-chat:before {
  content: '\e906';
}
.ab-icon-contact:before {
  content: '\e907';
}
.ab-icon-contact-help:before {
  content: '\e908';
}
.ab-icon-contact-hq:before {
  content: '\e909';
}
.ab-icon-events:before {
  content: '\e90a';
}
.ab-icon-faq:before {
  content: '\e90b';
}
.ab-icon-headquarters:before {
  content: '\e90c';
}
.ab-icon-home:before {
  content: '\e90d';
}
.ab-icon-hour:before {
  content: '\e90e';
}
.ab-icon-job:before {
  content: '\e90f';
}
.ab-icon-job-finder:before {
  content: '\e910';
}
.ab-icon-logo .path1:before {
  content: '\e911';
  color: rgb(74, 74, 73);
}
.ab-icon-logo .path2:before {
  content: '\e912';
  margin-left: -1em;
  color: rgb(74, 74, 73);
}
.ab-icon-logo .path3:before {
  content: '\e913';
  margin-left: -1em;
  color: rgb(74, 74, 73);
}
.ab-icon-logo .path4:before {
  content: '\e914';
  margin-left: -1em;
  color: rgb(74, 74, 73);
}
.ab-icon-logo .path5:before {
  content: '\e915';
  margin-left: -1em;
  color: rgb(74, 74, 73);
}
.ab-icon-logo .path6:before {
  content: '\e916';
  margin-left: -1em;
  color: rgb(206, 19, 45);
}
.ab-icon-logo .path7:before {
  content: '\e917';
  margin-left: -1em;
  color: rgb(206, 19, 45);
}
.ab-icon-logo .path8:before {
  content: '\e918';
  margin-left: -1em;
  color: rgb(74, 74, 73);
}
.ab-icon-map1:before {
  content: '\e919';
}
.ab-icon-marker:before {
  content: '\e91a';
}
.ab-icon-marker-o:before {
  content: '\e91b';
}
.ab-icon-plus:before {
  content: '\e91c';
}
.ab-icon-relations:before {
  content: '\e91d';
}
.ab-icon-sales-point:before {
  content: '\e91e';
}
.ab-icon-search:before {
  content: '\e91f';
}
.ab-icon-timetable:before {
  content: '\e920';
}
.ab-icon-transport-line:before {
  content: '\e922';
}
.ab-icon-user-account:before {
  content: '\e923';
}
.ab-icon-x:before {
  content: '\e924';
}
