@include media-breakpoint-down(lg) {
  .banner {
    .container_small {
      padding-left: 60px;
      padding-right: 60px;
    }
  }

  .stats_wrapper {
    .stats_container {
      margin-right: 60px;

      .stats_item {
        padding: 20px 25px;

        .stats_title {
          // @include fontSize(42);
          // @include lineHeight(50);
        }

        p {
          @include fontSize(16);
          @include lineHeight(20);
        }
      }
    }
  }

  .employees_carousel_wrapper {
    padding: 0 60px;
  }
}

@media screen and (max-width: 1440px) {
  .banner {
    .banner_title_wrapper {
      padding: 0 50px;
      // h1 {
      //     @include fontSize(90);
      //     @include lineHeight(95);
      // }
    }

    &.small_banner {
      .banner_title_wrapper {
        h1 {
          @include fontSize(32);
          @include lineHeight(36);
        }
      }
    }
  }
}

@media only screen and (max-width: 1336px) {
  .container {
    max-width: 1100px;
  }
}

@media screen and (max-width: 1280px) {
  .banner {
    .banner_title_wrapper {
      padding: 0 50px;

      h1 {
        @include fontSize(80);
        @include lineHeight(84);
      }
    }
  }

  .gallery-wrapper {
    .gallery-holder {
      .gallery-item-box {
        width: 29%;
      }
    }
  }
}

@include media-breakpoint-down(md) {
  .carousel_big_content_wrapper {
    .carousel_big_image {
      margin-bottom: 20px;
    }
  }

  .stats_wrapper {
    .stats_container {
      margin-right: 45px;
    }
  }

  .content_block_item {
    .content_block_content {
      padding: 0 !important;
    }
  }

  .employees_carousel_wrapper {
    padding: 0 60px;
    margin-top: 70px;
    // margin-top: 20px;

    .employee_box_item {
      .employee_box_image_wrapper {
        // margin-bottom: -57px;
        margin-top: -57px;

        img {
          width: 114px;
        }
      }

      .employee_box_content_wrapper {
        padding: 40px 10px 20px 10px;

        // min-height: 250px;
        &:before {
          width: 82px;
          height: 58px;
          // top: 40px;
          top: 0;
          left: 10px;
        }

        h2 {
          @include fontSize(16);
          @include lineHeight(20);
        }

        p {
          @include fontSize(14);
          @include lineHeight(18);
        }

        .employee_box_position {
          @include fontSize(14);
          @include lineHeight(18);
          margin-top: 20px;
        }
      }
    }

    .swiper-pagination {
      bottom: -20px;
    }
  }

  .sub_navigation_wrapper {
    .sub_navigation_item {
      .sub_navigation_item_inner {
        .icon_wrapper {
          .icon {
            svg {
              width: 60px;
              height: 60px;
            }
          }
        }

        .sub_navigation_item_title {
          padding: 14px 24px 12px 24px !important;
          font-size: 20px !important;
        }
      }
    }
  }

  .view-job-wrapper {
    margin-bottom: 30px;

    // display: inline-block;
    .contact-info {
      .details {
        .icon {
          position: unset;
          display: inline-block;
          margin-top: 10px;
        }
      }
    }
  }

  #main-form {
    input[name='zip'] {
      padding-left: 5px;
      padding-right: 5px;
    }
  }

  .popup.dramatic-event-popup {
    .inner {
      flex-direction: column;
      width: 100%;
      text-align: center;
      height: 90%;
    }

    .header {
      flex: 1;
    }

    .icon {
      font-size: 100px;
    }
  }
}

@media screen and (max-width: 1024px) {
  .container {
    max-width: 890px;
  }

  .gallery-wrapper {
    .gallery-holder {
      .gallery-item-box {
        width: 29.1%;
      }
    }
  }

  .popup-gallery-box {
    padding: 50px;
    overflow-y: auto;

    .close_button {
      top: 10px;
      right: -10px;
    }

    .popup-holder {
      top: 0;
      left: 0;
      transform: unset;
      max-width: 100%;
      width: 100%;
      min-height: 50vh;
      margin: 0 auto;
      padding: 60px 30px 30px 30px;
      position: relative;

      .inner-popup {
        flex-direction: column;

        .popup-image {
          max-width: 100%;
        }

        .popup-content {
          padding-left: 0;
          padding-top: 30px;
          max-width: 100%;
        }
      }
    }
  }

  .banner {
    height: 48vh;
    padding-bottom: 60px;

    &.small_banner {
      h1 {
        @include fontSize(30);
        @include lineHeight(40);
      }
    }

    .vertical_text {
      span {
        height: calc(100% - 100px);
        padding-right: 5px;
        padding-left: 5px;
      }
    }
  }

  .content_element.employees_carousel_element {
    overflow-x: hidden;
    background: #f5f5f5;
    padding-bottom: 50px;
    padding-top: 30px;
  }

  .timetable_wrapper {
    &:after {
      // bottom: 0px;
    }
  }

  .navigation_wrapper,
  .search_wrapper {
    &:after {
      bottom: 0px;
    }
  }

  .employees_carousel_wrapper {
    .employee_box_item {
      .employee_box_content_wrapper {
        padding: 30px 20px 30px 20px;

        &:before {
          top: -40px;
        }
      }

      margin-bottom: 20px;
    }
  }

  .view-job-wrapper {
    margin-bottom: 30px;
    // display: inline-block;
  }

  .scroll_top_wrapper {
    .scroll_top_button {
      bottom: 15px;
      right: -15px;
    }
  }

  .content_block_item {
    .content_block_image {
      height: auto;
    }
  }

  .abellio_world_element {
    .abellio_world_filter_trigger {
      margin: 16px 15px;
    }
  }

  .register_page {
    .timetable_submit_wrapper {
      bottom: 40px;
    }
  }

  .incidents-list {
    .an-info {
      &.an-info-top {
        flex-direction: column;

        >.inner {
          max-width: 100%;
        }

        .content {
          max-width: 100%;

          .inner {
            padding-left: 20px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .banner {
    .banner_title_wrapper {
      h1 {
        @include fontSize(65);
        @include lineHeight(75);
      }
    }
  }

  .gallery-wrapper .gallery-holder .gallery-item-box {
    width: 44.1%;
  }

  .text_size_large {
    .sub_navigation_wrapper {
      .sub_navigation_item {
        .sub_navigation_item_title {
          padding: 0 10px;
        }
      }
    }
  }

  .timetable_wrapper {
    .timetable_inner_wrapper {
      padding: 100px 100px 120px 100px;
    }

    .timetable_title {
      margin-bottom: 20px;
    }
  }
}

@include media-breakpoint-down(sm) {
  body {
    .swiper-container-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet {
      width: 30px;
    }
  }

  p {
    @include fontSize(16);
  }

  ul li,
  ol li {
    @include fontSize(16);
  }

  h2 {
    @include fontSize(28);
  }

  h3,
  .h3 {
    @include fontSize(24);
  }

  h4,
  .h4 {
    @include fontSize(22);
  }

  h5,
  .h5 {
    @include fontSize(20);
  }

  .a-warning {
    h4 {
      @include fontSize(22);
    }
  }

  .abellio_world_element {
    .abellio_world_filter_trigger {
      @include fontSize(14);
      @include lineHeight(22);
    }
  }

  .datetimepicker:not(.inline) {
    .datepicker {
      height: calc(100vh - 76px) !important;
    }
  }

  .page_section {
    min-height: calc(100vh - 76px);
    // padding-top: 55px;
  }

  .left_buttons_wrapper,
  .page_top_wrapper {
    display: none;
  }

  .register_page {
    .timetable_element {
      margin-bottom: 100px;
    }
  }

  .banner {
    display: flex;
    // align-items: flex-end;
    // align-items: center;
    height: auto;
    min-height: 346px;
    padding-top: 35px;
    padding-bottom: 165px;
    margin-bottom: 30px;

    &:before {
      border-top: 60px solid transparent;
    }

    // h1 {
    //     @include fontSize(55);
    //     @include lineHeight(74);
    // }

    .container_small {
      padding-left: 30px;
      padding-right: 30px;
    }

    .banner_title_wrapper {
      position: relative;
      bottom: 0;
      padding: 15px 15px;
      text-align: left;

      &>.container {
        padding: 0;
      }
    }

    .vertical_text {
      span {
        height: calc(100% - 25px);
      }
    }
  }

  .timetable_inner_result_wrapper {
    .timetable_inner_result_addresses {
      margin-left: -15px;
    }
  }

  .application_button_wrapper {
    bottom: 26px;

    .application_button {
      width: 104px;
      @include fontSize(15);
      @include lineHeight(18);
      font-size: 15px;
      line-height: 18px;
      left: 8px;
      bottom: -25px;
      position: absolute;
    }
  }

  .gallery-wrapper .gallery-holder .gallery-item-box {
    width: 44.1%;
  }

  .traffic_infobox_element {
    margin-bottom: 0;
  }

  .sub_navigation_element {
    margin-bottom: 30px;
  }

  .full_image_element {
    margin-bottom: 30px;
  }

  .carousel_content_element {
    margin-bottom: 30px;
  }

  .jobs_carousel_element {
    margin-bottom: 30px;
  }

  .employees_carousel_element {
    margin-bottom: 30px;
  }

  .title_big_image_element {
    margin-bottom: 30px;
  }

  .columns_content_element {
    margin-bottom: 0px;
  }

  .stations_list_element {
    margin-bottom: 0px;
  }

  .place_info_element {
    margin-bottom: 10px;
  }

  .stats_element {
    margin-bottom: 10px;
  }

  .timeline_element {
    margin-bottom: 30px;
  }

  .form_builder_element {
    margin-bottom: 10px;
  }

  .accordion_element {
    margin-bottom: 50px;

    h3 {
      margin-bottom: 0;
    }
  }

  .job_search_element {
    margin-bottom: 40px;
  }

  .content_blocks_element {
    margin-bottom: 0;
  }

  .address_box_column {
    padding-left: 15px;
  }

  .address_box_wrapper {
    max-width: 100%;
  }

  .job_offers_map_element {
    margin-bottom: 30px;
    height: 346px;

    .timetable_information_extras_overlay {
      display: block !important;
      z-index: 0;
    }
  }

  .abellio_world_element {
    margin-bottom: 30px;
  }

  .regions_page_element {
    margin-bottom: 30px;
  }

  .account_boxes_element.dark_grey_bg {
    display: none;
  }

  .jobs_navigator_form_element {
    padding-top: 60px;
    padding-bottom: 20px;
    height: calc(100vh - 76px);
    overflow-y: auto;
    display: flex;
    flex-direction: column;

    &.applications_wizard_form_element {
      padding-top: 60px;
    }

    &:before {
      display: none;
    }

    .jobs_navigator_menu {
      .jobs_navigator_menu_inner {
        justify-content: flex-start;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;

        .d-flex {
          padding-right: 30px;
        }
      }
    }

    .jobs_navigator_container {
      padding: 0;
      flex-grow: 1;

      .container_small {
        height: 100%;
        display: flex;
        flex-direction: column;

        .jobs_navigator_inner {
          flex-grow: 1;
        }
      }
    }

    .jobs_navigator_progress_wrapper {
      display: none;
    }
  }

  .abellio_info_box {
    .abellio_info_box_image {
      img {
        width: 130%;
        margin-left: -30%;
        height: 150px;
      }
    }

    .abellio_info_box_content {
      .abellio_info_box_title {
        @include fontSize(18);
        @include lineHeight(26);
      }
    }

    .abellio_info_box_more {
      @include fontSize(14);
      @include lineHeight(24);
    }
  }

  .content_element {
    &.accordion_element {
      .accordion_item {
        .accordion_content {
          ul {
            padding: 0 0 0 0;
          }
        }
      }
    }
  }

  .stats_wrapper {
    width: 100%;
    padding-right: 0;
    flex-direction: column;

    .stats_container {
      margin-right: 0;
      align-content: center;

      .stats_item {
        // padding: 10px 10px;
        // width: 100%;

        .stats_title {
          @include fontSize(30);
          @include lineHeight(40);
        }

        p {
          @include fontSize(14);
          @include lineHeight(16);
        }
      }
    }

    .stats_content {
      width: 100%;
      max-width: 100%;
      margin-top: 30px;
    }
  }

  .timeline_wrapper {
    &:before {
      width: 94px;
    }

    .timeline_item {
      padding-right: 50px;

      p {
        font-size: 1rem;
        line-height: 1.5;
      }

      .timeline_contents {
        padding-right: 20px;
      }

      .timeline_content {
        padding-right: 0px;
      }

      .timeline_year {
        @include fontSize(48);
        @include lineHeight(56);
      }

      &.align-self-end {
        padding-left: 50px;

        .timeline_contents {
          padding-left: 20px;
        }

        .timeline_content {
          padding-left: 0;
        }
      }
    }
  }

  #app {
    padding-bottom: 76px;
  }

  .footer_navigation {
    display: flex;
    height: 76px;
    border: 0;
    box-shadow: 0 -3px 15px rgba(0, 0, 0, 0.1);

    .left_buttons_wrapper {
      display: flex;
      width: 100%;
      position: relative;
      top: auto;
      left: auto;
      padding: 0 40px;

      .left_button {
        display: none !important;
        background: transparent;
        border: 0 none;
        margin: 0 !important;
        box-shadow: none;

        i {
          color: $menu-svg-color;
          font-size: 24px;
        }

        &:hover,
        &.is_active,
        &.router-link-active {
          color: $abellio-red;

          i {
            color: $abellio-red;
          }
        }
      }
    }

    .menu_trigger {
      width: 55px;
      height: 55px;

      i {
        font-size: 18px;

        &:before {
          color: $abellio-gray;
        }
      }

      svg {
        width: 30px;
        height: 30px;
        @include svgColor($menu-svg-color);
      }

      &:hover,
      &.is_active {
        @include svgColor($abellio-red);
      }
    }
  }

  .navigation_wrapper {
    padding-top: 0;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
    // height: calc(100% - 76px);
    height: 100%;
    display: block !important;

    &:before,
    &:after {
      display: none;
    }

    .close_button {
      display: none;
    }

    .navigation_overlay {
      top: 0;
      background: $abellio-red;
    }

    .nav_title {
      padding-top: 20px;
      text-align: center;
      padding-bottom: 15px;
      margin-bottom: 0;
      background: $white;
    }

    .navigation_inner {
      max-width: 100%;
      text-align: left;
      height: 100%;
      overflow-x: hidden;

      .first_level {
        padding-bottom: 150px;
        position: relative;
        margin-bottom: 160px;
        margin-bottom: 100px;
        padding-top: 25px;

        &:before {
          content: '';
          position: absolute;
          z-index: -1;
          left: 0;
          top: 0;
          width: 100%;
          height: calc(100% - 145px);
          background: $white;
        }

        &:after {
          content: '';
          position: absolute;
          z-index: -1;
          left: 0;
          bottom: 0;
          width: 100%;
          height: 145px;
          border-top: 60px solid $white;
          border-left: 50vw solid transparent;
          border-right: 50vw solid transparent;
        }
      }
    }

    .user_profile_wrapper {
      bottom: 35px;
      top: auto;

      .user_profile {
        color: $white;
        @include fontSize(20);
        @include lineHeight(30);

        .icon {
          background-image: url(../../assets/images/user_profile_icon_white.svg);
        }
      }
    }

    .region_changer_wrapper {
      display: block;
      bottom: 10px;
    }

    .locale_changer_wrapper {
      display: flex;
    }

    .input-wrapper {
      padding: 0 0px 0 10px;
      background-color: #fff;
      width: calc(100% - 61px);
      margin: 50px auto;

      input {
        background-color: transparent;
        border: none;
        margin-bottom: 0;
        line-height: 20px;
      }

      .search-icon {
        color: #ce132d;
        margin-top: 0;
        font-size: 20px;
        line-height: 42px;
      }

      button {
        border-radius: 0px;
      }
    }
  }

  .mobile_title_wrapper {
    display: block;
  }

  .accordion_wrapper {
    .accordion_item {
      margin-bottom: 30px;
    }
  }

  .carousel_content_wrapper {
    .swiper-container {
      overflow: visible;
      padding-bottom: 15px;
    }
  }

  .title_big_image_wrapper {
    width: 100% !important;
  }

  .abellio_world_element {
    margin-bottom: 0;
    height: calc(100vh - 76px);

    .map_zip_code_cannger_wrapper {
      display: none;
    }

    .job_offers_map_element {
      height: 100%;
      margin-bottom: 0;

      &:before {
        display: none;
      }
    }

    .abellio_world_filter_wrapper {
      margin-bottom: 0;
      position: absolute;
      left: 0;
      width: 100%;
      background: $light-grey;
      border-radius: 10px 10px 0 0;
      @include transition(0.8);
      padding: 25px 10px 0 10px;
      overflow: hidden;
      max-height: 0;
      bottom: 0;

      &.is_open {
        max-height: 2000px;
      }
    }

    .abellio_world_filter_title,
    .abellio_world_filter_region {
      display: block;
    }

    .abellio_world_filter_close {
      display: block;
    }

    .abellio_world_filter_close_button {
      display: block;
    }
  }

  .regions_section {
    display: flex;
    flex-direction: column;

    .job_offers_map_element {
      height: auto;
      flex-grow: 1;

      .vue-map-container {
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }

  .popup-gallery-box {
    .popup-holder {
      .inner-popup {
        .popup-content {
          h2 {
            font-size: 1.5rem;
          }
        }
      }
    }
  }

  .timetable_wrapper {
    // padding-top: 20px;
    // padding-bottom: 150px;
    // height: auto;
    height: calc(100vh - 150px);

    .timetable_inner_wrapper {
      padding: 30px 30px 70px 30px;
    }

    .timetable_title {
      position: relative;
      padding-bottom: 14px;

      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 0);
        width: 100vw;
        height: 1px;
        background: $white;
      }
    }

    .timetable_controls_wrapper {
      padding-top: 0;
      // padding-right: 32px;

      .timetable_controls_line {
        left: auto;
        right: 0;
        height: 100%;
        width: auto;
        flex-direction: column;
        padding-top: 20px;
        padding-bottom: 20px;

        &:before {
          top: 20px;
          bottom: 20px;
          width: 2px !important;
          height: auto;
          left: 50%;
          transform: translate(-50%, 0);
        }
      }

      .timetable_inputs_wrapper {
        flex-direction: column;
        margin-right: 32px;

        .timetable_input_wrapper {
          &:first-child {
            // margin-bottom: 38px;
          }
        }

        .timetable_switch_button_wrapper {
          position: absolute;
          z-index: 2;
          margin: 0;
          right: 32px;
          bottom: 0px;

          .timetable_switch_button {
            margin-top: 0;
            width: 53px;
            height: 53px;
            padding: 6px;

            svg {
              @include svgColor($black);
              transform: rotate(90deg);
              margin-bottom: 0;
            }
          }
        }

        .timetable_input_autocomplete_wrapper {
          .timetable_input_autocomplete {
            margin-left: 0;
            right: auto !important;
            left: 50% !important;
            width: 101%;
            transform: translate(-50%, 0);
          }
        }
      }
    }
  }

  .timetable_submit_wrapper {
    .btn {
      min-width: auto;
    }
  }

  .timetable_datetime_wrapper {
    justify-content: flex-start;
    margin-top: 10px;
  }

  .timetable_my_locations_wrapper {
    margin-top: 15px;
    margin-bottom: 0px;

    .timetable_my_locations_title {
      text-align: left;
    }

    .timetable_my_locations {
      justify-content: flex-start;
    }
  }

  .timetable_result_banner {
    .timetable_wrapper {
      height: auto;
      max-height: 100%;

      .timetable_submit_wrapper {
        bottom: 0 !important;

        @include media-breakpoint-down(sm) {
          position: relative !important;
        }
      }
    }

    .timetable_inner_result_addresses {
      text-align: left;
    }
  }

  .sub_navigation_wrapper {
    .sub_navigation_item {
      .sub_navigation_item_inner {
        .icon_wrapper {
          .icon {
            svg {
              width: 48px;
              height: 48px;
            }
          }
        }
      }
    }
  }

  .abellio_world_mobile_content_wrapper {
    position: absolute;
    overflow: hidden;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 85%;
    background: $light-grey;
    border-radius: 10px 10px 0 0;
    transform: translate(0, 110%);
    @include transition(0.5);
    padding: 25px 0 0 25px;

    .abellio_world_mobile_content_inner {
      padding-right: 25px;
      overflow: hidden;
      overflow-y: auto;
      height: 100%;
    }

    .abellio_world_mobile_list_wrapper {
      height: 100%;
      overflow: hidden;
      overflow-y: auto;
    }

    &.is_open {
      transform: translate(0, 0%);
    }

    .abellio_world_mobile_list_title {
      padding-left: 64px;
      position: relative;
      min-height: 40px;
      width: 100%;
      @include fontSize(22);
      @include lineHeight(28);

      .icon {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translate(0, -50%);
      }
    }

    .abellio_world_mobile_list_item {
      border-bottom: 1px solid $abellio-red;
      margin-bottom: 20px;
      padding-bottom: 20px;

      .abellio_world_mobile_list_item_title {
        margin-bottom: 5px;
        @include fontSize(20);
        @include lineHeight(22);
      }

      p {
        margin-top: 5px;
        margin-bottom: 0;
      }
    }

    .abellio_world_mobile_list_title+.abellio_world_mobile_list_item {
      border-top: 1px solid $abellio-red;
      margin-top: 20px;
      padding-top: 20px;
    }
  }

  .applications_other {
    margin-top: 20px;
  }

  .favorite_places_wrapper {
    .favorite_places_item {
      padding-left: 66px;

      .icon {
        left: 0;
      }
    }
  }

  .timetable_information_stations_wrapper {
    flex-direction: column;
    margin-bottom: 0;

    .timetable_information_stations_item {
      padding-top: 0;
      padding-right: 0;
      padding-left: 50px;
      padding-bottom: 30px;

      &:before {
        left: 9px;
        top: 0;
        bottom: 0;
        right: auto;
        width: 2px;
        height: auto;
      }
    }
  }

  .accordion_item {
    .tabs_section {
      ul {
        border-bottom: none;
        flex-direction: column;
        justify-content: flex-start;
        margin-top: 20px;

        li {
          margin-bottom: 20px;
          padding: 0 0px;

          a {
            font-size: 1.2rem;
            width: 100%;
            display: inline-block;
            margin-bottom: 0;
          }

          &:after {
            display: none;
          }
        }
      }
    }
  }

  .gallery-wrapper {
    .gallery-holder {
      .gallery-item-box {
        width: 100%;
      }
    }
  }

  .content_blocks_element {
    .content_block_item {
      padding-bottom: 20px;
      flex-direction: column-reverse !important;
      padding-top: 20px;
      // margin: 0;
    }
  }

  .traffic-information-wrapper {
    padding-right: 0 !important;
  }

  .traffic-information {
    .content {
      border-bottom-left-radius: 12px;
      border-bottom-right-radius: 12px;

      .inner {
        padding-left: 20px;
        max-width: 100% !important;
      }

      .line-date {
        max-width: 70%;

        &-d {
          font-size: 14px;
          line-height: 15px;
        }
      }
    }

    .incidents-list {
      &-in {
        display: flex;
        overflow-x: auto;
        margin-left: -8px;
      }

      .heading.inner.d-flex {
        flex-direction: row;
        min-height: 160px;
        background: #6b717a1a;
        overflow: hidden;
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
        padding: 24px;

        .relation {
          padding-left: 0;
          margin-left: 20px;
          padding-right: 21px;
          height: auto;

          &:before {
            right: 0;
            left: auto;
            top: 25px;
            height: calc(100% - 63px);
            transform: unset;
          }

          .departure,
          .arrival {
            text-align: right;
            @include fontSize(14);
            @include lineHeight(15);

            .dot {
              right: -28px;
              left: auto;
            }
          }

          .arrival {
            .dot {
              bottom: auto;
              top: 0;
            }
          }
        }

        .route-code {
          padding-left: 0;
          padding-bottom: 10px;
          font-size: 1.5rem;
          width: 100%;
          min-width: auto;
        }
      }

      .an-info.an-info-top {
        width: 100%;
        min-width: 282px;
        margin-bottom: 20px;

        &:last-child {
          margin-right: 24px;
        }
      }

      .show_more_btn {
        float: unset;
      }
    }

    .tabs_wrapper {
      margin-right: 24px !important;
      margin-left: 0 !important;
      margin-bottom: 1.8rem !important;

      .tab_item_switch {
        margin: 0 0px !important;
        min-height: 60px;
        padding-bottom: 16px;
        display: flex;
        align-items: flex-end;

        .switcher {
          display: flex;
          align-items: flex-end;
          font-size: 14px;
          line-height: 16px;
        }

        .switcher-counter {
          background-color: transparent;
          color: $abellio-red;
          margin-left: 5px;
          padding: 0;

          &::before {
            content: '(';
          }

          &::after {
            content: ')';
          }
        }

        &.active {
          .switcher {
            font-size: 16px;
            line-height: 16px;
          }
        }
      }
    }

    &.traffic-info-message {
      .right-actions {
        top: 35px;
        bottom: auto;
      }
    }

    .route-stops {
      .start {
        padding-right: 15px;
        font-size: 14px;
        line-height: 15px;
        align-items: flex-end;
        justify-content: flex-start;
        width: 50%;
        word-wrap: break-word;
      }

      >div {
        padding-bottom: 15px;
        width: 50%;
        font-size: 14px;
        line-height: 15px;
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
      }
    }

    .line-filter {
      .line-info {
        margin: 0 12px 10px 0px;
      }
    }
  }

  .traffic-information .content .inner .text-muted,
  .traffic-information .content .inner .accordion_wrapper .accordion_item .accordion_content h4,
  .accordion_wrapper .accordion_item .accordion_content .traffic-information .content .inner h4,
  .traffic-information .content .downloads-wrapper .a-download .inner .info,
  .downloads-wrapper .a-download .traffic-information .content .inner .info,
  .traffic-information .content .inner .search-results .results-count,
  .search-results .traffic-information .content .inner .results-count,
  .traffic-information .content .a-search .inner .info,
  .a-search .traffic-information .content .inner .info {
    margin: 0 0 8px 0 !important;
  }

  .traffic-information div.inner {
    padding: 20px 30px 20px 5px;
  }

  .traffic-information .content .inner {
    padding-left: 20px;
  }

  .employees_carousel_wrapper .swiper-slide {
    margin-right: 30px !important;
  }

  .jobs_carousel_element {
    margin-bottom: 45px;
  }

  .gallery-wrapper .gallery-holder .gallery-item-box {
    width: 100%;
  }

  .timetable_element {
    &.sticky-timetable {
      z-index: 99;
    }
  }

  .popup {
    padding: 0 30px;
  }

  .timetable_wrapper {
    &:after {
      border-top: 70px solid #ce132d;
    }

    .timetable_inner_wrapper {
      .close_button {
        bottom: -60px;
      }
    }
  }

  .timetable_submit_wrapper {
    bottom: -10px;
  }

  .route-notifiers {
    position: unset;

    >div {
      opacity: 1 !important;
    }

    .a-notify {
      width: auto;

      // height: 70px;
      img {
        width: 50px;
        height: 50px;

        &.triangle {
          width: 50px;
          height: 50px;
        }
      }

      >p {
        font-size: 0.9rem;
      }
    }
  }

  .accordion_element {
    .accordion_item {
      .accordion_content {
        ul {
          padding: 0 0 0 15px;

          li {
            a {
              @include fontSize(14);
            }
          }
        }
      }
    }

    h3 {
      color: $black;
    }
  }

  .breadcrumb_section {
    ul {
      display: inline-block;
      width: 75%;

      li {
        display: inline-block;
      }
    }

    .zuruck_button {
      display: flex;
      align-items: center;
      min-width: 20%;
      padding-left: 15px;

      &:before {
        left: 0px;
      }
    }
  }

  .traffic-information {
    .tabs_wrapper {
      margin: 0 -5px;

      .tab_item_switch {
        margin: 0 5px;
      }
    }
  }

  .accordion_wrapper {
    .accordion_item {
      .accordion_title {
        padding: 18px 40px 18px 18px;

        &:after {
          transform: rotate(-180deg) translateY(-30%);
          top: 30%;
        }
      }

      &.is_open {
        .accordion_title {
          &:after {
            transform: rotate(0deg) translateY(-50%);
          }
        }
      }
    }
  }

  .login_page {
    .timetable_element {
      margin-bottom: 160px;
    }
  }

  .traffic-information .line-filter {
    display: flex;
    justify-content: unset;
    margin-bottom: 30px;
    flex-wrap: nowrap;
    overflow-y: auto;
    padding-right: 15px;

    .line-info {
      word-break: keep-all;
      white-space: nowrap;
    }
  }

  .incidents-list {
    .an-info.an-info-top {
      align-items: unset;
      width: 80%;
      overflow: unset !important;
      margin: 0 8px;

      .text-more {
        display: none;
      }
    }
  }
}

@media (max-device-width: 800px) and (orientation: landscape) {
  .timetable_submit_wrapper {
    bottom: -40px;
  }

  .footer_navigation {
    height: 50px;

    .left_buttons_wrapper {
      .left_button {
        width: 40px;
        height: 40px;
      }
    }
  }

  .timetable_wrapper {
    height: calc(100vh - 120px);

    .timetable_inner_wrapper {
      min-height: auto;
      height: calc(100vh - 120px);
      overflow-y: auto;
      padding: 15px 30px 50px 30px;

      .close_button {
        bottom: -65px;
      }
    }

    .timetable_controls_wrapper {
      .timetable_inputs_wrapper {
        .timetable_input_wrapper {
          margin-bottom: 10px !important;

          p {
            margin-bottom: 0px;
          }

          label {
            top: 3px;
          }

          input {
            padding: 18px 35px 3px 10px;
          }
        }
      }
    }

    .timetable_title {
      position: relative;
      padding-bottom: 5px;
      margin-bottom: 5px;
    }
  }

  .navigation_wrapper {
    height: calc(100% - 58px);
    min-height: auto;

    .navigation_inner {
      height: calc(100% - 126px);
      background: #fff;
      overflow: auto;

      .first_level {
        padding-bottom: 0px;
        position: relative;
        margin-bottom: 160px;
        margin-bottom: 20px;
        padding-top: 25px;
        height: 230px;
        overflow-y: auto;
        background: #fff;

        &::after {
          display: none;
        }
      }

      &:after {
        content: '';
        position: absolute;
        z-index: -1;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 126px;
        border-top: 60px solid #fff;
        border-left: 50vw solid transparent;
        border-right: 50vw solid transparent;
      }
    }

    .locale_changer_wrapper {
      display: flex;
      position: absolute;
      left: 0;
      right: 0;
      margin: 0;
      bottom: 75px;
    }
  }
}

@media screen and (max-width: 823px) {
  .container {
    max-width: 660px;
  }

  .row {
    margin-left: -24px;
    margin-right: -24px;
  }

  .container,
  .col-1,
  .col-2,
  .col-3,
  .col-4,
  .col-5,
  .col-6,
  .col-7,
  .col-8,
  .col-9,
  .col-10,
  .col-11,
  .col-12,
  .col,
  .col-auto,
  .col-sm-1,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12,
  .col-sm,
  .col-sm-auto,
  .col-md-1,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-md-10,
  .col-md-11,
  .col-md-12,
  .col-md,
  .col-md-auto,
  .col-lg-1,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12,
  .col-lg,
  .col-lg-auto,
  .col-xl-1,
  .col-xl-2,
  .col-xl-3,
  .col-xl-4,
  .col-xl-5,
  .col-xl-6,
  .col-xl-7,
  .col-xl-8,
  .col-xl-9,
  .col-xl-10,
  .col-xl-11,
  .col-xl-12,
  .col-xl,
  .col-xl-auto {
    padding-left: 24px;
    padding-right: 24px;
  }
}

@media screen and(max-width: 768px) {
  .banner {
    min-height: 450px;
    margin-bottom: 32px;

    .banner_title_wrapper {
      h1 {
        @include fontSize(50);
        @include lineHeight(60);
      }
    }
  }

  .navigation_wrapper,
  .search_wrapper {
    &:after {
      border-top: 100px solid #fff;
    }
  }

  .user_profile_wrapper {
    bottom: 95px;
  }

  .close_button {
    bottom: 50px;
  }

  .search-inner {
    .search-input {
      font-size: 1.5rem;
      line-height: 3rem;
    }
  }

  .footer_navigation {
    .left_buttons_wrapper {
      padding: 0 20px;
      height: auto;
      flex-direction: row;
    }
  }

  .timetable_wrapper {
    .login_section_wrapper {
      .desc {
        max-width: 95%;
      }
    }
  }
}

@include media-breakpoint-down(xs) {
  .timetable_datetime_wrapper .datetimepicker:not(.inline) .pickers-container {
    height: calc(100% - 175px) !important;
  }

  .locale_changer_wrapper {
    a {
      &:after {
        background-color: $abellio-red;
      }
    }
  }

  .navigation_wrapper,
  .search_wrapper {
    .navigation_inner {
      .first_level {
        &:before {
          top: 1px;
        }

        >li {
          border-top: 1px solid $abellio-red;
        }
      }
    }
  }

  .timetable_my_locations_wrapper {
    .timetable_my_locations {
      display: inline-block;

      .timetable_my_locations_item {
        float: left;
        margin-left: 0;
        margin-right: 10px;
        margin-bottom: 10px;
      }
    }
  }

  .main_form_faq {
    .forms_fields {
      .col-6 {
        flex: 0 0 100%;
        max-width: 100%;
      }
    }
  }

  .banner {
    min-height: 272px;
    padding-bottom: 79px;
    text-align: center;

    .banner_title_wrapper {
      h1 {
        // font-size: 3.1rem;
        // line-height: 4.3rem;
        @include fontSize(56);
        @include lineHeight(60);
      }
    }

    &.small_banner {
      padding-top: 50px;
      padding-bottom: 100px;

      h1 {
        @include fontSize(14);
        @include lineHeight(30);
      }
    }
  }

  .sub_navigation_wrapper {
    margin: 0 35px;

    .sub_navigation_item {
      .sub_navigation_item_inner {
        .icon_wrapper {
          padding: 10px;
          padding-bottom: 8px;
        }

        .sub_navigation_item_title {
          @include fontSize(14);
          @include lineHeight(18);
          padding: 0 10px;
        }
      }
    }
  }

  .timeline_wrapper {
    padding-top: 0;
    display: block !important;

    &:before {
      display: none;
    }

    .timeline_item {
      padding: 0 !important;
      width: 100%;
      margin-top: 0 !important;
      min-height: 0;

      .timeline_year {
        padding: 14px 20px;
        display: block;
        position: relative !important;
        top: auto !important;
        bottom: auto !important;
        right: auto !important;
        left: auto !important;
        width: 100%;
        height: auto;
        background: $abellio-red;
        text-align: right;

        br {
          display: none;
        }
      }

      .timeline_contents {
        padding-top: 20px;
      }

      &.align-self-end {
        .timeline_year {
          text-align: left;
        }
      }
    }
  }

  .footer_section {
    background-color: #fff;
  }

  .html-content {
    table {
      overflow-y: auto;
      display: block;
      border: none;

      th,
      td {
        &:last-child {
          min-width: 90px;
        }
      }
    }
  }

  .sub_navigation_wrapper {
    .sub_navigation_item {
      .sub_navigation_item_inner {
        .icon_wrapper {
          .icon {
            svg {
              width: 32px;
              height: 32px;
            }
          }
        }
      }
    }
  }

  .account_boxes_item {
    padding: 0 0px;

    .icon {
      width: 65px;
      height: 65px;
      bottom: 14px;
    }

    h5 {
      @include fontSize(16);
      @include lineHeight(20);
      margin-bottom: 10px;
    }

    p {
      @include fontSize(14);
      @include lineHeight(18);
    }
  }

  .timetable_information_extra_wrapper {
    .timetable_information_extras {
      width: 220px;

      &.is_active {
        margin-right: 12px;
      }
    }
  }

  .traffic-information {
    .right-actions {
      right: 20px;
    }
  }

  .content_element {
    &.timetable_element {
      position: relative;

      .timetable_submit_wrapper {
        position: absolute;
        bottom: -60px;
        left: 0;
        right: 0;
        z-index: 9;
      }
    }
  }

  .mobile_title_wrapper {
    .mobile_title_back {
      top: 10px;
    }
  }

  .popup-gallery-box {
    .popup-holder {
      .inner-popup {
        .popup-image {
          max-width: 100%;
          width: 100%;
          min-height: 322px;
          background-position: center;
        }
      }
    }
  }

  .navigation_wrapper,
  .search_wrapper {
    .navigation_inner {
      .first_level {
        >li {
          >a {
            font-size: 1.15rem;
          }
        }
      }
    }
  }

  .timetable_wrapper {
    .timetable_title {
      font-size: 1.18rem;
    }
  }

  .traffic-information {
    .container-fluid {
      .row {
        .row.mb-3 {
          .col-6 {
            flex: 0 0 100%;
            max-width: 100%;
          }
        }
      }
    }
  }

  .page_section {
    .error_page {
      text-align: center;
      padding: 0 20px;

      h1 {
        color: $abellio-red;
        font-size: 8rem;
        margin-bottom: 20px;
      }

      h6 {
        font-size: 1.5rem;
        line-height: 1.2;
      }

      p {
        font-size: 1rem;
        margin-top: 20px;
      }
    }
  }

  .footer_section {
    .top_section_footer {
      display: none;
    }

    .bottom_section_footer {
      border-top: 1px solid $dark-gray;
    }
  }

  .vue-map-container {
    .gm-style {
      .info-window {
        &.ticket_sales_info_window {
          max-width: 300px;
          width: 100%;
        }
      }
    }
  }

  //   .timetable_results_content {
  //     margin-top: 110px;
  //     }
  .account_information_element {
    .account_information_table {
      margin-bottom: 30px;
      table-layout: unset;
    }

    .account_information_title {
      margin-bottom: 20px;
    }

    .favorite_places_content {
      margin-top: 0;
    }

    .account_saved_rides_table_info {
      margin-top: 30px;
    }

    .account_information_second_part {
      margin-top: 30px;
    }

    .br_line {
      display: none;
    }
  }

  .timetable_wrapper {
    .timetable_controls_wrapper {
      .timetable_inputs_wrapper {
        .timetable_input_wrapper {
          .timetable_input_autocomplete_wrapper {
            .timetable_input_autocomplete {
              .timetable_input_autocomplete_item {
                padding-top: 10px;
                padding-bottom: 10px;
                padding-left: 40px;
                min-height: 50px;
                margin-left: 15px;
                font-size: 0.9rem;

                .icon {
                  width: 25px;
                  height: 25px;

                  i {
                    font-size: 15px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .timetable_result_list {
    .timetable_result_list_item {
      .timetable_result_list_item_part1 {
        flex-direction: column;

        .road_parts_wrapper {
          flex-direction: column;
          margin-left: 15px;

          .road_parts_item {
            max-height: 100%;
            padding-bottom: 30px;
            padding-top: 0;
            padding-left: 30px;

            .road_parts_arrow {
              height: 100%;
              width: 20px;

              &:before {
                content: '';
                position: absolute;
                z-index: 1;
                top: 0;
                left: 0;
                width: 18px;
                height: 18px;
                background: #ce132d;
                border-radius: 50%;
              }

              &:after {
                content: '';
                position: absolute;
                z-index: 0;
                top: 50%;
                left: 8px;
                width: 2px;
                height: 100%;
                background: #4a4a4a;
                transform: translate(0, -50%);
              }
            }
          }
        }

        .start_time {
          margin-left: 15px;
          margin-bottom: 15px;
        }

        .end_time {
          margin-left: 15px;
        }
      }
    }
  }

  .abellio_info_box {
    .abellio_info_box_content {
      .abellio_info_box_title {
        &.abellio_info_box_title {
          font-size: 1rem;
        }
      }
    }
  }

  .carousel_big_content_wrapper {
    h2 {
      font-size: 1rem;
      line-height: 1.5;
    }
  }

  .a-warning {
    .icon {
      margin-bottom: 20px;
    }
  }

  .special-icons {
    .icon_acc_wrap {
      .icon-acc-text {
        @include fontSize(14);
        margin-right: 10px;
      }
    }
  }
}

@media screen and(max-width: 576px) {
  .banner {
    min-height: 336px;

    .banner_title_wrapper {
      h1 {
        @include fontSize(40);
        @include lineHeight(50);
      }
    }
  }

  .navigation_wrapper,
  .search_wrapper {
    min-height: auto;
  }

  .babasClass .close_button {
    bottom: -56px;
  }
}

@media screen and (max-width: 415px) {
  .datetimepicker:not(.inline) {
    z-index: 1000 !important;
  }

  .account_boxes_col {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }

  .popup-gallery-box {
    padding: 40px 20px;

    .popup-holder {
      .inner-popup {
        .popup-image {
          min-height: 225px;
        }
      }
    }
  }

  .banner {
    height: auto;
    min-height: 242px;
    margin-bottom: 24px;

    &.small_banner {
      min-height: 240px;

      .banner_title_wrapper {
        padding: 15px 25px 15px 15px;
      }
    }
  }
}

@media screen and (max-width: 375px) {
  .sub_navigation_wrapper {
    .sub_navigation_item {
      .sub_navigation_item_inner {
        .icon_wrapper {
          .icon {
            svg {
              width: 55px !important;
              height: 55px !important;
            }
          }
        }

        .sub_navigation_item_title {
          font-size: 1rem;
        }
      }
    }
  }

  .timetable_my_locations_wrapper {
    .timetable_my_locations {
      .timetable_my_locations_item {
        width: 35px;
        height: 35px;
        margin-bottom: 10px;
      }
    }
  }

  .timetable_my_locations_wrapper {
    margin-top: 10px;
    margin-bottom: 0px;
  }

  .timetable_wrapper {
    .timetable_inner_wrapper {
      padding: 30px 30px 80px 30px;
    }

    .timetable_controls_wrapper {
      .timetable_inputs_wrapper {
        .timetable_input_wrapper {
          &:first-child {
            margin-bottom: 20px;
          }

          label {
            top: 5px;
          }

          input {
            padding: 20px 10px 5px 10px;
          }
        }
      }
    }
  }

  .navigation_wrapper {
    .navigation_inner {
      .first_level {
        padding-top: 10px;
        padding-left: 15px;
        padding-right: 15px;
      }
    }
  }
}

@media screen and (max-width: 360px) {

  .navigation_wrapper,
  .search_wrapper {
    .navigation_inner {
      .first_level {
        > {
          li {
            >a {
              font-size: 1rem;
              line-height: 1.3rem;
            }
          }
        }
      }
    }
  }

  .timetable_wrapper {
    .timetable_inner_wrapper {
      .close_button {
        // bottom: -90px;
      }
    }
  }

  .timetable_wrapper {
    .timetable_title {
      margin-bottom: 15px;
    }
  }

  .timetable_my_locations_wrapper {
    .timetable_my_locations {
      .timetable_my_locations_item {
        width: 32px;
        height: 32px;
        margin-bottom: 10px;
      }
    }
  }

  .route-notifiers {
    .a-notify {
      >p {
        font-size: 0.8rem;
      }
    }
  }
}

@media screen and (max-width: 320px) {
  .banner {
    .banner_title_wrapper {
      h1 {
        @include fontSize(34);
        @include lineHeight(44);
      }
    }

    &.small_banner {
      padding-top: 70px;
      padding-bottom: 70px;
    }
  }

  .timetable_wrapper {
    .timetable_controls_wrapper {
      .timetable_inputs_wrapper {
        .timetable_input_wrapper {
          &:first-child {
            margin-bottom: 15px;
          }

          input {
            padding: 15px 10px 5px 10px;
            font-size: 0.8rem;
          }

          label {
            top: 2px;
          }
        }
      }

      .timetable_controls_line {
        padding-top: 10px;
        padding-bottom: 10px;
      }
    }

    .timetable_inner_wrapper {
      padding: 15px 15px 50px 15px;
    }

    &:after {
      top: auto;
    }
  }

  .timetable_datetime_wrapper {
    margin-top: 10px;
  }

  .timetable_my_locations_wrapper {
    margin-top: 5px;

    .timetable_my_locations_title {
      font-size: 0.8rem;
    }

    .timetable_my_locations {
      .timetable_my_locations_item {
        margin-bottom: 0px;
      }
    }
  }

  .route-notifiers {
    .a-notify {
      width: auto;

      // height: 50px;
      >p {
        font-size: 0.7rem;
        margin-top: 0;
      }
    }

    .icon {
      padding: 5px 0 2px 0;
      font-size: 26px;
    }
  }

  .timetable_element {
    &.sticky-timetable {
      .timetable_wrapper {
        padding-bottom: 130px;
      }

      .close_button {
        bottom: 50px;
      }
    }

    &.content_element {
      .timetable_submit_wrapper {
        bottom: -55px;
      }
    }
  }

  .navigation_wrapper,
  .search_wrapper {
    .navigation_inner {
      .first_level {
        >li {
          >a {
            font-size: 1rem;
            line-height: 1.2rem;
            padding: 10px 10px;
          }
        }
      }
    }
  }

  .navigation_wrapper {
    .user_profile_wrapper {
      bottom: 30px;
    }

    .region_changer_wrapper {
      bottom: 5px;
      z-index: 9;
    }

    .user_profile_wrapper {
      .logout-btn {
        padding-top: 0px;
      }
    }
  }

  .navigation_wrapper {
    .navigation_inner {
      .first_level {
        padding-bottom: 160px;
        margin-bottom: 160px;
        margin-bottom: 100px;
        padding-top: 25px;
      }
    }
  }

  .user_profile_wrapper {
    .user-account {
      margin-bottom: 0px;
    }
  }

  .popup-gallery-box {
    .popup-holder {
      .inner-popup {
        .popup-image {
          min-height: 100px;
        }
      }
    }
  }

  .popup-gallery-box {
    .popup-holder {
      .inner-popup {
        .popup-content {
          h2 {
            font-size: 1.2rem;
            line-height: 1.5;
          }
        }
      }
    }
  }

  .timetable_wrapper {
    &:after {
      border-top: 80px solid #ce132d;
    }
  }

  .timetable_submit_wrapper {
    bottom: 96px;

    .btn {
      font-size: 0.8rem;
      padding: 7px 10px;
    }
  }

  .register_page {
    .timetable_wrapper {
      &:after {
        border-top: 100px solid #ce132d;
      }
    }
  }

  .navigation_wrapper {
    height: calc(100% - 50px);
  }

  .footer_navigation {
    height: 50px;

    .left_buttons_wrapper {
      .left_button {
        i {
          font-size: 24px;
        }
      }
    }
  }
}

@media all and (-ms-high-contrast: none),
(-ms-high-contrast: active) {
  .map_zip_code_cannger_wrapper {
    fieldset {
      label {
        color: #fff;
      }
    }
  }

  .abellio_world_element {
    .abellio_world_filter_trigger {
      max-width: 170px;
      width: 100%;
    }
  }

  .breadcrumb_section {
    .zuruck_button {
      &:before {
        top: 5px;
      }
    }
  }

  .search-input[type='search']::-ms-clear {
    display: none;
    width: 0;
    height: 0;
    appearance: none;
  }

  .search-input[type='search']::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
    appearance: none;
  }

  .search-input[type='search']::-webkit-search-decoration,
  .search-input[type='search']::-webkit-search-cancel-button,
  .search-input[type='search']::-webkit-search-results-button,
  .search-input[type='search']::-webkit-search-results-decoration {
    display: none;
    appearance: none;
  }

  .timetable_datetime_wrapper .timetable_time .icon,
  .timetable_datetime_wrapper .timetable_date .icon {
    top: -5px;
  }

  .timetable_wrapper {
    .timetable_controls_wrapper {
      .timetable_controls_line {
        .timetable_controls_line_icon {
          position: absolute;
          left: 0;
          top: -14px;

          &.line_icon_end {
            right: 0;
            left: auto;
          }
        }
      }
    }
  }

  .timetable_inner_result_wrapper .timetable_inner_result_addresses .timetable_inner_result_line:before {
    top: 25px;
  }

  .banner {
    .vertical_text {
      top: 0;

      span {
        text-align: left;
        transform: rotate(-180deg);
      }
    }
  }
}

@media screen and (max-height: 675px) and (min-width: 768px) {

  //    .timetable_wrapper .timetable_inner_wrapper{
  //       padding:90px 50px 35px 54px;
  //    }
  .timetable_wrapper .timetable_title {
    margin-bottom: 0;
  }

  .timetable_wrapper .timetable_controls_wrapper {
    //   padding-top: 35px;
  }

  .timetable_my_locations_wrapper {
    margin: 20px 0;
  }

  .timetable_submit_wrapper {
    bottom: -70px;
  }
}

@media screen and (max-height: 690px) and (max-width: 425px) {
  .route-notifiers .a-notify img.triangle {
    height: 35px;
  }

  .timetable_wrapper .timetable_inner_wrapper {
    padding-bottom: 20px;
  }

  //    .timetable_wrapper .timetable_controls_wrapper .timetable_inputs_wrapper .timetable_input_wrapper:first-child{
  //       margin-bottom: 0;
  //    }
  .timetable_my_locations_wrapper .timetable_my_locations_title {
    font-size: 0.8rem;
  }
}

@include media-breakpoint-down(md) {
  .mt-md-0 {
    margin-top: 0 !important;
  }
}

@media screen and (max-width: 824px) {
  .timetable_wrapper {
    .timetable_controls_wrapper.time {
      justify-content: flex-start;
    }
  }

  .timetable_wrapper {
    .timetable_inner_wrapper {
      padding: 50px 100px 120px 50px;
    }

    .timetable_controls_wrapper {
      &.time {
        justify-content: space-between;

        .timetable_datetime_wrapper {
          width: 70%;
        }
      }

      .timetable_inputs_wrapper {
        margin-right: 0;

        .timetable_input_wrapper {
          width: auto;
          justify-content: space-between;
        }
      }
    }
  }
}

@media screen and (max-width: 670px) {
  .babasClass {
    .timetable_wrapper {
      .timetable_inner_wrapper {
        transform: translateY(0);
        width: 100%;
        // margin: 0 25px;
      }
    }

    .timetable_from_wrapper,
    .timetable_to_wrapper {
      width: 100%;
    }
  }

  .timetable_wrapper .timetable_controls_wrapper .timetable_inputs_wrapper .timetable_input_wrapper input {
    padding: 20px 10px 12px 60px;
  }

  .timetable_wrapper {
    .timetable_inner_wrapper {
      transform: translateY(0);
      width: auto;
      margin: 0 0;
    }

    .timetable_controls_wrapper {
      &.time {
        flex-direction: column;
        align-items: flex-start;

        .timetable_datetime_wrapper {
          width: 100%;
          // border: 1px solid red;
        }

        label {
          margin-bottom: 10px;
        }
      }

      .timetable_inputs_wrapper {
        margin-right: 0;

        .timetable_input_wrapper {
          width: auto;
          justify-content: space-between;
        }
      }
    }

    .timetable_controls_wrapper {
      .timetable_inputs_wrapper {
        .timetable_input_wrapper {
          flex-direction: column;
          align-items: flex-start;

          label {
            margin-bottom: 10px;
          }
        }
      }
    }
  }

  .timetable_wrapper {
    .timetable_controls_wrapper {
      &.time {
        justify-content: space-between;

        .timetable_datetime_wrapper {
          width: 100%;
          // border: 1px solid red;
        }
      }

      .timetable_inputs_wrapper {
        width: 100%;
        margin-right: 0;

        .timetable_input_wrapper {
          width: auto;
          justify-content: space-between;

          input {
            width: 100%;
          }
        }
      }
    }
  }

  .timetable_inner_inner {
    width: 100%;
  }

  .timetable_inline .timetable_wrapper .timetable_controls_wrapper .timetable_inputs_wrapper .timetable_input_wrapper .timetable_input_autocomplete_wrapper {
    width: 100%;
  }
}

// .timetable_wrapper .timetable_controls_wrapper .timetable_inputs_wrapper .timetable_input_wrapper input
// .timetable_wrapper .timetable_controls_wrapper .timetable_inputs_wrapper .timetable_input_wrapper label

@media (max-width: 880px) {
  .presse-btn {
    margin-right: 0;
  }
}

@media (max-width: 767.98px) {

  .traffic-information-wrapper {
    padding-left: 0;
  }

  .page_section {
    margin-top: 76px;
  }

  .navigation_wrapper .navigation_inner .first_level {
    padding-top: 80px;
  }

  // respo
  .traffic-information .incidents-list-in {
    display: block;
    overflow-x: visible;
  }

  // .ids-fgi-row {
  //   display: none !important;
  // }
  .ids-wt-btn-more {
    padding-right: 10px;
  }

  .ids-fgi-row .ids-btn {
    // display: block !important;
    border: 1px solid red !important;
    min-width: auto !important;
    justify-content: flex-end !important;
  }

  .ids-wt-fgi-list {
    display: flex;
    flex-direction: column;
  }

  .ids-fgi-body {
    width: 100% !important;
  }

  .ids-wt-btn-more {
    margin-left: 0 !important;
    display: block !important;
    align-items: flex-start;
  }

  .ids-wt-lines {
    display: none !important;
  }

  .ids-wt-lines.ids-row,
  .ids-wt-header {
    display: none !important;
  }

  .ids-wt-lines.ids-row.ids-row-margin {
    display: block !important;
    overflow-x: auto;
  }

  .ids-fgi-head .ids-wt-route {
    min-width: unset !important;
  }
}

@media (min-width: 767.98px) {
  .logo-mobile {
    display: none;
  }
}