
                        @import "@/assets/scss/_variables.scss";
                        @import "@/assets/scss/_mixins.scss";
                    

@import "./assets/scss/all.scss";

.loading-screen {
   background: rgba(255, 255, 255, 0.5);
   z-index: 10000;
   width: 100%;
   height: 100%;
   top: 0;
   left: 0;
   position: fixed;
   text-align: center;

   svg {
      position: absolute;
      right: 30px;
      bottom: 30px;
      width: 10%;
      stroke: #ce132d;
   }
}
