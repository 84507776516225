.search-results {
   .results-count {
      @extend .text-muted;

      strong {
         color: $abellio-dark-grey;
      }
   }
}

.a-search {
   margin-bottom: 25px;

   .inner {
      padding: 15px;
      box-shadow: $box-shadow;
      position: relative;

      .title {
         color: $abellio-red;
         font-weight: 500;
         margin-bottom: 0;
      }

      .info {
         @extend .text-muted;
         font-weight: 300;
         text-transform: unset;
         @include fontSize(13);
         margin-bottom: 15px;
      }

      p {
         font-weight: 300;
      }

      .img {
         position: absolute;
         right: 15px;
         top: 15px;
         height: calc(100% - 30px);
         width: 150px;
         border-radius: 6px;
         background-size: cover;
         background-position: center;
         background-repeat: no-repeat;
      }
   }

   &.has-media {
      .inner {
         padding-right: 165px;
      }
   }
}

.m-autocomplete {
   position: absolute;
   left: 0;
   top: 85px;
   width: 100%;
   background: #fff;
   text-align: left;
   overflow-y: auto;
   box-shadow: 0 14px 20px rgba(0, 0, 0, 0.1);
   border-radius: 5px;
   transition: max-height .3s linear, opacity .3s linear, visibility .3s linear;
   max-height: 0;
   opacity: 0;
   visibility: hidden;

   &__item {
      padding: 12px 10px;
      border-bottom: 1px solid #ddd;

      &:hover {
         background-color: #F5F5F5;
         cursor: pointer;
      }
   }

   &.is-active {
      visibility: visible;
      opacity: 1;
      max-height: 200px;
   }
}


.custom-scrollbar {
   &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
      background-color: #F5F5F5;
   }

   &::-webkit-scrollbar {
      width: 7px;
      background-color: #F5F5F5;
   }

   &::-webkit-scrollbar-thumb {
      background-color: $abellio-red;
   }
}
