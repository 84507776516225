.tabs-wrapper {
    overflow-x: hidden;

    .navigation {
        height: 90px;
        display: flex;
        align-items: flex-end;

        a {
            width: 25%;
            height: 100px;
            color: #fff;
            font-size: 4rem;
            border-right: 1px solid darken($abellio-dark-grey, 10);
            display: inline-flex;
            justify-content: flex-end;
            align-items: flex-end;
            margin-bottom: 0;
            line-height: .6;
            background-color: $abellio-red;
            font-weight: 800;
            padding-right: 15px;
            transition: all .3s linear;

            &:not(.active) {
                background-color: $abellio-dark-grey;
                opacity: .2;
                height: 80px;
            }

            &:hover {
                opacity: .4;
            }

            &.done, &.active {
                opacity: 1;
            }
        }
    }

    .tabs {
        position: relative;
        overflow-y: hidden;

        .tab {
            position: relative;
            left: 0;
            top: 0;
            transition: all 0.3s linear;
            transform: translate(0, 0);
            transition-delay: .1s;

            .inner {
                background-color: lighten($abellio-dark-grey, 55);

                .content {
                    padding: 4%;

                    ul {
                        margin-bottom: 0;
                    }
                }
            }

            &:not(.active) {
                opacity: 0;
                visibility: hidden;
                transform: translate(0, 20%);
            }
        }
    }

    .surcharges-options {
        > div {
            display: flex;
            justify-content: space-between;
            align-items: center;

            p, input {
                width: 50%;
            }
        }
    }

    .actions-btn {
        position: relative;
        z-index: 1;

        a {
            text-transform: uppercase;
            font-size: 15px;
            font-weight: 900;
            display: inline-flex;
            align-items: center;
            width: 50%;
            padding: 20px;
            background-color: lighten($abellio-dark-grey, 53);
            margin: 0;

            .icon {
                display: flex;
            }

            &:last-child {
                justify-content: flex-end;
            }

            &[disabled="disabled"]{
                opacity: .5;
                cursor: not-allowed;
            }
        }
    }

    label {
        display: flex;
        align-items: center;
        height: 50px;

        input[type="radio"] {
            margin-bottom: 0;
            margin-right: 15px;
        }
    }

    ul.price-list {
        padding: 0;

        li {
            list-style: none;
            border-bottom: 1px solid #eee;
            padding: 6px 0;
            display: flex;
            justify-content: space-between;
        }
    }
}
