/* --------------------------------------------
    TYPOGRAPHY
-------------------------------------------- */
@font-face {
   font-family: 'Frutiger';
   src: // url('../fonts/frutiger_light-webfont.ttf') format('truetype'),
      url('../fonts/frutiger_light-webfont.woff2') format('woff2'),
      url('../fonts/frutiger_light-webfont.woff') format('woff');
   font-weight: normal;
   font-style: normal;
   font-display: swap;
}

@font-face {
   font-family: 'Frutiger';
   src: // url('../fonts/ftb-webfont.ttf') format('truetype'),
      url('../fonts/ftb-webfont.woff2') format('woff2'),
      url('../fonts/ftb-webfont.woff') format('woff');
   font-weight: bold;
   font-style: normal;
   font-display: swap;
}

@import "icons";

html {
   font-size: #{$base-font-size}px;

   &.text_size_large {
      font-size: #{$base-font-size-large}px;
   }
}

body {
   background: #fff;
   color: $black;
   font-family: Frutiger, Helvetica, Arial, sans-serif !important;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
   @include fontSize(14);
   @include lineHeight(26);
   font-weight: normal;
   width: 100%;
   //height: 100%;
   padding: 0;
   margin: 0;
   -webkit-box-sizing: border-box;
   box-sizing: border-box;
}

h1, .h1 {
   @include fontSize(55);
   @include lineHeight(55);
   font-weight: bold;
   margin: 0 0 15px 0;
   padding: 0 0 0 0;
   word-break: break-word;
}

h2, .h2 {
   @include fontSize(32);
   @include lineHeight(42);
   font-weight: bold;
   margin: 0 0 15px 0;
   padding: 0 0 0 0;
   word-break: break-word;
}

h3, .h3 {
   @include fontSize(28);
   @include lineHeight(33);
   font-weight: bold;
   margin: 0 0 15px 0;
   padding: 0 0 0 0;
   word-break: break-word;
}

h4, .h4 {
   @include fontSize(24);
   @include lineHeight(32);
   font-weight: bold;
   margin: 0 0 15px 0;
   padding: 0 0 0 0;
   word-break: break-word;
}

h5, .h5 {
   @include fontSize(20);
   @include lineHeight(28);
   font-weight: bold;
   margin: 0 0 15px 0;
   padding: 0 0 0 0;
   word-break: break-word;
}

h6, .h6 {
   @include fontSize(18);
   @include lineHeight(26);
   font-weight: bold;
   margin: 0 0 15px 0;
   padding: 0 0 0 0;
   word-break: break-word;
}

p {
   @include fontSize(18);
   @include lineHeight(26);
   margin: 0 0 15px 0;
   padding: 0 0 0 0;

   &.small {
      // @include fontSize(14);
   }
}

a {
   @include fontSize(18);
   @include lineHeight(26);
   font-weight: inherit;
   margin: 0 0 15px 0;
   padding: 0 0 0 0;
   text-decoration: none;
   color: inherit;
   -webkit-touch-callout: none; /* iOS Safari */
   -webkit-user-select: none; /* Safari */
   -khtml-user-select: none; /* Konqueror HTML */
   -moz-user-select: none; /* Old versions of Firefox */
   -ms-user-select: none; /* Internet Explorer/Edge */
   user-select: none;
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a,
p a,
label a {
   font-size: inherit;
   line-height: inherit;
   margin: 0;
   color: $abellio-red;
   text-decoration: underline;
}

a:hover, a:focus {
   color: inherit;
   text-decoration: none;
   outline-offset: 0;
   outline: transparent;
}

strong {
   font-weight: bold;
   margin: 0 0 0 0;
   padding: 0 0 0 0;
}

em {
   font-style: italic;
   margin: 0 0 0 0;
   padding: 0 0 0 0;
}

ul, ol {
   margin: 0 0 15px 0;
   padding: 0 0 0 24px;
}

ul > li {
   list-style: disc;
}

ol > li {
   list-style: decimal;
}

ul li, ol li {
   @include fontSize(18);
   @include lineHeight(26);
   margin: 0 0 0 0;
   padding: 0 0 0 0;
}

img, input {
   border: 0 none;
   outline: 0 none;
}

p > img {
   max-width: 100%;
}

hr {
   height: 1px;
   background: $hr-color;
   margin: 0 0 15px 0;
   border: 0 none;
}

iframe {
   margin: 0;
   padding: 0;
}

table {
   border-collapse: collapse;
   border-spacing: 0;
   margin-bottom: 15px;
   width: 100%;
}

table tr td {
   font-size: inherit;
   line-height: inherit;
   font-weight: normal;
   padding: 1px 10px 1px 0px;
   vertical-align: top;
}

table tr th {
   font-size: inherit;
   line-height: inherit;
   font-weight: bold;
   padding: 1px 10px 1px 0px;
   vertical-align: top;
}

/* --------------------------------------------
    Form
-------------------------------------------- */
form {
   width: 100%;
   display: block;
   height: auto;
   margin: 0 0 0 0;
   padding: 0 0 0 0;
}

label {
   display: block;
   @include fontSize(18);
   @include lineHeight(26);
   font-weight: normal;
   margin: 0 0 15px 0;
   padding: 0 0 0 0;
}

input,
select {
   display: block;
   width: 100%;
   height: auto;
   @include fontSize(18);
   @include lineHeight(27);
   margin: 0 0 25px 0;
   padding: 8px 14px;
   border: 1px solid $black;
   background: #fff;
   outline: none;
   border-radius: 2px;
}

select {
   padding: 10px 20px 10px 11px;
}

fieldset {
   .custom-select {
      .selected {
         padding: 9px 40px 9px 14px;
         color: $black;
         opacity: 0.6;
         -ms-opacity: 0.6;
         font-family: 'Frutiger';

         &.placeholder {
            font-family: 'Frutiger';
            font-weight: normal;
            opacity: 1;
         }
      }
   }
}

input[type="checkbox"],
input[type="radio"] {
   width: auto;
}

.custom-select {
   position: relative;
   width: 100%;
   margin: 0 0 15px 0;
   text-align: left;
   outline: none;
   border-radius: 2px;
   border: 1px solid $black;

   .selected {
      padding: 7px 36px 7px 14px;
      cursor: pointer;
      user-select: none;
      position: relative;
      @include transition(0.3);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      &:after {
         position: absolute;
         content: "";
         background: url(../../assets/images/select_arrow.svg) no-repeat center center;
         width: 16px;
         height: 12px;
         right: 16px;
         top: 50%;
         transform: translate(0, -50%) rotate(-180deg);
         @include transition(0.3);
      }

      &.open {
         &:after {
            transform: translate(0, -50%) rotate(0deg);
         }
      }
   }

   .items {
      position: absolute;
      z-index: 1000;
      overflow: hidden;
      border: 1px solid $black;
      border-radius: 2px;
      padding: 0;
      background-color: $white;
      left: 0;
      right: 0;
      margin: 0 -1px;
      max-height: 200px;
      overflow-y: auto;

      .item {
         padding: 7px 14px;
         cursor: pointer;
         user-select: none;
         position: relative;
         transition: 0.3s;

         &:hover {
            background-color: lighten($black, 85%);
            transition: 0.3s;
         }

         &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 14px;
            right: 14px;
            border-top: 1px solid $abellio-red;
         }

         &:first-child:before {
            display: none;
         }
      }
   }

   .selectHide {
      display: none;
   }
}

.select_white {
   color: $white;

   .custom-select {
      border-radius: 0;
      border: 0 none;
      margin-bottom: 0;
      @include fontSize(14);
      @include lineHeight(16);

      .selected {
         padding: 0 26px 0 0;
         display: inline-block;

         &:after {
            right: 0;
            background: url(../../assets/images/select_arrow_white.svg) no-repeat center top;
            width: 10px;
            height: 7px;
            margin-top: -1px;
         }
      }

      .items {
         color: $black;
         margin-top: 5px;
         min-width: 150px;
      }
   }
}

textarea {
   display: block;
   resize: none;
   width: 100%;
   height: auto;
   min-height: 200px;
   @include fontSize(18);
   @include lineHeight(26);
   margin: 0 0 25px 0;
   padding: 8px 14px;
   border: 1px solid $black;
   background: #fff;
   outline: none;
   border-radius: 2px;
}

input:disabled,
select:disabled,
button:disabled,
textarea:disabled,
.custom-select.is_disabled {
   opacity: 0.6;
   border-color: lighten($black, 40%);
   cursor: not-allowed;

   * {
      cursor: not-allowed;
   }
}

fieldset {
   min-width: 0;
   padding: 0 8px;
   margin: -6px 0 25px 0;
   outline: 0;
   border-radius: 2px;
   box-shadow: none;
   border: 1px solid #e1e1e1;
   position: relative;
   @include transition(0.3);

   &.is_disabled {
      opacity: 0.6;
      border-color: lighten($black, 40%);

      &,
      & * {
         cursor: not-allowed;
      }

      input:disabled,
      select:disabled,
      button:disabled,
      textarea:disabled,
      .custom-select {
         opacity: 1;
      }
   }

   legend {
      padding: 6px 6px 0 6px;
      @include transition(0.3);
   }

   label {
      margin-bottom: 0;
      @include lineHeight(18);
      font-weight: 300;
      @include fontSize(13);
   }

   input,
   select,
   textarea,
   .custom-select {
      width: calc(100% + 16px);
      margin-bottom: 0;
      margin-left: -8px;
      background: transparent;
      border: 0 none !important;
      font-family: 'Frutiger';
      font-weight: normal;
   }

   .date-time-picker {
      width: calc(100% + 16px);
      margin-bottom: 0;
      margin-left: -8px;
      background: transparent;

      input {
         padding: 8px 14px !important;
         margin: 0;
         @include fontSize(18);
         @include lineHeight(27);
         font-size: 1rem !important;
         font-family: 'Frutiger';
      }

   }

   .custom-select {
      position: static;

      .selected {
         position: static;
      }

      // .selected:after {
      //    margin-top: 5px;
      // }
   }

   .custom-checkboxes-wrapper, .custom-draggable-wrapper {
      padding: 6px;
      margin-bottom: 0;
   }
}

input[type="submit"],
button,
.btn {
   display: inline-block;
   padding: 12px 20px 12px 20px;
   border-radius: 4px;
   background: $button-background;
   color: $white;
   @include fontSize(18);
   @include lineHeight(20);
   font-weight: bold;
   cursor: pointer;
   border: 0 none;
   width: auto;
   margin: 0;
   font-family: 'Frutiger';
   font-weight: bold;

   &:disabled,
   &.is_disabled {
      opacity: 0.6;
   }

   &.btn-grey {
      background: lighten(#545454, 10%);
   }

   &.btn-blue {
      background: #0071BC;
   }

   &.btn-yellow {
      background: #FBB03B;
   }

   &.btn-green {
      background: #49B170;
   }

   &.btn-red, .btn-primary {
      background: #CE132D;
   }

   &.btn-dark-red {
      background: #7F2936;
   }

   &.btn-opacity {
      background-color: rgba(255, 255, 255, 0.4)
   }

   &.btn-suche {
      min-width: auto;
   }

   &:focus {
      outline: 0;
      border: none;
   }
}

::placeholder, ::-ms-input-placeholder, :-ms-input-placeholder, ::-moz-placeholder, :-moz-placeholder, ::-webkit-input-placeholder {
   color: lighten($black, 50) !important;
   font-weight: 300 !important;
   opacity: 0.6;
   -ms-opacity: 0.6;
   font-family: 'Frutiger';
}

.custom-checkboxes-wrapper {
   margin-bottom: 25px;
   display: inline-block;

   .checkbox_option {
      @include fontSize(18);
      @include lineHeight(26);
      display: flex;
      align-items: flex-start;
      margin: 4px 0;
      font-weight: normal;

      input {
         margin-top: 6px;
         margin-left: 0;
         margin-right: 12px;
         margin-bottom: 0;
      }

      a {
         font-weight: bold;
         color: $abellio-red;
      }

      p {
         margin-bottom: 0;
      }
   }
}

.custom-draggable-wrapper {
   margin-bottom: 25px;
   display: inline-block;

   .checkbox_option {
      @include fontSize(18);
      @include lineHeight(26);
      display: flex;
      align-items: center;
      margin: 4px 0;
      font-weight: normal;
      // padding-left: 25px;

      input {
         margin-top: 6px;
         margin-left: 0;
         margin-right: 12px;
         margin-bottom: 0;
         position: absolute;
         opacity: 0;
         z-index: -1;
      }
      .icon {
         color: #000;
      }
      a {
         font-weight: bold;
         color: $abellio-red;
      }

      p {
         margin-bottom: 0;
      }
   }
   &_icon {
      width: 14px;
      height: 14px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 10px;
      img{
         width: 100%;
         height: auto;
      }
   }
}

.vf-field-invalid {
   .vf-error_message {
      margin: -20px 0 25px 0;
      color: $abellio-red;
      display: none;
      font-weight: bold;
      @include fontSize(14);
      @include lineHeight(18);
   }

   &.vf-field-submitted {
      fieldset {
         border-color: $abellio-red;

         legend {
            color: $abellio-red;
         }
      }

      .vf-error_message {
         display: block;
      }
   }
}

.datepicker {
   button {
      padding: 0;
      margin-bottom: 0;

      &:not(.active) {
         color: $black !important;
      }
   }
}

.text-lowercase {
   text-transform: lowercase;
}

.text-uppercase {
   text-transform: uppercase;
}

.text-capitalize {
   text-transform: capitalize;
}

.success-message {
   background: rgba(216, 216, 216, 0.2);
   padding: 50px 30px 30px;
   text-align: center;
   align-items: center;
   margin-bottom: 120px;

   .button-ok {
      display: inline-block;
      padding: 12px 20px 12px 20px;
      border-radius: 2px;
      background: #2E2E2E;
      color: #fff;
      font-size: 1rem;
      line-height: 1.11111rem;
      font-weight: bold;
      cursor: pointer;
      border: 0 none;
      width: auto;
      margin: 0;
      font-family: 'Frutiger';
      font-weight: bold;
   }

   svg {
      width: 40px;
   }

   h2 {
      margin-top: 40px;
   }

   p {
      margin-bottom: 30px;
   }
}
