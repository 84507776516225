.traffic-information {
   margin-bottom: 30px;

   .line-filter {
      display: flex;
      justify-content: center;
      margin-bottom: 30px;
      flex-wrap: wrap;
      &-wrapper{
         margin-top: 20px;
         @include media-breakpoint-up(md){
            margin-top:0;
         }
      }
      .line-info {
         margin: 0 6px 10px 6px;
         border-radius: 44px;

         &.passive {
            opacity: 0.5;
         }
      }

      .inner {
         padding: 3px 16px;
         font-size: 14px;
         cursor: pointer;
         border-radius: 44px;
         color: #4A4A49;
         font-weight: 700;
      }
   }

   .an-info {
      margin-bottom: 20px;
      background: #FFFFFF;
      box-shadow: 5px 3px 14px rgba(0, 0, 0, 0.06), 0px 2px 5px rgba(0, 0, 0, 0.07), 0px 1px 2px rgba(0, 0, 0, 0.06);
      border-radius: 12px;
      overflow: hidden;
      align-items: initial !important; 
      &.opened {
         .content {
            max-height: 100%;
         }

         .expand {
            img {
               transform: rotate(-90deg);
            }
         }
      }
   }

   .heading {
      position: relative;

      &.red {
         border-left: 5px solid #e74c3c;

         &.a-filter {
            background-color: lighten(#e74c3c, 35);
         }
      }

      &.brown {
         border-left: 5px solid #d35400;

         &.a-filter {
            background-color: lighten(#d35400, 45);
         }
      }

      &.green {
         border-left: 5px solid #27ae60;

         &.a-filter {
            background-color: lighten(#27ae60, 45);
         }
      }
   }

   .right-actions {
      position: absolute;
      right: 25px;
      top: 0;
      bottom: 0;
      margin: auto;
      align-items: center;
      @include fontSize(12);

      .cursor-pointer {
         display: flex;
         align-items: center;
         justify-content: center;

         .ab-icon-arrow-left {
            margin-right: 5px;
            transition: 0.3s;
         }

         &:hover {
            .ab-icon-arrow-left {
               transform: translateX(-5px);
               transition: 0.3s;
            }
         }
      }

      .expand {
         background-color: transparent;
         outline: 0;
         width: 30px;
         height: 30px;
         padding: 7px;
         margin-left: 15px;

         img {
            transition: all 0.3s linear;
            transform: rotate(90deg);
         }
      }
   }


   .view-more {
      // margin-left: 15px;
      // border-radius: 50%;
      // width: 30px;
      // height: 30px;
      // padding: 0;
      // line-height: 24px;
      // margin-bottom: 0;
      // border: 2px solid $abellio-dark-grey;
      // background-color: transparent;
      // color: $abellio-dark-grey !important;
      // transition: all 0.2s linear;
      // outline: 0;
      position: absolute;
      right: 20px;
      bottom: 0;
      margin: 0 0 24px 0;
      display: flex;
      // text-align: center;

      // &:hover {
      //    background-color: $abellio-red;
      //    border-color: $abellio-red;
      //    color: #fff !important;
      // }

      .text-more {
         text-decoration: underline;
         font-weight: 700;
         font-size: 14px;
         color: #6B717A;
      }
      .plus-more {
         border: 2px solid #6B717A;
         color: #6B717A;
         border-radius: 50%;
         width: 24px;
         height: 24px;
         text-align: center;
         margin-left: 18px;
         font-size: 18px;
         font-weight: 700;
         display: flex;
         align-content: center;
         justify-content: center;
         line-height: 18px;
      }
   }

   div.inner {
      padding: 24px;
      // align-items: center;
      // cursor: pointer;
   }

   .relation {
      padding-left: 35px;
      position: relative;
      padding-right: 40px;
      margin-left: 15px;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      max-height: 100%;

      &:before {
         content: "";
         background-image: linear-gradient(#6e6e6e 33%, rgba(255, 255, 255, 0) 0);
         background-position: center;
         background-size: 1px 2px;
         background-repeat: repeat-y;
         position: absolute;
         left: 0;
         top: 46%;
         width: 1px;
         height: 46%;
         bottom: auto;
         transform: translateY(-44%);
      }

      .departure {
         margin-bottom: 0 !important;
         opacity: .8;

         .dot {
            border-color: $abellio-dark-grey;
            top: 3px;
            &:after {
               background-color: $abellio-dark-grey;
               border: none;
            }
         }
         &.h6 {
            margin-bottom: 0;
         }
      }

      .arrival, .departure {
         position: relative;
         line-height: 1.2;
         margin-bottom: 0;
      }

      .arrival {
         .dot {
            bottom: 5px;
            top: auto;
         }
      }

      .dot {
         position: absolute;
         left: -42px;
         top: 0;
         width: 16px;
         height: 16px;
         border-radius: 50%;
         border: 1px solid #CE132D;
         &:after {
            content: '';
            position: absolute;
            right: 1px;
            top: 1px;
            width: 16px;
            height: 16px;
            border-radius: 50%;
            left: 1px;
            background-color: $abellio-red;
            width: 12px;
            height: 12px;
            border-radius: 50%;
            bottom: 0;
            border: 1px solid $abellio-red
         }
      }
   }

   .route-code {
      margin-top: 9px;
      letter-spacing: -.7px;    
      &-in {
         border-radius: 44px;
         text-align: center;
         font-size: 14px;
         overflow: hidden;
         width: 60px;
      }
   }

   .content {
      position: relative;
      background-color: $white;
      @include fontSize(14);
      max-height: 0;
      overflow: hidden;
      transition: all 0.5s linear;

      .extra-info-content *{
         @include fontSize(14);
         margin-bottom: 8px;
      }

      .inner {
         padding-left: 130px;
         max-width: 65% !important;
         .text-muted {
            font-size: 10px;
            margin: 0 !important;
            line-height: 10px;
            color: #6B717A;
            opacity: 0.75;
         }
      }
   }


   .route-stops {
      > div {
         position: relative;
         border-bottom: 2px solid #C71712;
         padding-bottom: 5px;
         width: 100%;
      }

      .dot {
         position: absolute;
         left: 0;
         background-color: #6E6E6E;
         width: 12px;
         height: 12px;
         border-radius: 50%;
         bottom: -6.5px;
      }

      .start {
         padding-right: 60px;

         // &:after {
         //    content: '\21CE';
         //    position: absolute;
         //    right: 20px;
         //    top: -1px;
         //    color: #777;
         //    font-size: 20px;
         // }
      }

      .stop {
         .dot {
            left: auto;
            right: -2px;
         }
      }
   }

   &.traffic-info-message {
      .relation {
         padding-right: 100px;
         height: auto;
         &:before {
            max-height: calc(100% - 40px);
            @include media-breakpoint-down(sm) {
               // padding-top: 50px !important;
               height: calc(100% - 50px) !important;
               top: 30px !important;
               transform: unset !important;
           }
         }

         .dot {
            top: 10px;
         }

         .arrival {
            .dot {
               top: auto;
               bottom: 15px;
            }
         }
      }

      .content {
         .inner {
            max-width: 100% !important;
            padding: 25px;
            .stop  {
               text-align: right;
            }
            @include media-breakpoint-down(sm) {
               padding-top: 50px !important;
           }
         }
      }

      .an-info.opened {
         .content {
            max-height: 100%;
         }
         .inner {
            @include media-breakpoint-down(sm) {
               padding-top: 50px !important;
           }
         }
      }

      .documents-list {
         p {
            margin-bottom: 7px;
         }

         a {
            color: $abellio-red;
            text-decoration: underline;
         }
      }

      .right-actions {
         @include media-breakpoint-down(sm) {
            top: 5px !important;
            bottom: auto;
        }
     
      }
   }

   .tabs_wrapper {
      display: flex;
      margin: 0 0 40px 0 ;

      .tab_item_switch {
         display: flex;
         justify-content: center;
         align-items: center;
         width: 100%;
         text-align: center;
         // border-top: 1px solid #f9f9f9;
         border-bottom: 1px solid $abellio-gray;
         // box-shadow: 0 1px 3px rgba(0, 0, 0, 0.08), 0 2px 4px rgba(0, 0, 0, 0.03);
         padding: 10px;
         margin: 0 0;

         .switcher {
            width: 100%;
            text-align: center;
            border-top: none;
            box-shadow: none;
            display: block;
            padding: 0;
            margin: 0;
            
            &-counter {
               background: #C71712;
               border-radius: 44px;
               padding: 2px 10px;
               color: $white;
               margin-left: 24px;
               font-weight: 300;
               font-size: 14px;
            }
         }

         &.active {
            // background: #ededed;
            border-bottom: 6px solid $abellio-gray;
            .switcher {
               font-weight: 900;
            }
         }
      }
   }
}

.traffic-info-message {
   .content {
      background-color: #f9f9f9;
   }
}