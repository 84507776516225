.timetable_inline{
    .timetable_wrapper{
        background-color: transparent;
        height: auto !important;
        &:after{
            content: none;
        }
    }
    .timetable_inner_wrapper{
        background-color: transparent;
    }
    span.colored-dot-icon.white, .timetable_wrapper .timetable_controls_wrapper .timetable_controls_line:before{
        background-color: #000;
    }
    span.colored-dot-icon{
        transition: all .8s linear;
        &:after{
            border-color: #fff;
        }
    }
    .time-wrapper {
        display: flex;
    }
    .timetable_wrapper .timetable_controls_wrapper .timetable_controls_line .timetable_controls_line_icon{
        background-color: transparent;
    }
    // input[type="text"]{
    //     border-top: 1px solid #f9f9f9 !important;
    //      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.14), 0 2px 4px rgba(0, 0, 0, 0.07);
    // }
    .timetable_wrapper .timetable_controls_wrapper .timetable_inputs_wrapper .timetable_input_wrapper .timetable_input_autocomplete_wrapper .timetable_input_autocomplete{
        border-color: #f9f9f9;
        box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
        border-radius: 0 0 5px 5px;
    }
    .timetable_input_autocomplete{
        margin-top: 5px;
    }
    .timetable_date_wrapper, i, .timetable_time{
        color:#000;
    }
    .timetable_datetime_wrapper .date-time-picker a.date_trigger{
        background-color: transparent;
    }
    .timetable_datetime_wrapper{
        justify-content: flex-start;
        margin-top: 10px;
        font-size: 13px;
        i{
            font-size: 22px !important;
            opacity: .7;
        }
    }
    .timetable_datetime_wrapper .timetable_time, .timetable_datetime_wrapper .timetable_date{
        min-height: auto;
    padding-left: 0;
    }
    .timetable_wrapper .timetable_controls_wrapper{
        // padding-top: 38px;
    }
    .timetable_inner_wrapper{
        padding: 48px 0;
        display: flex;
        flex-direction: column;
        position: relative;
        z-index: 999;
        // transform: translateY(-180px);
        background: #fff;
    }
    .timetable_wrapper .timetable_controls_wrapper .timetable_inputs_wrapper .timetable_input_wrapper .timetable_input_autocomplete_wrapper{
        z-index: unset;
    }
    .timetable_input_autocomplete_wrapper{
        z-index: 10 !important;
    }
    label{
        opacity: .5;
    }
    .timetable_submit_wrapper{
        padding-top: 32px;
        margin-top: 32px;
        border-top: 0.5px solid #6B717A;
        @include media-breakpoint-down(sm) {
            padding-top: 20px;
            margin-top: 10px;
            justify-content: flex-end;
            .timetable_inner_inner {
                margin: unset;
            }
        }
    }
    .timetable_wrapper .timetable_controls_wrapper .timetable_controls_line.active{
        &:before{
            background-color: $abellio-red;
        }
        span.colored-dot-icon.white{
            background-color: $abellio-red !important;
        }
    }
    &.timetable_element {
        margin-top: -180px;
        @include media-breakpoint-down(md) {
            max-width: 90%;
            margin: 0 auto;
        }
        .timetable_wrapper {
            margin-bottom: 130px;
            @include media-breakpoint-down(md) {
                margin-bottom: 50px;
            }
        }
    }
}

@media screen and (max-width: 767.98px){
    .timetable_inner_wrapper{
        padding: 20px 20px !important;
    }

    .timetable_inline .timetable_wrapper .timetable_controls_wrapper .timetable_controls_line{
        padding-top: 50px;
        padding-bottom: 10px;
        &:not(.active):before{
            max-height: 0;
        }
        &:before{
            top: 55px;
        }
    }
    
}