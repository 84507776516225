
                        @import "@/assets/scss/_variables.scss";
                        @import "@/assets/scss/_mixins.scss";
                    

select {
    width: 0;
    height: 0;
    padding: 0;
    margin: 0;
    border: 0 none;
}
.select-wrapper{
    width: 0;
    height: 0;
    overflow: hidden;
}
