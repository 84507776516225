//
// Grid Options
//
// To avoid your options getting overridden by an update,
// copy and paste the below options to your own sass file
// and include it before this file.

// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1280px
) !default;


// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
        sm: 540px,
        md: 720px,
        lg: 960px,
        xl: 1260px
) !default;


// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-columns: 12 !default;
$grid-gutter-width: 30px !default;

// END of Grid Options


// Bootstrap file imports
@import "text/mixins/deprecate";
@import "text/mixins/hover";
@import "text/mixins/text-emphasis";
@import "text/mixins/text-hide";
@import "text/mixins/text-truncate";
@import "breakpoints/mixins/breakpoints";
@import "layout/mixins/visibility";
@import "grid/bootstrap-grid";
@import "text/utilities/text";
@import "sizing/utilities/sizing";
@import "layout/utilities/position";
@import "layout/utilities/visibility";
