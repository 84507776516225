.text_size_large {
   .banner {
      // h1 {
      //    line-height: 5rem;
      //    font-size: 5rem;
      // }
   }

   .abellio_world_element {
      .abellio_world_filter_trigger {
         width: 130px;
      }
   }

   .timeline_wrapper {
      .timeline_item {
         .timeline_year {
            right: -40px;
            line-height: 4rem;
         }

         &.align-self-end {
            .timeline_year {
               left: -40px;
            }
         }
      }
   }
}
